import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, label, isChecked, onChange, showError, errorMessage, ...props }) => {

    return (
        <div className='checkbox-wrapper'>
            <label htmlFor={`label-${id}`}>{label}</label>
            <input
                id={id}
                type='checkbox'
                value={isChecked}
                checked={isChecked}
                onChange={onChange}
                {...props} />
            {showError && <span className='checkbox-error-message'>{errorMessage}</span>}
        </div>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string
}

export default Checkbox;