/*jshint esversion: 6 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, X } from "phosphor-react";

import { ReactComponent as Logo } from '../../assets/logo.svg';

import { sendEvent } from '../../helpers/analyticsHandler';
import NavbarMenu from './NavbarMenu';

import './SidebarMenu.scss';

export default function SidebarMenu({ id }) {
    const [showMenu, setShowMenu] = useState(false);

    const onItemClick = (navBarItem) => {
        sendEvent('analyticsClickAction', 'navigation', null, navBarItem);
    }

    return (
        <aside id={id} data-testid={id} className='navbar-menu'>
            <Logo className='logo' />
            {showMenu ? <X size={32} className='menu-bars' data-testid={`${id}-menu-bars`} onClick={() => setShowMenu(!showMenu)} /> : <List size={32} data-testid={`${id}-menu-bars`} onClick={() => setShowMenu(!showMenu)} className='menu-bars' />}
            <NavbarMenu id="sidebar-menu" classNavbar='sidebar-menu' onItemClick={(navBarItem) => onItemClick(navBarItem)} />
            {showMenu &&
                <>
                    <div className='overlay' data-testid={`${id}-overlay`} onClick={() => setShowMenu(!showMenu)} >
                    </div>
                    <NavbarMenu id={`${id}-hamburger`} classNavbar='hamburger-menu' onItemClick={(navBarItem) => { setShowMenu(!showMenu); onItemClick(navBarItem); }} />
                </>
            }
        </aside>
    );
}

SidebarMenu.propTypes = {
    id: PropTypes.string
}