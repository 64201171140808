import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TutorialIcon } from '../../assets/icons/tutorial-icon-desktop.svg';

import './TutorialItem.scss';

const TutorialItem = ({id, title, subtitle, onClick}) => {

return (
    <>
        <div data-testid={id} className='tutorial-pop' onClick = {onClick}>
            <div className='browse-image'>
                <TutorialIcon/>
            </div>
            <div className = 'description-tutorial'>
                <p className='title'>{title}</p>
                <p className='subtitle'>{subtitle}</p>
            </div>
        </div>
    </>
)
}

TutorialItem.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onClick: PropTypes.func,
}

export default TutorialItem;