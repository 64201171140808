import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import './ErrorModal.scss';
import Button from '../Button/Button';

const ErrorModal = ({ id, isOpen, handleClose, message, header }) => {
    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleClose} size="sm">
                    <div className='error-modal'>
                        <h1>{header}</h1>
                        <h2>{message}</h2>
                        <Button id={`${id}-close-btn`} className='btn--rounded btn--blue-light btn--close'
                            onClick={() => handleClose()}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
            }
        </>
    )
}

ErrorModal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string,
    header: PropTypes.string
}

export default ErrorModal;