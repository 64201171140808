import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PermissionSettings from '../../components/PermissionSettings/PermissionSettings';
import ManageRecs from '../../components/Admin/ManageRecs/ManageRecs';
import ManageUpdates from '../../components/Admin/ManageUpdates/ManageUpdates';
import ManagePersona from '../../components/Admin/ManagePersona/ManagePersona';

import './Admin.scss';

const Admin = ({ id }) => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <section id={id} data-testid='admin-page'>
                <div className='content--container admin-page--container'>
                    <div className = 'manage-tab'>
                        <ul className="nav">
                            <li 
                                id={`${id}-manage-personas-tab`} 
                                data-testid={`${id}-manage-personas-tab`} 
                                className={activeTab === 1 ? 'active': 'inactive'} 
                                onClick={() => setActiveTab(1)}
                            >Manage Personas</li>
                            <li 
                                id={`${id}-manage-recommendations-tab`} 
                                data-testid={`${id}-manage-recommendations-tab`} 
                                className={activeTab === 2 ? 'active': 'inactive'} 
                                onClick={() => setActiveTab(2)}
                            >Manage Recommendations</li>
                            <li 
                                id={`${id}-manage-updates-tab`} 
                                data-testid={`${id}-manage-updates-tab`} 
                                className={activeTab === 3 ? 'active': 'inactive'} 
                                onClick={() => setActiveTab(3)}
                            >Manage Updates</li>
                            <li 
                                id={`${id}-manage-permissions-tab`} 
                                data-testid={`${id}-manage-permissions-tab`} 
                                className={activeTab === 4 ? 'active': 'inactive'} 
                                onClick={() => setActiveTab(4)}
                            >Manage Permissions</li>
                        </ul>
                    </div>
                    <div className = 'admin-content'>
                        {activeTab === 1 ?
                            <ManagePersona id='manage-personas'></ManagePersona>
                            :null
                        }
                        {activeTab === 2 ? 
                            <ManageRecs id = "manage-recs"></ManageRecs>
                            : null
                        }
                        {activeTab === 3 ?
                            <ManageUpdates id = "manage-updates"></ManageUpdates>
                            : null
                        }
                        {activeTab === 4 ? 
                            <PermissionSettings></PermissionSettings>
                            : null
                        }
                        
                    </div>
                </div>
            </section>
        </>
    );
};

Admin.propTypes = {
    id: PropTypes.string,
}

export default Admin;