import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FavoriteProduct } from '../../../services/dmpApi';
import { Context } from '../../../contexts/context';
import { Actions } from '../../../contexts/action';
import { getDataLayer, sendEvent } from '../../../helpers/analyticsHandler';

import ConfirmUnfavoriteModal from '../../Modal/ConfirmUnfavoriteModal';

import { ReactComponent as Heart } from '../../../assets/icons/favorite.svg';

import './Favorite.scss';

const Favorite = ({ id, favoriteItem }) => {
    const { 
        state: {
            favorites
        },
        dispatch
    } = useContext(Context);
    const [isFavorited, setIsFavorited] = useState(favorites.filter(item => item.id === favoriteItem.id).length > 0);
    const [openUnfavoriteConfirm, setOpenUnfavoriteConfirm] = useState(false);
    const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);

    const handleFavoriteClick = async (e) => {
        if (e) {
            e.stopPropagation();
        }

        let tempIsFavorited = isFavorited;

        setIsFavorited(!isFavorited);
        setIsLoadingFavorite(true);
        FavoriteProduct(favoriteItem.id)
            .then(response => {
                let tempFavorites = favorites;
                if (isFavorited) {
                    tempFavorites = tempFavorites.filter(item => item.id !== favoriteItem.id);
                } else {
                    if (tempFavorites.findIndex(item => item.id === favoriteItem.id) < 0) {
                        tempFavorites.unshift({
                            type: favoriteItem.type,
                            name: favoriteItem.name,
                            hasAccess: favoriteItem.access ? favoriteItem.access !== "none" : favoriteItem.hasAccess,
                            id: favoriteItem.id,
                            appUrl: favoriteItem.appUrl,
                            url: favoriteItem.url,
                            access: favoriteItem.access,
                            reportType: favoriteItem.reportType,
                            presentation: favoriteItem.presentation,
                            businessSegment: favoriteItem.businessSegment,
                            domain: favoriteItem.domain,
                            primaryOwner: favoriteItem.primaryOwner,
                            secondaryOwner: favoriteItem.secondaryOwner
                        });
                    }
                }

                dispatch({ type: Actions.UPDATE_FAVORITES, payload: tempFavorites });
                
                sendEvent('analyticsClickAction', getDataLayer().pageProperties.pageName.toLowerCase() + '_favorite', null, !isFavorited ? 'Add To Favorites' : 'Remove From Favorites', favoriteItem);
                
                setOpenUnfavoriteConfirm(false);
                setIsLoadingFavorite(false);
            })
            .catch(error => {
                console.log(error)
            
                const toastProperties = {
                    id: favoriteItem.id,
                    type: 'error',
                    title: 'Error',
                    message: 'An error occurred and this item could not be favorited.'
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });

                setIsFavorited(tempIsFavorited);
                setIsLoadingFavorite(false);
            });
    }

    const handleConfirmUnfavorite = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setOpenUnfavoriteConfirm(true);
    }

    const handleCancelClick = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setOpenUnfavoriteConfirm(false);
    }

    useEffect(() => {
        setIsFavorited(favorites.filter(item => item.id === favoriteItem.id).length > 0)
    }, [favoriteItem, favorites])

    const handleClick = (e) => {
        if (e) {
            e.stopPropagation();
        }
        if (!isLoadingFavorite) {
            if (isFavorited) {
                handleConfirmUnfavorite();
            } else {
                handleFavoriteClick();
            }
        }
    }

    return (
        <>
            <div
                data-testid={id}
                onClick={handleClick}
                className={`${isLoadingFavorite ? 'no-click' : 'favorite-heart'}`}
            >
                <Heart className={isFavorited ? 'fill' : ''}/>
            </div>
            <ConfirmUnfavoriteModal
                isOpen={openUnfavoriteConfirm}
                id={`unfavorite-confirmation-modal`}
                handleClose={handleCancelClick}
                handleRemove={handleFavoriteClick}
                header='Confirmation'
                message={`Are you sure you want to remove ${favoriteItem.name} from your favorites?`}
            />
        </>

    )
}

Favorite.propTypes = {
    id: PropTypes.string,
    favoriteItem: PropTypes.any.isRequired
}

export default Favorite;