import { X } from "phosphor-react";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import ReactTooltip from "react-tooltip";

import { sendEvent, trackFilters } from '../../helpers/analyticsHandler';

import DefinitionGlobalFilter from './DefinitionGlobalFilter';
import Pagination from './Pagination';
import SortDropdown from './SortDropdown';
import ViewAllFilters from './ViewAllFilters';

import './Datatable.scss';

export default function Datatable({
    columns, data, hiddenColumns, defaultFilters, defaultPageSize, defaultPageIndex, onTableStateChange,
    onRowClick, setResultsCount, onChangeSort, sortOptions, id, defaultSortBy, hasGlobalFilter, rowClass, dropDownRow, paginationDisplay, noResultsMessage, givenClassName,
    fetchedResultsCount, totalResultsCount, displayCheck }) {

    const navigate = useNavigate();
    if (hiddenColumns === undefined) hiddenColumns = [];
    const {
        allColumns,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setAllFilters,
        setPageSize,
        setSortBy,
        rows,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter, filters, sortBy }
    } = useTable({
        sortTypes: {
            alphanumeric: (row1, row2, columnName) => {
                const rowOneColumn = (row1.values[columnName] || '').trim();
                const rowTwoColumn = (row2.values[columnName] || '').trim();
                if (isNaN(rowOneColumn)) {
                    return rowOneColumn.toUpperCase() >
                        rowTwoColumn.toUpperCase()
                        ? 1
                        : -1;
                }
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
            }
        },
        columns,
        data,
        initialState: {
            pageIndex: 0, pageSize: defaultPageSize, hiddenColumns: hiddenColumns, filters: defaultFilters, sortBy: defaultSortBy ? defaultSortBy : []
        },
        autoResetFilters: false,
        autoResetSortBy: false,
        autoResetGlobalFilter: false
    }, useGlobalFilter, useFilters, useSortBy, usePagination);
    const location = useLocation();

    const sortByMap = () => {
        if (sortBy && sortBy[0]) {
            const { id, desc } = sortBy[0];
            switch (id) {
                case 'lastAccessed':
                    return 'Last Used';
                case 'favoritedAt':
                    return 'Date Favorited';
                case 'name':
                    if (desc) return 'Alphabetical Z-A';
                    return 'Alphabetical A-Z';
                default:
                    return 'Default';
            }
        } else return 'Default';
    }

    useEffect(() => {
        onTableStateChange(pageSize, filters, pageIndex, sortBy);
    }, [onTableStateChange, pageSize, filters, pageIndex, sortBy]);

    useEffect(() => {
        setResultsCount(rows.length);
    }, [setResultsCount, rows])

    const handleClearAllFiltersEvent = () => {
        setAllFilters([]);
        sendEvent('analyticsClickAction', location.pathname + '_filtersClear');
        if(location.pathname.includes('search')){
            navigate(`/search`);
            sendEvent('analyticsClickAction', 'internalSearch');
        }

    }

    useEffect(() => {
        if (filters) {
            trackFilters(filters);
        }
    }, [filters]);

    // Render the UI for your table
    return (
        <section id={id} data-testid={id} className={givenClassName===undefined ? 'results-datatable' : `results-datatable--${givenClassName}`}>
            {hasGlobalFilter && (
                <div id={id} data-testid={id} className='data-definition--container'>
                    <DefinitionGlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        className='search-filter' />
                </div>
            )}
            <div className='datatable datatable--definitions'>
                {dropDownRow!==false && <div className={'dropdown-row'}>
                    <ReactTooltip id='filter-tooltip' className='custom-color-no-arrow' delayHide={1000}
                        textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                    <div className='filters'>
                        { allColumns.map((column, i) => {
                            return (
                                <div key={i} {...getFilterTooltip(column)}>
                                    {column.canFilter && column.Filter ? column.render("Filter") : null}
                                </div>
                            );
                        })}
                        {allColumns.filter(column => column.Filter).length > 0   &&
                            <div className='dropdown--filter'>
                                <div data-testid={`${id}-clear-all`} className='dropdown-btn dropdown-btn--red' onClick={() => handleClearAllFiltersEvent()}>Clear All <div className='circle circle--red'><X size={12} weight="bold" /></div></div>
                            </div>
                        }
                    </div>
                    <div className='filter-row--right'>
                        <div className='view-filters allFilters'>
                            {allColumns.filter(column => column.Filter).length > 0  &&
                                <ViewAllFilters allColumns={allColumns} setAllFilters={() => handleClearAllFiltersEvent()} />
                            }
                        </div>
                        {(!displayCheck || fetchedResultsCount>=totalResultsCount) && <div className='sort'>
                            <span className='sort--title'>Sort by</span>
                            <SortDropdown
                                id={`${id}-sort-dropdown`}
                                onChange={(option) => { onChangeSort(option, setSortBy, sortBy) }}
                                options={sortOptions}
                                defaultOption={sortByMap()} />
                        </div>}
                    </div>
                </div>
                }
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((group, idx) => (
                            <tr key={idx} {...group.getHeaderGroupProps()}>
                                {group.headers.map((column, idx) => (
                                    <th key={idx} {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='search-page'>
                        {page.map((row, idx) => {
                            prepareRow(row);
                            return (
                                <tr key={idx} data-testid={`${id}-row-${row.id}`} {...row.getRowProps()} onClick={() => { onRowClick && onRowClick(row); }} className={rowClass && rowClass(row.original.type)}>
                                    {row.cells.map((cell, idx) => {
                                        return <td key={idx} {...cell.getCellProps()} className={cell.column?.className}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length === 0 && (
                    noResultsMessage===undefined ?
                    <div className="no-results-message">
                        <p>No results found</p>
                        <p>Please try again using different search/filters criteria</p>
                    </div>
                    :
                    <div className="no-results-message">
                    <p>{noResultsMessage}</p>
                </div>
                )}
                {paginationDisplay!==false && <Pagination
                    id={`${id}-pagination`}
                    previousPage={() => previousPage()}
                    canPreviousPage={canPreviousPage}
                    nextPage={() => nextPage()}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    gotoPage={(page) => gotoPage(page)}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    setPageSize={(num) => setPageSize(num)}
                    totalCount={(rows && rows.length)}
                />}
            </div>
        </section>
    );
}

Datatable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    hiddenColumns: PropTypes.array,
    defaultFilters: PropTypes.array,
    defaultPageSize: PropTypes.number,
    defaultPageIndex: PropTypes.number,
    onTableStateChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    setResultsCount: PropTypes.func.isRequired,
    sortOptions: PropTypes.array,
    onChangeSort: PropTypes.func,
    id: PropTypes.string,
    defaultSortBy: PropTypes.array,
    hasGlobalFilter: PropTypes.bool,
    rowClass: PropTypes.func,
    dropDownRow: PropTypes.bool,
    paginationDisplay: PropTypes.bool,
    noResultsMessage: PropTypes.string,
    givenClassName : PropTypes.string,
    fetchedResultsCount : PropTypes.number,
    totalResultsCount : PropTypes.number,
    displayCheck: PropTypes.bool
};

export function getFilterTooltip(column) {
    if (column.tooltip === undefined || column.tooltip === null || column.tooltip.length === 0)
        return {};

    return {
        'data-for': 'filter-tooltip',
        'data-tip': column.tooltip
    }
}