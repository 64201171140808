import React from 'react';
import PropTypes from 'prop-types';

import './Toggle.scss';

const Toggle = ({ id, optionLeft, optionRight, activeOption, onChange }) => {
    
    return (
        <div className='toggle' id={id} data-testid={id}>
            <span 
                className={`toggle--left ${(activeOption === optionLeft) && 'active'}`}
                onClick={() => onChange(optionLeft)}
                data-testid={`${id}-${optionLeft}`}
            >{optionLeft}</span>
            <span 
                className={`toggle--right ${(activeOption === optionRight) && 'active'}`}
                onClick={() => onChange(optionRight)}
                data-testid={`${id}-${optionRight}`}
            >{optionRight}</span>
        </div>
    )
}

Toggle.propTypes = {
    id: PropTypes.string,
    optionLeft: PropTypes.string,
    optionRight: PropTypes.string,
    activeOption: PropTypes.string,
    onChange: PropTypes.func
}

export default Toggle