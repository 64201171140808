import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sendEvent } from '../../../helpers/analyticsHandler';

import FormContainer from '../../Form/FormContainer';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import ErrorModal from '../../Modal/ErrorModal';
import Button from '../Button';

export default function ButtonAddMarketPlace({ id, classBtn }) {
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [redirect, setRedirect] = useState('');

    const handleClick = () => {
        setShowModal(!showModal);
        sendEvent('analyticsClickAction', 'addToMarketplace');
        setRedirect('')
    }

    const handleClickConfirmation = (redirectRequest) => {
        setShowModalConfirmation(!showModalConfirmation)
        setShowModal(!showModal)
        if(redirectRequest){ 
            setRedirect(redirectRequest)
        }
    }

    const handleError = (redirectRequest) => {
        if(redirectRequest){ 
            setRedirect(redirectRequest)
        }
        setShowModalError(!showModalError)
        setShowModal(!showModal)
        setShowModalConfirmation(false)
    }
    

    return (
        <>
            <Button className={classBtn} onClick={handleClick} id={`${id}-add-to-market-btn`} data-testid={`${id}-add-to-market-btn`}>Request for Data & AI
            </Button>
            <FormContainer
                id={`${id}-form-modal`}
                isOpen={showModal}
                handleClose={() => setShowModal(false)}
                header={'Request for Data & AI'}
                typeForm="DIA"
                handleClickConfirmation={handleClickConfirmation}
                handleError={handleError}
            />
            <ConfirmationModal
                id={`${id}-confirm-modal`}
                isOpen={showModalConfirmation}
                handleClose={() => setShowModalConfirmation(false)}
                header= { (redirect==='SNOW')? 'You will be re-directed to website[nrg.service-now.com] to submit your request in Service-Now.' : 
                            (redirect==='collibra') ? "You will be re-directed to website[nrg.collibra.com] to submit your request in Collibra." :
                "Thank you for submitting your request to the Data & AI Marketplace."}
                message='You can expect  confirmation email from Data Governance in 1 to 2 days​ and Data Governance will notify you of approval.'
            >
            </ConfirmationModal>
            <ErrorModal
                id={`${id}-error-modal`}
                isOpen={showModalError}
                handleClose={() => setShowModalError(false)}
                message= {(redirect!=='') ? "Your request has failed. Please submit your request again. If you're not redirected to a new site to raise a follow-up request, please ensure that your pop-up blocker is disabled for this site." 
                            : 'Your request has failed. Please submit your request again.'}
            >
            </ErrorModal>
        </>
    );
}

ButtonAddMarketPlace.propTypes = {
    id: PropTypes.string,
    classBtn: PropTypes.string
}