/*jshint esversion: 6 */
import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";

import './App.scss';

import { AppRouter } from './routers/AppRouter';

import ScrollToTop from './components/ScrollTo/ScrollToTop';
import Navbar from './components/Navbar/Navbar';
import SidebarMenu from './components/Sidebar/SidebarMenu';
import Login from './components/Login/Login';
import Toast from './components/Toast/Toast';

export default function App() {

    return (
        <div className="App" data-testid="app">
            <UnauthenticatedTemplate>
                <p>Redirecting to Login...</p>
                <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <BrowserRouter>
                    <ScrollToTop />
                    <Navbar />
                    <Toast />
                    <main>
                        <SidebarMenu />
                        <AppRouter />
                    </main>
                </BrowserRouter>
            </AuthenticatedTemplate>
        </div>
    );
}

