const user = {}

const userState = {
    setUser: function (accessToken, account, tokenExpirationTime) {
        user.accessToken = accessToken;
        user.account = account;
        user.tokenExpirationTime = tokenExpirationTime;
    },
    getAccessToken: async function () {
        if(user.tokenExpirationTime !== undefined  && new Date(user.tokenExpirationTime)<new Date()){
            this.expireToken();
            await delay(100)
            return await this.getAccessToken();
        }
        else if (user.accessToken === undefined) {
            console.log('user state not set, pausing.....');
            await delay(100)
            return await this.getAccessToken();
        } else {
            return user.accessToken;
        }
    },
    expireToken: function () {
        user.accessToken = undefined;
        user.tokenExpirationTime = undefined;
        console.log('expiring user token');
        if (typeof this.expireCallback === 'function') {
            this.expireCallback();
        }
    },
    setExpireCallback(expireCallBackFunc) {
        this.expireCallback = expireCallBackFunc;
    }
};

// pause until user token is set
function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export default userState;
