import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button/Button';

import './RecommendationsPopup.scss';

const RecommendationsPopup = ({ id, isOpen, handleClose, handleAction }) => {
    return ( 
        <>
            <div id={id} data-testid={id} className={`popup-container ${!isOpen ? 'hide' : 'show'}`}></div>
            <div className={`popup-main ${!isOpen ? 'hide' : 'show'}`}>
                <div className='popup-content'>
                    <p className='popup-header'>I’m new to the Data & AI Marketplace. Where do I start?</p>
                    <p>You can start by viewing recommendations we’ve picked out, or by seeing what’s new in the Data & AI Marketplace.
                        <br></br>
                        <br></br>
                        Just click the recommendations page to begin.
                    </p>
                    <div className='popup-btns'>
                        <Button 
                            className='btn--plain-underline' 
                            id={`${id}-skip-for-now`} 
                            onClick={handleClose}
                        ><span>Skip for now</span></Button>
                        <Button 
                            className='btn--rounded btn--blue-white no-outline' 
                            id={`${id}-get-started`} 
                            onClick={handleAction}
                        ><span>Get started</span></Button>
                    </div>
                </div>
            </div>
        </>
  )
}

RecommendationsPopup.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func, 
    handleAction: PropTypes.func
}

export default RecommendationsPopup