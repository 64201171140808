import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from "phosphor-react";
import PropTypes from 'prop-types';
import { sendEvent } from '../../../helpers/analyticsHandler';

import './BackButton.scss';

const BackButton = ({ id, text, handleClick }) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if(handleClick===undefined){
            sendEvent('analyticsClickAction', 'back');
            navigate(-1);
        }else{
            handleClick();
        }
    
    }

    return (
        <div id={`${id}-back-btn`} data-testid={`${id}-back-btn`} onClick={() => handleOnClick()} className='back-button'>
            <div className='circle circle--blue'> <ArrowLeft size={16} weight="bold" /></div><p>{text===undefined ? 'Back' : text}</p>
        </div>
    )
}

BackButton.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    handleClick: PropTypes.func
};

export default BackButton