import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

import { Actions } from '../../contexts/action';
import { Context } from '../../contexts/context';
import { sendEvent } from '../../helpers/analyticsHandler';
import { ExecuteDSModel, GetLastDSModelRun, GetProductDetail, TrackAssetUsage } from '../../services/dmpApi';

import BackButton from '../../components/Button/BackButton/BackButton';
import DataUnavailable from '../../components/DataUnavailable/DataUnavailable';
import Drawer from '../../components/Drawer/Drawer';
import FormDataScienceModelExecution from '../../components/Form/FormDataScienceModelExecution';
import { Tab, TabPanel, Tabs } from '../../components/Tabs/Tabs';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';
import DataDefinition from '../../components/product/DataDefinition';
import DataTraceability from '../../components/product/DataTraceability';
import ProductDetailContainer from '../../components/product/ProductDetailContainer';

import clockWise from '../../assets/icons/clock-wise.svg';

import './productDetailPage.scss';

const ProductDetailPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataDefinition, setDataDefinition] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    const [resultsCount, setResultsCount] = useState(0);
    const { id } = useParams();
    const {
        state: {
            persona
        },
        dispatch
    } = useContext(Context);
    const [showTabsContainer, setShowTabsContainer] = useState(true);
    const [loadError, setLoadError] = useState('');
    const [showLaunchModal, setShowLaunchModal] = useState(false);
    const [formFields, setFormFields] = useState({});
    const [DSModelStatus, setDSModelStatus] = useState("");
    const [lastDSModalErrorMessage, setLastDSModalErrorMessage] = useState("");
    const [lastDSModalOutputDate, setLastDSModalOutputDate] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [lastDSModelRunId, setLastDSModelRunId] = useState("")
    const [previousRunDownloadStatus, setPreviousRunDownloadStatus] = useState("")

    function openProductHandler(presentationFlag) {
        TrackAssetUsage(id)
            .catch(error => {
                console.log(error);
            });

        sendEvent('analyticsClickAction', 'product_launch', null, null, data);

        const newWindow = window.open(getProductUrl(data,presentationFlag), '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    function getProductUrl(data, presentationFlag) {
        
        if (data.type === 'Data Science Product' || presentationFlag) {
            return data.presentation;
        } else if (data.access === 'app' && data.appUrl) {
            return data.appUrl;
        }

        let url = data.url.replace(/&amp;/g , "&");
        return url;
    }

    function openProductDetailHandler(product) {
        navigate(`/product/${product.id}`, {
            state: {
                id: product.id,
                name: product.name,
                type: product.type
            }
        });

        sendEvent('analyticsClickAction', 'product_target', null, null, product);
    }

    function toastDownloadButton(data, isOpen){
        openProductDetailHandler(data)
        toggleLaunchModal(isOpen)
    }

    const fetchData = async () => {
        const response = await GetProductDetail(id)
            .catch((err) => {
                setLoadError('Error loading product details. ' + err);
                setIsLoading(false);
                console.log(err)
            });

        if (response) {
            const data = response.data;
            
            setData(data);
            setDataDefinition(response.data.relationships?.definitions);
            setResultsCount(response.data.relationships?.definitions?.length);
            setActiveTab((["Data Set","Data Entity","Schema"].includes(data.type)) ? 2 : data.type==="Gen AI Use Case" ? 4 : 1);
            if (data.reportType === 'Spoke' && response.data.relationships?.definitions?.length === 0) {
                setShowTabsContainer(false);
            }
            setShowModal(!((data.access && data.access!=='none') || data.type==='Data Science Product'))

            if (loadError.length > 0) setLoadError('');
            setIsLoading(false);

            if(data.type === "Data Science Product" && data.modelExecution){
                fetchLastDSModelRun()
                if(!showToast && DSModelStatus==="Execution Successful"){
                    setShowToast(true)
                    const toastProperties = {
                        id: "update-model-run",  
                        type: 'success',
                        title: 'Renewal Pricing Model run complete',
                        actionFunc: toastDownloadButton,
                        display: true,
                        data: data,
                        message: `Model run successfully completed and your output is ready to download.`
                    }
                    dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                }
             }
        }
    };

    const fetchLastDSModelRun = async() => {
        await GetLastDSModelRun(id)
        .then(response =>{
            if(response.data.runStatus==="1"){
                setDSModelStatus("Execution Successful")
                setLastDSModalOutputDate(response.data.endAt) 
                setLastDSModelRunId(response.data.runId)
                setPreviousRunDownloadStatus(response.data.isDownloaded)
            }else if(response.data.runStatus==="0"){
                setDSModelStatus("Validation Successful")
            }else if(response.data.runStatus==="-1"){
                setDSModelStatus("Execution Failed")
                setLastDSModalErrorMessage(response.data.errorMessage)
                setLastDSModalOutputDate(response.data.endAt) 
            }
        })
        .catch((err) => {
            //setLoadError('Error loading last DS model run details. ' + err);
            console.log(err)
        });

    }

    useEffect(() => {
        setIsLoading(true);
        setActiveTab(1);
        fetchData();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleChange = (e, value) => {
        setActiveTab(value);
    };

    const getDataTraceabilityTab = () => {
        if (persona.flags.includes('HIDE-DETAIL-TRACEABILITY') || data.reportType === 'Spoke' || data.type==="Gen AI Use Case") {
            return null;
        } else {
            return <Tab id='product-detail-page-tab-data-traceability' key={2} label={(["Data Set","Data Entity","Schema"].includes(data.type)) ? `Tables` : `Data Discovery`} value={2} />
        }
    }

    const getDataDefinitionTab = () => {
        if ((data.reportType === 'Spoke' && resultsCount === 0) || (["Data Set","Data Entity","Schema","Gen AI Use Case"].includes(data.type))) {
            return null;
        } else {
            return <Tab id='product-detail-page-tab-definitions' key={1} label="Definitions" value={1} />
        }
    }

    const getProductVersionTab = () => {
        if (data.type === 'Data Science Product') {
            return <Tab id='product-detail-page-tab-product-version' key={3} label="Product Version" value={3} />;
        } else {
            return null;
        }
    }

    const getFormattedDate = (timeStamp) => {
        let date = new Date(timeStamp);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        let formattedDate = month + '/' + day + '/' + year;

        return formattedDate;
    }

    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    function errorResult() {
        return (
            <div data-testid='search-page-error' onClick={() => fetchData()} className='loading-error'>
                <span>{loadError}<br />
                    Click here to retry</span>
            </div>
        );
    }
    
    const toggleLaunchModal = (isOpen) => {
        setShowLaunchModal(isOpen);
        setFormFields({});
        if(!isOpen){
            fetchLastDSModelRun();
        }
    }
 
    const handleFormFieldChange = (childState) => {
       setFormFields({...formFields,...childState});
    }

    const handleDSModelSubmit =  async(model_id, asset_id) => {
        setDSModelStatus("Validating")
        await ExecuteDSModel(model_id, asset_id, formFields)
            .then(response => {
            setDSModelStatus("Validation Successful")
            setFormFields({})
            })
            .catch((err) => {
                //setLoadError('Error loading product details. ' + err);
                setLastDSModalErrorMessage(err+".")
                setDSModelStatus('Execution Error')
                setIsLoading(false);
                console.log(err)
            });
        
    }

    function detailView() {
        return (
            <>
                <section className='product-detail'>
                    <ProductDetailContainer
                        id='product-detail-page-container'
                        data={data}
                        dataType={data?.type}
                        openProductHandler={openProductHandler}
                        openProductDetailHandler={openProductDetailHandler}
                        toggleLaunchModal = {(state) => toggleLaunchModal(state)}
                        DSModelStatus={DSModelStatus}
                        lastDSModelRunId={lastDSModelRunId}
                    />
                </section>
                {showTabsContainer &&
                    <section className='tab'>
                        <div className='tab--container'>
                            <Tabs id='product-detail-page-tabs' selectedTab={activeTab} onChange={handleChange}>
                                {getDataDefinitionTab()}
                                {getDataTraceabilityTab()}
                                {getProductVersionTab()}
                            </Tabs>
                        </div>
                        <div className={activeTab === 0 || data.length === 0 ? 'tab--panel-container inactive' : 'tab--panel-container'}>
                            <TabPanel value={activeTab} selectedIndex={1}>
                                {resultsCount > 0
                                    ? <DataDefinition data={dataDefinition} resultCount={resultsCount} productType={data.type} id='product-detail' />
                                    : <DataUnavailable id='product-detail-page-definition-unavailbale' subtitle='Definition information is temporarily unavailable for this data product, please check back later.' icon={clockWise}/>
                                }

                            </TabPanel>
                            <TabPanel value={activeTab} selectedIndex={2}>
                                {data?.relationships && (!["Data Set","Data Entity","Schema"].includes(data.type) ? (data?.relationships?.sources?.length > 0 || data?.relationships?.targets?.length > 0) : data?.relationships?.table?.length>0)
                                    ? <DataTraceability data={data} openProductDetailHandler={openProductDetailHandler} />
                                    : <DataUnavailable id='product-detail-page-traceability-unavailbale' subtitle={["Data Set","Data Entity","Schema"].includes(data.type) ? 'Tables information is temporarily unavailable for this data product, please check back later.' : 'Traceability information is temporarily unavailable for this data product, please check back later.'} icon={clockWise}/>
                                }
                            </TabPanel>
                            <TabPanel value={activeTab} selectedIndex={3}>
                                <div className='product-version'>
                                    <div className={`version-container ${getTypeColor(data.type)}`}>
                                        <span className='version-title'>Version</span>
                                        <span className='version-info'>{data.version}</span>
                                    </div>
                                    <div className={`version-container ${getTypeColor(data.type)}`}>
                                        <span className='version-title'>Product Launch Date</span>
                                        <span className='version-info'>{getFormattedDate(data.startDate)}</span>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </section>
                }
            </>
        );
    }

    return (
        <section data-testid='product-detail-page'>
            <div className='content--container product-detail--container'>
            <div className="header">
                <BackButton id='product-detail' />
            </div>
            {isLoading &&
                <div data-testid={'product-detail-page-loading'} className="product-detail-spinner">
                    <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                    <span>Loading....</span>
                </div>
            }
            {!isLoading && loadError && loadError.length > 0 && errorResult()}
            {!isLoading && loadError?.length === 0 ?
                <>{(data?.access && data?.access!=='none') || data.type ==='Data Science Product' ? 
                    <div data-testid='product-detail-page' className='product-detail-page-container'>{detailView()}</div>
                    : 
                    <>
                        <div data-testid='search-page-error' onClick={() => setShowModal(true)} className='loading-error'>
                            <span>You do not have permissions to view this page. Click here to request access.</span>
                        </div>
                        <DetailDrawer
                            id='search-page-details'
                            itemId={data.id}
                            modalActive={showModal}
                            setModalState={(state) => setShowModal(state)}
                            data-testid={data.id}
                        />
                    </>
                }
                </>
                : null
            }
            </div>
            {data.modelExecution && <Drawer 
                isOpen={showLaunchModal}
                handleClose={(state) => toggleLaunchModal(state)}
                id={'DSAPI-Model-drawer'}>
                <FormDataScienceModelExecution
                    id = {data.id}
                    model_id={data.modelExecution.model_id}
                    handleSubmit={handleDSModelSubmit}
                    assetName={data.name}
                    modelExecutionParameters = {data.modelExecution.input_parameters}
                    onFieldChange={handleFormFieldChange}
                    DSModelStatus={DSModelStatus}
                    lastDSModalErrorMessage={lastDSModalErrorMessage}
                    lastDSModalOutputDate={lastDSModalOutputDate}
                    lastDSModelRunId={lastDSModelRunId}
                    previousRunDownloadStatus={previousRunDownloadStatus}
                ></FormDataScienceModelExecution>
            </Drawer>}
        </section>

    );

};

export default ProductDetailPage;