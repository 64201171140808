import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-loader-spinner';
import Modal from './Modal';
import FormTextArea from '../Form/FormTextArea';
import Button from '../Button/Button';

import './AddPersonaUserModal.scss';


const AddPersonaUserModal = ({ id, isOpen, personaName, handleClose, handleSubmitForm, isSubmitLoading,
     handleUserNameFieldChange, handleUserEmailFieldChange, userName, userNameError, userEmail, userEmailError }) => {
    
    return (
        <><div className='add-persona-user-modal-container'>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleClose} size="md">
                    <div className='add-persona-user-modal'>
                        <h2>Add {personaName} User</h2>
                        <FormTextArea
                            id={`${id}-user-name`}
                            type="textarea"
                            errorMessage='This Field is required'
                            required={true}
                            rows="1"
                            cols="1"
                            name="user-name"
                            long={true}
                            label='Please enter the full name of the User'
                            placeholder="Enter Full Name"
                            value={userName}
                            handleChange={(e) => handleUserNameFieldChange(e.target.value)}
                            showError={userNameError} 
                        />
                        <FormTextArea
                            id={`${id}-user-email`}
                            type="textarea"
                            errorMessage='This is not a valid Email Address'
                            required={true}
                            rows="1"
                            cols="1"
                            name="user-email"
                            long={true}
                            label='Please enter the email address of the User'
                            placeholder="Enter Email Address"
                            value={userEmail}
                            handleChange={(e) => handleUserEmailFieldChange(e.target.value)}
                            showError={userEmailError} 
                        />
                        <div className='submit-btn'>
                            <Button
                                id={`${id}-submit-btn`}
                                className='btn--rounded btn--blue-light btn--submit'
                                onClick={() => handleSubmitForm()}
                                disabled={isSubmitLoading}
                            >
                                Add User to Persona
                            </Button>
                            {isSubmitLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="faq-modal-spinner" /></span>}
                        </div>

                    </div>
                </Modal>
            }
            </div>
        </>
    )
}

AddPersonaUserModal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    personaName: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmitForm: PropTypes.func,
    isSubmitLoading: PropTypes.bool,
    handleUserNameFieldChange: PropTypes.func,
    handleUserEmailFieldChange: PropTypes.func,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userEmailError: PropTypes.bool,
    userNameError: PropTypes.bool
}

export default AddPersonaUserModal;