import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ReactTooltip from "react-tooltip";
import shareIcon from '../../assets/icons/share.svg';
import Accordion from '../Accordion/Accordion';
import AccordionItem from '../Accordion/AccordionItem/AccordionItem';
import Button from '../Button/Button';
import DetailCard from '../DetailCard/DetailCard';
import Favorite from '../FavoriteElements/FavoriteIcon/Favorite';
import LaunchModel from '../LaunchModel/LaunchModel';
import ErrorModal from '../Modal/ErrorModal';
import ProductDescription from '../ProductElements/ProductDescription';
import ProductIcon from '../ProductElements/ProductIcon';

const ProductDetailContainer = ({ data, openProductHandler, openProductDetailHandler, id, dataType, toggleLaunchModal, DSModelStatus, lastDSModelRunId }) => {   
    const [quickAccessLinks, setQuickAccessLinks] = useState([]);
    const [governanceClickError, setGovernanceClickError] = useState(false);


    const handleGovernanceClick = () => {
        const newWindow = window.open(`https://nrg.collibra.com/asset/${data.id}`, '_blank');
        if(!newWindow){
            setGovernanceClickError(true)
        }

    }
    const handleReportTraceabilityClick =() => {
        const newWindow = window.open(`https://nrg.collibra.com/asset/${data.id}?tabbar=TraceabilityDiagram`, '_blank');
        if(!newWindow){
            setGovernanceClickError(true)
        }
    }
    
    const handleShowProduct = () => {
        openProductHandler();
    }

    const getTitleCard = () => {
        let titleCard;
        if (dataType === 'Dashboard') {
            titleCard = 'Would you like to view this dashboard?';
        } else if (dataType === 'Data Science Product') {
            titleCard = 'Would you like to learn more?';
        } else {
            titleCard = 'Would you like to work with this data?';
        }
        return titleCard;
    }

    const updatedDescription = () => {
        
        let desc = data?.description;
        if(dataType==='Gen AI Use Case'){
            if(data?.useCaseApplication){
                desc += '<br/><br/><b>Use Case Application : </b>'+data?.useCaseApplication;
            }
            if(data?.useCaseStage){
                desc+='<br/><br/><b>Use Case Stage : </b>'+data?.useCaseStage
            }
        }
        return desc;
    }
    const updateUrlInDescription = (description) => {      
        let urlRegex = /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
        return description.replace(/href="(.*?)"/g, (match,url) => {
            //check if it is a collibra url
            if(!url.startsWith("https://nrg.collibra.com")){
                //check if it is not a valid url
                if(!urlRegex.test(url)){
                    return `href="https://nrg.collibra.com${url}"`;
                }
            }
            return match;
        })
    }
 
    const getParagraphCard = () => {
        let paragraphCard = 'Select launch product to open data in a new tab.';
        if (dataType === 'Data Science Product') {
            paragraphCard = 'Select view presentation to be directed to the PowerPoint presentation in Sharepoint'
        }
        return paragraphCard;
    }

    const getQuickAccessLinkCard = () => {
        let quickAccessLinkCard = '';
        quickAccessLinks.forEach((link) => {
            if (link.uiType === 'dashboard') {
                quickAccessLinkCard += 'Select view dashboard to view the dashboard detail page. '
            } else if (link.uiType === 'output') {
                quickAccessLinkCard += 'Select view data output to view the dashboard input table. '
            } else {
                quickAccessLinkCard += 'Select view data science product to view the data science product detail page. '
            }
        });
        return quickAccessLinkCard;
    }

    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    useEffect(() => {
        const getQuickAccessLinks = () => {
            let quickAccesLinks = [];
            if (data?.relationships?.dashboards?.length > 0 && data?.relationships?.dashboards[0].status === 'Accepted') {
                quickAccesLinks.push(data?.relationships?.dashboards[0])
            }
            if (data?.relationships?.outputs?.length > 0 && data?.relationships?.outputs[0].status === 'Accepted') {
                quickAccesLinks.push(data?.relationships?.outputs[0])
            }
            if (data?.relationships?.dataProcesses?.length > 0 && data?.relationships?.dataProcesses[0].status === 'Accepted') {
                quickAccesLinks.push(data?.relationships?.dataProcesses[0])
            }
            return quickAccesLinks;
        }

        setQuickAccessLinks(getQuickAccessLinks());
    }, [data]);

    return (
        <div className='product-container'>
            <div id={id} data-testid={id} className='product-detail--container'>
                <div className='product-detail--block'>
                    <ProductIcon productType={data?.type} />
                    <p className='product-detail--title'>{data?.name}</p>
                    <span className="favorite">
                        <Favorite favoriteItem={data && data} />
                    </span>
                </div>
                {data.description && <ProductDescription description={updatedDescription(data)} />}
                <div className='data-detail-accordion'>
                    <Accordion id={`${id}-accordion`} defaultOpen={0} multiple={false}>
                        {!(["Data Set","Data Entity","Schema","Gen AI Use Case"].includes(data.type)) && <AccordionItem
                            id={`${id}-product-category`}
                            key={`${id}-product-category`}
                            name='Product Category'
                            value={0}
                            type='accordion-lg'
                            accordionItemClass={getTypeColor(data.type)}
                        >
                            <>
                                <hr />
                                <section className='category-grid' key={`${id}-product-category-section`}>
                                    {
                                        data?.relationships?.sourceSystem &&
                                        <div className='container-element' key={`${id}-dataSourceSystem`}>
                                            <p>Source System</p>
                                            <ul>
                                                {data && data?.relationships?.sourceSystem?.map(source => {
                                                    return <li key={source.id}>{source.name}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.relationships?.schema?.length>0 &&
                                        <div className='container-element' key={`${id}-dataSchema`}>
                                            <p>SCHEMA</p>
                                            <ul>
                                                {data && data?.relationships?.schema?.map((schema, idx) => {
                                                    return <li key={idx}>{schema.name}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.relationships?.schema?.length>0 &&
                                        <div className='container-element' key={`${id}-dataSchemaDescription`}>
                                            <p>SCHEMA DESCRIPTION</p>
                                            <ul>
                                                {data && data?.relationships?.schema?.map((schema, idx) => {
                                                    return <li key={idx}><div  className={'description'} dangerouslySetInnerHTML={{ __html: updateUrlInDescription(schema.description) }}></div></li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.securityClassification &&
                                        <div className='container-element' key={`${id}-dataSecurityClassification`}>
                                            <p>SECURITY CLASSIFICATION</p>
                                            <li>{data.securityClassification}</li>
                                        </div>
                                    }
                                    {
                                        data?.regions?.length > 0 &&
                                        <div className='container-element' key={`${id}-dataRegions`}>
                                            <p>Region</p>
                                            <ul>
                                                {data && data?.regions?.map((region, idx) => {
                                                    return <li key={idx}>{region}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.brands?.length > 0 &&
                                        <div className='container-element' key={`${id}-dataBrands`}>
                                            <p>Brand</p>
                                            <ul>
                                                {data && data?.brands?.map((brand, idx) => {
                                                    return <li key={idx}>{brand}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.applications?.length > 0 &&
                                        <div className='container-element' key={`${id}-dataApplications`}>
                                            <p>Business Process</p>
                                            <ul>
                                                {data && data?.applications?.map((application, idx) => {
                                                    return <li key={idx}>{application}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.databricksSchedule &&
                                        <div className='container-element' key={`${id}-dataDatabricksSchedule`}>
                                            <p>DATABRICKS SCHEDULE</p>
                                            <li>{data.databricksSchedule}</li>
                                        </div>
                                    }
                                    {
                                        data?.ingestionType &&
                                        <div className='container-element' key={`${id}-dataIngestionType`}>
                                            <p>IngestionType</p>
                                            <li>{data.ingestionType}</li>
                                        </div>
                                    }
                                </section>
                            </>
                        </AccordionItem>}
                        {(data.type !== "Data Entity" && data.type !== "Data Set" && data.type !== "Schema") && <AccordionItem
                            id={`${id}-product-owners`}
                            key={`${id}-product-owners`}
                            name='Product Owners'
                            value={1}
                            type='accordion-lg'
                            accordionItemClass={getTypeColor(data.type)}
                        >
                            <>
                                <hr />
                                <section className='owners-grid' key={`${id}-product-owners-section`}>
                                    {
                                        data?.primaryOwner?.length > 0 &&
                                        <div className='container-element' key={`${id}-dataPrimaryOwner`}>
                                            <p>Primary Owner</p>
                                            <ul className={data?.primaryOwner?.length > 3 ? 'ul-column-set' : ''}>
                                                {data && data?.primaryOwner.map((owner, index) => {
                                                    return <li key={index}>{owner.name} <span className='role-name'>({owner.roleName})</span></li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.secondaryOwner?.length > 0 &&
                                        <div className='container-element' key={`${id}-dataSecondaryOwner`}>
                                            <p>Secondary Owner</p>
                                            <ul className={data?.secondaryOwner?.length > 3 ? 'ul-column-set' : ''}>
                                                {data && data?.secondaryOwner?.map((owner, index) => {
                                                    return <li key={index}>{owner.name} <span className='role-name'>({owner.roleName})</span></li>;
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        data?.businessSponsor?.length > 0 &&
                                        <div className='container-element' key={`${id}-dataBusinessSponsor`}>
                                            <p>Business Sponsor</p>
                                                <li>{data?.businessSponsor}</li>
                                       </div>
                                    }
                                </section>
                            </>
                        </AccordionItem>}
                    </Accordion>
                </div>
            </div>
            <div className='product-card--container'>
                <DetailCard
                    title={getTitleCard()}
                    paragraph={getParagraphCard()}
                    cssClass={getTypeColor(data?.type)}
                >
                    {
                        (!(["Data Set","Data Entity","Schema","Data Science Product"].includes(dataType)) && ((data.access === 'app' && data.appUrl) || data.url)) && 
                        <Button id={`${id}-open-product`} className='btn--block-rounded btn--blue-light card-action-btns view-btns' onClick={handleShowProduct}><p>{data.type==='Gen AI Use Case' ? `Launch ${data.name}` : 'Launch Product'}</p> <img src={shareIcon} alt='Share Icon' className='typeIcon' /></Button>
                    }
                    {
                        (dataType === 'Data Science Product' || dataType==='Gen AI Use Case') &&
                        <Button
                            id={`${id}-view-presentation`}
                            data-testid={`${id}-view-presentation`}
                            className='btn--block-rounded btn--blue-light card-action-btns view-btns'
                            onClick={() => {openProductHandler(true)}} //need presentation url
                        >View Presentation<img src={shareIcon} alt='Share Icon' className='typeIcon' />
                        </Button>
                    }
                    {!data.type==='Gen AI Use Case' ?
                    <>
                    <Button id={`${id}-governance`} className='btn--block-rounded btn--blue-light card-action-btns view-btns' onClick={handleGovernanceClick}>Governance</Button>
                    <Button id={`${id}-report-traceability`} className='btn--block-rounded btn--blue-light card-action-btns view-btns' onClick={handleReportTraceabilityClick}>Data Traceability</Button>
                    </>
                        :
                        null
                    }

                </DetailCard>
                {
                    quickAccessLinks.length > 0 &&
                    <DetailCard
                        id='productDetailCard'
                        title='Quick Access Links'
                        cssClass={`${getTypeColor(data?.type)}`}
                        paragraph={getQuickAccessLinkCard()}
                    >
                        <span className='quick-access-links'>
                            {
                                quickAccessLinks.map((accessLink, idx) => {
                                    return (
                                        <div key={idx}>
                                            <ReactTooltip id={`${idx}-view-dashboard-tip`} className='custom-color-no-arrow' delayHide={200}
                                                textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
                                            <Button
                                                id={`${idx}-view-dashboard`}
                                                data-testid={`${idx}-view-dashboard`}
                                                className='btn--plain-underline'
                                                data-for={`${idx}-view-dashboard-tip`}
                                                data-tip={accessLink?.name}
                                                key={idx}
                                                onClick={() => openProductDetailHandler(accessLink)}
                                            ><span>{(accessLink.type === 'Table' || accessLink.type==='Database View') ? 'View Data Output' : (accessLink.type === 'Data Science Product' || accessLink.type === 'Data Process') ? 'View Data Science Product' : 'View Dashboard'}</span></Button>
                                        </div>
                                    )
                                })
                            }
                        </span>
                    </DetailCard>
                    }
                    {data.modelExecution && (data.access && data.access!=="none") &&<LaunchModel
                    toggleLaunchModal={toggleLaunchModal}
                    DSModelStatus={DSModelStatus}
                    lastDSModelRunId={lastDSModelRunId}
                />}
                
            </div>
            
            <ErrorModal
                id={`${id}-error-modal`}
                isOpen={governanceClickError}
                handleClose={() => {
                    setGovernanceClickError(false)}}
                message={"We are unable to redirect to 'nrg.collibra.com' right now. Please try again later."}
            >
            </ErrorModal>
        </div>
    );
};


ProductDetailContainer.propTypes = {
    data: PropTypes.any,
    openProductHandler: PropTypes.func,
    openPresentationHandler: PropTypes.func,
    openProductDetailHandler: PropTypes.func,
    id: PropTypes.string,
    dataType: PropTypes.any,
    toggleLaunchModal: PropTypes.func,
    DSModelStatus: PropTypes.string,
    lastDSModelRunId: PropTypes.string
};

export default ProductDetailContainer;