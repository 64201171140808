import React from 'react';
import PropTypes from 'prop-types';
import './BrowseCard.scss'

const BrowseCard = ({ title, children, id, type }) => {
    return (
        <>
            <div className={type ? `browse-card-${type}` : 'browse-card'} data-testid={id}>
                <div className='browse-card--header-box'>
                    <p className='browse-card--title'> {title}</p>
                </div>
                <div className='browse-card--children'>
                    {children}
                </div>
            </div>
        </>
    )
}

BrowseCard.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    id: PropTypes.string,
    type: PropTypes.string
}

export default BrowseCard;