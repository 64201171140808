/*jshint esversion: 6 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Button.scss'

export default function Button(props) {
    const myClass = `btn ${props?.className}`;
    const [
        hover,
        setHover
    ] = useState(false);
    const toggleHover = () => {
        setHover(!hover);
    };
    return (
        <button
            {...props}
            id={props.id}
            data-testid={props.id}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            type="button"
            onClick={
                !props.disabled ? props.onClick : () => { }
            }
            className={myClass || ' mt-1'}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string.isRequired,
    id: PropTypes.string
};
