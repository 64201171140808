import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import documentIcon from '../../assets/icons/document-icon.svg';
import ErrorIcon from '../../assets/icons/error-icon.svg';
import inProgressIcon from '../../assets/icons/in-progress.svg';
import successCheck from '../../assets/icons/success-check.svg';
import { GetDownloadPresignedUrl } from '../../services/dmpApi';
import Button from '../Button/Button';
import './LaunchModel.scss';

const LaunchModel = ({toggleLaunchModal, id, DSModelStatus, lastDSModelRunId}) => {
    const [statusName, setStatusName] = useState("");
    const [statusHeader, setStatusHeader] = useState("");
    const [statusBar, setStatusBar] = useState("inActive");
    const [statusIcon, setStatusIcon] = useState("");

    function modelStatus(){
        switch(DSModelStatus){
            case 'Validating':
                setStatusBar("Validation")
                setStatusName("Validating Input")
                setStatusHeader("Your input is being validated.")
                setStatusIcon(documentIcon)
                break;
            case 'Validation Successful':
                setStatusBar("Validation")
                setStatusName("In Progress")
                setStatusHeader("Your Input was validated and the model run is in progress.")
                setStatusIcon(inProgressIcon)
                break;
            case 'Validation Failed' :
                setStatusBar("Failed")
                setStatusName("Error Found")
                setStatusHeader("File Structure is invalid.")
                setStatusIcon(ErrorIcon)
                break;
            case 'Execution Successful' :
                setStatusBar("Success")
                setStatusName("Complete: Download Ready")
                setStatusHeader("Model run completed successfully.")
                setStatusIcon(successCheck)
                break;
            case 'Execution Failed':
                setStatusBar("Failed")
                setStatusName("complete: Model Run Failed")
                setStatusHeader("Model run completed [Failed].")
                setStatusIcon(ErrorIcon)
                break;
            default:
                setStatusBar("inActive")
                setStatusName("")
                setStatusHeader("")
                break;
        }
    }

    const fetchDownloadPresignedUrl = async(runId) => {
        await GetDownloadPresignedUrl(runId)
        .then(response => { 
           const link = document.createElement('a')
           link.href = response.data.downloadUrl
           link.setAttribute('download','OutputFileTest.xlsx')
           document.body.appendChild(link)
           link.click()
           alert(`Downloading DS Model Execution Output File !!`)
            
        })
        .catch((err) => {
            console.log(err)
            alert("Couldn't download your file right at the moment. Please try again !")

        })
    }
    useEffect(() => {
        modelStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[DSModelStatus])

    return (
       <div className={`launch-model ${DSModelStatus!=="" ? 'status' : ''}`} data-testid={id}>
            {DSModelStatus!=="" && <div className={`status-bar ${statusBar}`}>
                <div className='status-header'>
                    <label className='status-name'>
                    <img src={statusIcon} alt='validating input status' />
                    <span>{statusName}</span>
                    </label>
                </div>
                <div className='status-content'>
                    <label className='status-update'><span>Status: {statusHeader}</span></label>
                </div>
                {DSModelStatus === "Execution Successful" && <div className='form-download-btn'>
                <Button
                    id={`output-download-btn`}
                    className='btn--rounded btn--blue-light btn--submit'
                    onClick={() => fetchDownloadPresignedUrl(lastDSModelRunId)}
                    disabled={lastDSModelRunId===""}
                    >
                    Download Output
                </Button>
                </div>}
            </div>
            }
            <Button
            id={'launch-model'}
            data-testid={'launch-model'}
            className='btn--block-rounded btn--blue-light card-action-btns view-btns'
            onClick={() => toggleLaunchModal(true)}
            >
                Launch Model
            </Button>
        </div>
    )

}

LaunchModel.propTypes = {
    toggleLaunchModal: PropTypes.func,
    id: PropTypes.string,
    DSModelStatus: PropTypes.string,
    lastDSModelRunId: PropTypes.string

};

export default LaunchModel;