/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import questionLabel from '../../assets/icons/question-label.svg'

const FormInput = (props) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
        if (props.required && props.showError) {
            setFocused(true);
        }
    }

    useEffect(() => {
        handleFocus();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showError]);

    return (
        <div className="formInput">
            <label htmlFor={props.name}>
                {props.label}
                {
                    (props.label && props.showtooltip) &&
                    <img src={questionLabel} alt='help question' data-for='row-name-tip' data-tip={props.tooltip} />
                }
            </label>
            <ReactTooltip id='row-name-tip' className='custom-color-no-arrow' delayHide={1000}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' />
            <input
                type={props.type}
                id={props.name}
                data-testid={props.id}
                name={props.name}
                placeholder={props.placeholder}
                showtooltip={props.showtooltip}
                required={props.required}
                pattern="^[a-zA-Z0-9.!#%&'*+=?^_`{|}~\-\s]{4,50}"
                onBlur={handleFocus}
                focused={focused.toString()}
                onChange={props.handleChange}
                disabled={props.disabled}
                value={props.value}
            />
            <span className='error-message'>{props.showError ? props.errorMessage : ""}</span>
        </div>
    )
}

FormInput.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    required: PropTypes.bool,
    showtooltip: PropTypes.bool,
    tooltip: PropTypes.string,
    handleChange: PropTypes.func,
    showError: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    pattern: PropTypes.string
}

export default FormInput;