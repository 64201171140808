import React from 'react'
import PropTypes from 'prop-types'

import ReportType from './ReportType'

import { ReactComponent as HasAccessFlat } from '../../assets/icons/hasAccess-flat.svg';
import { ReactComponent as HasNoAccessFlat } from '../../assets/icons/hasNoAccess-flat.svg';

import './ProductAccessOrigin.scss';

const ProductAccessOrigin = ({ id, access, reportType }) => {
    
    return (
        <div id={id} data-testid={id} className='product-access-origin'>
            {access ? <HasAccessFlat className='access-type' /> : <HasNoAccessFlat className='access-type' />}
            <p>{access ? 'Authorized' : 'Restricted'}</p>
            {reportType!==undefined && <span className='dot-separator'>&#x2022;</span>}
            {reportType!==undefined &&
            <ReportType reportType={reportType} />}
    </div>
  )
}

ProductAccessOrigin.propTypes = {
    id: PropTypes.string,
    access: PropTypes.bool,
    reportType: PropTypes.string
}

export default ProductAccessOrigin