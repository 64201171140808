import React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../../components/Button/BackButton/BackButton';

import './NotFoundPage.scss';

export default function NotFoundPage({ id }) {
    return (
        <div id={id} data-testid={id} className='not-found--container'>
            <BackButton id={id}/>
            <section className='app-container'>
                <span>Page not found, please try your search again.</span>
            </section>
        </div>
    );
}

NotFoundPage.propTypes = {
    id: PropTypes.string
};