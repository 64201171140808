import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useState } from "react";
import { loginRequest } from "../../authConfig";
import { Actions } from "../../contexts/action";
import { Context } from "../../contexts/context";
import { GetMedia, GetProfile } from '../../services/dmpApi';
import userState from "./CurrentUser";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });

    return null;
}

function initUserState(setToken) {
    if (Object.isFrozen(userState)) return;
    userState.setExpireCallback(function () { 
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('tokenExpirationTime');
        setToken(null);
    });
    Object.freeze(userState);
}

export default function Login() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [account, setAccount] = useState(null);
    const { dispatch } = useContext(Context);

    useEffect(() => {
        function RequestAccessToken() {
            console.log('requesting user access token');
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
    
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                cacheData(accounts[0],response.accessToken,response.expiresOn);
            }).catch(() => {
                instance.acquireTokenPopup(request).then((response) => {
                    cacheData(accounts[0], response.accessToken, response.expiresOn);
                });
            });
        }
        
        if (!token){
            if(window.localStorage.getItem('access_token') && new Date(window.localStorage.getItem('tokenExpirationTime'))>=new Date()){
                cacheData(accounts[0],window.localStorage.getItem('access_token'),window.localStorage.getItem('tokenExpirationTime'));
                setToken(window.localStorage.getItem('access_token'));
            }
            else {
                RequestAccessToken();
            }
        }
        
    },[accounts,instance,token]);
    

    useEffect(() => {
        const LoadProfile = async () => {
            const response = await GetProfile()
                .catch((err) => console.log(err));
            
            if (response) {
                const data = response.data;

                let favorites = data.favorites.map(item => {
                    return {
                        type: item._source.type,
                        name: item._source.name,
                        hasAccess: item.hasAccess,
                        id: item._source.id,
                        appUrl: item._source.appUrl,
                        url: item._source.url,
                        access: item.access,
                        reportType: item._source.reportType,
                        favoritedAt: item.createdAt,
                        lastAccessed: item.lastAccessed,
                        presentation: item._source.presentation,
                        businessSegment: item._source.businessSegment,
                        domain: item._source.domain,
                        primaryOwner: item._source.primaryOwner,
                        secondaryOwner: item._source.secondaryOwner
                    }
                });

                favorites.sort(
                    (favA, favB) => new Date(favB.lastAccessed) - new Date(favA.lastAccessed),
                );

                data.persona?.recommendations?.recommendationsList?.sort(
                    (recA, recB) => recA.orderNum - recB.orderNum,
                );

                if (!sessionStorage.getItem('view-popup') && data.createdOn) {
                    let pastTwoWeeks = new Date();
                    pastTwoWeeks.setDate(pastTwoWeeks.getDate() - 14);
                    
                    if (new Date(data.createdOn) < pastTwoWeeks){                 
                        sessionStorage.setItem('view-popup', false);
                    } else {
                        sessionStorage.setItem('view-popup', true);
                    }
                }
                
                dispatch({ 
                    type: Actions.SET_PROFILE, 
                    payload: {
                        persona: data.persona,
                        isAdmin: data.isAdmin,
                        favorites: favorites,
                        createdOn: data.createdOn
                    } 
                });
               
            }
        }

        if (token !== null) {
            LoadProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token]);


    useEffect(() => {
        const LoadMedia = async () => {
            const response = await GetMedia()
                .catch((err) => console.log(err));
            
            if (response) {
                let data = response.data;
                
                dispatch({
                    type: Actions.UPDATE_MEDIA,
                    payload: data
                });
            }
            else {
                console.log('no response')
            }
        }
        if (token !== null) {
            LoadMedia();
        }
    }, [dispatch, token]);
    
    if (!isAuthenticated) return handleLogin(instance);

    function cacheData(account, token, tokenExpirationTime) {
        const accessToken=`Bearer ${token}`;
        window.localStorage.setItem('access_token',token);
        window.localStorage.setItem('tokenExpirationTime',tokenExpirationTime);
        initUserState(setToken);
        userState.setUser(accessToken, account, tokenExpirationTime);
        setAccount(account);
        setToken(token);
        //When deep linking directly to search this isn't run in time, need to fix this to use the interceptor later
        // axios.interceptors.request.use((config) => {
        //     config.headers.Authorization = `Bearer ${token}`;
        //     return config;
        // }, (error) => {
        //     return Promise.reject(error);
        // });
    }

    return (
        <>
            {account ?
                <div id="profile-div" style={{ height: 0, overflow: "hidden" }}>
                    <p>{JSON.stringify(account)}</p>
                    <p>{JSON.stringify(token)}</p>
                </div> : null
            }
        </>
    );
}