import React from "react";
import PropTypes from 'prop-types';

import { reducer } from './reducer'

export const initialState = {
    isLoaded: false,
    persona: {
        name: '',
        flags: [],
        recommendations: null
    },
    isAdmin: false,
    favorites: [],
    media: null,
    notifications: [],
    dashboardPermissions: null,
    createdOn: '',
    whatsNew: null
}

export const Context = React.createContext();

export const Provider = ({ children, stateOverride }) => {
    const [state, dispatch] = React.useReducer(reducer, stateOverride ? stateOverride : initialState);
    return <Context.Provider value={{ state, dispatch }}>
        {children}
    </Context.Provider>
}

Provider.propTypes = {
    children: PropTypes.any,
    stateOverride: PropTypes.any
};