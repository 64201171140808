import { MagnifyingGlass } from "phosphor-react";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

const DEBOUNCE_MS = 800;

export default function DefinitionGlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    className,
    onSearch,
    id
}) {
    const [value, setValue] = useState(globalFilter);

    // debounce pauses async refrech for 200ms while typing
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, DEBOUNCE_MS);

    const onKeyPressHandler = function (e) {
        setGlobalFilter(value || undefined);
        let charCode = e.which || e.charCode || e.keyCode || 0;
        if (charCode === 13) onSearchSubmit();
    }

    const onSearchSubmit = function () {
        setGlobalFilter(value || undefined);
        if (onSearch !== undefined)
            onSearch();         
    }

    return (

        <div id={id} data-testid={id} className={'search-global-filter ' + (className !== undefined ? className : '')}>
            <input data-testid={`${id}-input`} type='search' placeholder={`Search ${preGlobalFilteredRows.length} definitions ...`}
                value={value || ""}
                onKeyPress={onKeyPressHandler}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }} />
            <button data-testid={`${id}-search-btn`} onClick={onSearchSubmit}><MagnifyingGlass size={20} className='icon' /><span>Search</span></button>
        </div>
    );
}

DefinitionGlobalFilter.propTypes = {
    globalFilter: PropTypes.any,
    setGlobalFilter: PropTypes.func.isRequired,
    preGlobalFilteredRows: PropTypes.array.isRequired,
    className: PropTypes.string,
    onSearch: PropTypes.func,
    id: PropTypes.string
};