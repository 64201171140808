import { MagnifyingGlass } from "phosphor-react";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { sendEvent } from "../../helpers/analyticsHandler";

import './SearchBar.scss';

export default function SearchBar({ id }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    // useParams doesn't work here because this element is rendered outside of the <Route /> 
    // Will have to parse the pathname manually
    const location = useLocation();

    useEffect(() => {
        const path = decodeURI(location.pathname); 
        
        if (!path.startsWith('/search/')) {
            setSearchTerm('');
            return;
        }

        const term = path.replace('/search/', '');
        if (term && term.length > 0 && term !== searchTerm) {
            setSearchTerm(term);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const submit = () => {
        navigate(`/search/${searchTerm}`);
        sendEvent('analyticsClickAction', 'internalSearch');
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            submit();
        }
    };

    const handleChange = (e) => {
        if(e.target.value.length===0){
            navigate(`/search`);
            sendEvent('analyticsClickAction', 'internalSearch');
        }
        setSearchTerm(e.target.value)
    }

    return (
        <div className='search-background'>
            <div id={id} data-testid={id} className="search-container">
                <input
                    id={`${id}-search-input`}
                    data-testid={`${id}-search-input`}
                    type='search'
                    value={searchTerm}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleKeyPress}
                    placeholder={`Search Data & AI Marketplace by keyword ...`}
                />
                <button id={`${id}-search-btn`}
                    data-testid={`${id}-search-btn`}
                    onClick={submit}
                >
                    <MagnifyingGlass size={20} />
                    <span>Search</span>
                </button>
            </div>
        </div>
    );
}

SearchBar.propTypes = {
    row: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
    globalFilter: PropTypes.any,
    setGlobalFilter: PropTypes.any,
    count: PropTypes.any,
    id: PropTypes.string
};
