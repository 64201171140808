import React from 'react';
import PropTypes from 'prop-types';
import clockWise from '../../../assets/icons/clock-wise.svg';
import './FavoritesUnavailable.scss';

const FavoritesUnavailable = ({ id }) => {

    return (
            <div data-testid={id} className='no-favorites'>
                <img src={clockWise} alt="clock" />
                <p className='no-favorites-header'>No Favorites Yet!</p>
                <p>Click the heart icon on any data asset to add a favorite.</p>
            </div>
    );
};

FavoritesUnavailable.propTypes = {
    id: PropTypes.string
};

export default FavoritesUnavailable;