import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Accordion = ({ id, defaultOpen, multiple, children, globalClicked, setGlobalClicked, globalAccordion }) => {
    const [clicked, setClicked] = useState(defaultOpen !== undefined ? defaultOpen : -1);

    const accordionItems = React.Children.map(children, child => {
        if (child && typeof (child.type) !== 'string') {
            const handleToggle = (idx) => {
                if (globalAccordion) {
                    if (globalClicked === idx) {
                        return setGlobalClicked(-1);
                    }
                    setGlobalClicked(idx);
                } else {
                    if (clicked === idx) {
                        return setClicked(-1);
                    }
                    setClicked(idx);
                }
            }

            return React.cloneElement(child, {
                active: globalAccordion ? child.props.value === globalClicked : child.props.value === clicked,
                onToggle: () => handleToggle(child.props.value),
                multiple: multiple,
                openbydefault: child.props.value === defaultOpen
            });
        } else if(child) {  // Setting Props on non-react elements creates errors (such as <hr> elements)
            return React.cloneElement(child);
        }
    });

    return (
        <ul id={id} data-testid={id} className='accordion-container'>
            {accordionItems}
        </ul>
    )
}

Accordion.propTypes = {
    id: PropTypes.string,
    defaultOpen: PropTypes.number,
    multiple: PropTypes.bool,
    children: PropTypes.any,
    globalAccordion: PropTypes.bool,
    globalClicked: PropTypes.number,
    setGlobalClicked: PropTypes.func
}

export default Accordion;