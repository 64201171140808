import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from "react-tooltip";

import Favorite from '../../FavoriteElements/FavoriteIcon/Favorite';
import ProductIcon from '../../ProductElements/ProductIcon';

import arrow from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as LaunchProduct } from '../../../assets/icons/share-grey.svg';
import { ReactComponent as RequestAccess } from '../../../assets/icons/unlock.svg';

import './FavoritesGroupedCard.scss';

const FavoritesGroupedCard = ({ id, icon, title, items, handleItemClick, handleLaunchProduct, handleRequestAccess }) => {
    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    return (
        <>
            <div className='favorites-group-card' data-testid={id}>
                <div className='group-card-header'>
                    <span className='header-title'>
                        {icon && <img src={icon} alt={`${id}-title-icon`} />}
                        <p>{title.replace('Operations', 'Ops')}</p>
                    </span>
                    <p className='header-subtitle'> {items.length} item{items.length !== 1 && 's'}</p>
                </div>
                <hr />
                <div className='favorites-group-card--items'>
                    {items.map((item, idx) => {
                        return (
                            <div className={`card-item ${getTypeColor(item.type)}`} key={idx} onClick={() => handleItemClick(item)} data-testid={`${id}-${idx}`}>
                                <span className='item-header'>
                                    <ReactTooltip id='favorites-item-title' className='custom-color-no-arrow' delayHide={200}
                                        textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
                                    <ProductIcon productType={item.type}></ProductIcon>
                                    <p
                                        className='item-name'
                                        data-for='favorites-item-title'
                                        data-tip={item.name}
                                    >{item.name}</p>
                                    <img src={arrow} alt='name-arrow' className='arrow-separator' />
                                </span>
                                <span className='item-actions'>
                                    {item.hasAccess === true ?
                                        <>
                                            <LaunchProduct onClick={(e) => handleLaunchProduct(e, item)} data-testid={`${id}-${idx}-launch`} />
                                        </>
                                        : <RequestAccess onClick={(e) => { handleRequestAccess(e, item) }} data-testid={`${id}-${idx}-access`} />
                                    }
                                    <Favorite favoriteItem={item}></Favorite>
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

FavoritesGroupedCard.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.any,
    title: PropTypes.string.isRequired,
    items: PropTypes.array,
    handleItemClick: PropTypes.func,
    handleLaunchProduct: PropTypes.func,
    handleRequestAccess: PropTypes.func
}

export default FavoritesGroupedCard;