import React, { useState } from 'react';
import PropTypes from 'prop-types';

const BrowseItem = ({ icon, name, id, subtitle, handleItemClick }) => {
    const [isSelected, setIsSeleted] = useState(false);

    const handleClick = () => {
        setIsSeleted(!isSelected);
        handleItemClick()
    }
    return (
        <>
            <div className={`browse-item ${isSelected ? 'selected-item': ''}`} key={name} data-testid={id} onClick={handleClick}>
                <img src={icon} alt={name}/>
                <p>{name}</p>
                {subtitle !== '' ? <p className = 'browse-item-subtitle'><span className='subtitle-braces'>&#40;</span>{subtitle}<span className='subtitle-braces'>&#41;</span></p> : <p></p>}
            </div>
        </>
    )
}

BrowseItem.propTypes = {
    icon: PropTypes.any,
    name: PropTypes.any,
    id: PropTypes.string,
    subtitle: PropTypes.string,
    handleItemClick: PropTypes.func
}

export default BrowseItem;