import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-loader-spinner';

import { GetProductDetail } from '../../services/dmpApi';
import { sendEvent } from '../../helpers/analyticsHandler';

import DetailsView from './DetailsView';
import Drawer from '../Drawer/Drawer';
import FormContainer from '../Form/FormContainer';
import ConfirmationModal from '../Modal/ConfirmationModal';
import ErrorModal from '../Modal/ErrorModal';

const DetailDrawer = (props) => {
    const [result, setResult] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [loadError, setLoadError] = useState('');

    const handleClick = () => {
        setShowModal(!showModal);
        sendEvent('analyticsClickAction', 'drawerRequestAccess', null, null, result);
    }
    const handleClickConfirmation = () => {
        setShowModalConfirmation(!showModalConfirmation)
        setShowModal(!showModal)
    }

    const handleError = () => {
        setShowModalError(!showModalError)
        setShowModal(!showModal)
    }

    const LoadData = async () => {
        if (!props.itemId) {
            setIsLoading(false);
            setResult({});
            return;
        }
        setIsLoading(true);
        GetProductDetail(props.itemId)
            .then(response => {
                setResult(response.data);
                if (loadError.length > 0) setLoadError('');
                setIsLoading(false);
            }).catch(error => {
                setLoadError('Error loading product details. ' + error);
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        if (props.modalActive) {
            LoadData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modalActive]);

    function errorResult() {
        return (
            <div data-testid='detail-drawer-error' onClick={() => LoadData()} className='loading-error'>
                <span>{loadError}<br />
                    Click here to retry</span>
            </div>
        );
    }

    return (
        <div data-testid={props.id}>
            <Drawer
                isOpen={props.modalActive}
                handleClose={props.setModalState}
                id={`${props.id}-drawer`}
            >
                {isLoading &&
                    <Grid color="#999999" height={100} width={110} ariaLabel='loading' wrapperClass="drawer-spinner" />
                }
                {!isLoading && loadError && loadError.length > 0 && errorResult()}
                {!isLoading && loadError?.length === 0 ?
                    <DetailsView id={`${props.id}-details-view`} result={result} handleClick={handleClick} />
                    : null
                }
            </Drawer>
            <FormContainer
                id={`${props.id}-form-modal`}
                isOpen={showModal}
                handleClose={() => setShowModal(false)}
                header={'Request Access'}
                typeForm="DAA"
                handleClickConfirmation={handleClickConfirmation}
                handleError = {handleError}
                asset={{
                    id: props.itemId,
                    name: result?.name,
                    type: result?.type,
                    primaryOwners: result?.primaryOwner,
                    secondaryOwners: result?.secondaryOwner
                }}
            />
            <ConfirmationModal
                isOpen={showModalConfirmation}
                id={`${props.id}-confirmation-modal`}
                handleClose={() => setShowModalConfirmation(false)}
                header='Thank you for submitting your request for access.'
                message='You can expect confirmation email from Data Governance in 1 to 2 days​ and Data Governance will notify you of approval.'
            >
            </ConfirmationModal>
            <ErrorModal
                isOpen={showModalError}
                id={`${props.id}-error-modal`}
                handleClose={() => setShowModalError(false)}
                message={'Your request has failed. Please submit your request again.'}
            >
            </ErrorModal>

        </div>
    );

}
DetailDrawer.propTypes = {
    itemId: PropTypes.string,
    modalActive: PropTypes.bool,
    setModalState: PropTypes.func,
    id: PropTypes.string
};

export default DetailDrawer;