/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

import arrow from '../../assets/icons/arrow-up.svg';
import { ReactComponent as HasAccessFlat } from '../../assets/icons/hasAccess-flat.svg';
import { ReactComponent as HasNoAccessFlat } from '../../assets/icons/hasNoAccess-flat.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share-grey.svg';

import { Context } from '../../contexts/context';
import { sendEvent, trackSort } from '../../helpers/analyticsHandler';
import { ProductSearch, TrackAssetUsage } from '../../services/dmpApi';

import BackButton from '../../components/Button/BackButton/BackButton';
import Favorite from '../../components/FavoriteElements/FavoriteIcon/Favorite';
import FormContainer from '../../components/Form/FormContainer';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import ProductIcon from '../../components/ProductElements/ProductIcon';
import ReportType from '../../components/ProductElements/ReportType';
import ActionsDropdown from '../../components/datatable/ActionsDropdown';
import { AccessFilter, ApplyFilter, ArrayMultiSelectFilter, LegacyFilter, MultiSelectFilter, OwnershipFilter } from '../../components/datatable/Filters';
import StatefulDatatable from '../../components/datatable/StatefulDatatable';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';

import ErrorModal from '../../components/Modal/ErrorModal';
import ProductAccessOrigin from '../../components/ProductElements/ProductAccessOrigin';
import './SearchPage.scss';

export default function SearchPage(props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isFavoritesLoading, setIsFavoritesLoading] = useState(true);
    const [loadError, setLoadError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    const [results, setResults] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [clickedItem, setClickedItem] = useState(null);
    const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);
    const [showRequestAccessConfirmModal, setShowRequestAccessConfirmModal] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const { term } = useParams();
    const {
        state: {
            isLoaded
        }
    } = useContext(Context);

    useEffect(() => {
        LoadData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    function handleRowClick(row) {
        if (row.hasAccess === true || row.type==='Data Science Product') {
            navigate(`/product/${row.id}`, {
                state: {
                    id: row.id,
                    name: row.name,
                    type: row.type
                }
            });
        } else {
            setModalId(row.id);
            setShowModal(!showModal);
        }
    }

    function LoadData() {
        setIsLoading(true);
        ProductSearch(term)
            .then(response => {
                if (response !== undefined && response.data !== undefined && response.data.results !== undefined) {
                    setResults(response.data.results);
                    if (loadError.length > 0) setLoadError('');
                } else {
                    setLoadError('Error gathering Search results. ' + response);
                    console.log('Search Error', response);
                }
                setIsLoading(false);
            }).catch(error => {
                setLoadError('Error loading Search results. ' + error);
                setIsLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        if (isLoaded) {
            setIsFavoritesLoading(false);
        }
    }, [isLoaded]);

    const handleShowProduct = (e, row) => {
        if (e) {
            e.stopPropagation();
        }
        setClickedItem(row);
        openProductHandler(row);
    };

    function openProductHandler(row) {
        TrackAssetUsage(row.id)
            .catch(error => {
                console.log(error);
            });

        sendEvent('analyticsClickAction', 'search_launch', null, null, row);

        const newWindow = window.open(getProductUrl(row), '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    function getProductUrl(data) {
        if (data.type === 'Data Science Product') {
            return data.presentation;
        } else if (data.access === 'app' && data.appUrl) {
            return data.appUrl;
        }
        let url = data.url.replace(/&amp;/g , "&");
        return url;
    }

    function buildActionOptions(hasAccess, type) {
        let tempOptions = [];
        if (hasAccess) {
            tempOptions.push('View Details');
        } else {
            tempOptions.push('Overview');
            tempOptions.push('Request Access');
        }
        return tempOptions;
    }

    function handleAction(actionType, row) {
        setClickedItem(row);

        if (actionType === 'View Details' || actionType === 'Overview') {
            handleRowClick(row);
        } else if (actionType === 'Request Access') {
            setModalId(row.id);
            setShowRequestAccessModal(true);
        } else if (actionType.includes('Launch')) {
            handleShowProduct(null, row);
        }

        sendEvent('analyticsClickAction', 'search_details', null, actionType, row);
    }

    const handleClickRequestAccessConfirmation = () => {
        setShowRequestAccessConfirmModal(!showRequestAccessConfirmModal);
        setShowRequestAccessModal(!showRequestAccessModal);
    };

    const handleModalErrorRequestAccess = () => {
        setShowModalError(!showModalError)
        setShowRequestAccessModal(!showRequestAccessModal)
    }

    function handleSortChange(option, setSortBy, sortBy) {
        switch (option) {
            case 'Alphabetical A-Z':
                setSortBy([{ id: "name", desc: false }]);
                break;
            case 'Alphabetical Z-A':
                setSortBy([{ id: "name", desc: true }]);
                break;
            default:
                setSortBy([{ id: "id", desc: false }]);
        }

        trackSort(option);
        sendEvent('analyticsClickAction', 'search_sort', null, option, null);
    }

    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "Type Name",
                accessor: "type",
                filterLabel: "Product Type",
                Filter: MultiSelectFilter,
                filter: ApplyFilter
            },
            {
                Header: "Domain",
                accessor: "domain",
                filterLabel: "Domain",
                Filter: ArrayMultiSelectFilter,
                filter: ApplyFilter
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Segment",
                accessor: "businessSegment",
                filterLabel: "Segment",
                Filter: ArrayMultiSelectFilter,
                filter: ApplyFilter,
            },
            {
                Header: "Data Origin Name",
                accessor: "reportType",
                filterLabel: "Data Origin",
                Filter: MultiSelectFilter,
                filter: ApplyFilter
            },
            {
                Header: "",
                accessor: "hasAccess",
                filterLabel: "Access",
                Filter: AccessFilter,
                filter: ApplyFilter,
            },
            {
                Header: "id",
                accessor: "id",
            },
            {
                Header: "Legacy Acquisition",
                accessor: "legacyBrands",
                Cell: ({ row }) => (
                    row.values.legacyBrands.map((brand, id) => {
                        return (
                            <p key={id} id={`brand_${id}`}>{brand}</p>
                        );
                    })
                ),
                Filter: LegacyFilter,
                filter: ApplyFilter,
                filterLabel: "Legacy Acquisition",
                tooltip: 'This filter allows you to see only Legacy DE, Legacy XOOM, or Legacy EPNet datasets, not an enterprise view.'
            },
            {
                Header: "Name",
                accessor: "nameIcon",
                className: "title-column",
                Cell: ({ row }) => (
                    <>
                        <button>{row.values.name}</button>
                        <img src={arrow} alt='name-arrow' className='arrow-separator' />
                        <ReactTooltip id='row-name-tip' className='custom-color-no-arrow' delayHide={1000}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                    </>
                )
            },
            {
                Header: "Product Type",
                accessor: "typeIcon",
                className: "type-icon-column",
                Cell: ({ row }) => (
                    <>
                        <ProductIcon productType={row.values.type} />
                        {row.values.type}
                    </>
                )
            },
            {
                Header: "Has Access",
                accessor: "hasAccessIcon",
                className: "access-column data-column",
                Cell: ({ row }) => (
                    <>
                        <ReactTooltip id='row-access-tip' className='custom-color-no-arrow' delayHide={200}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
                        <div className='access-origin-separate'>
                            <p className='cell--title'>Access:</p>
                            <div data-for='row-access-tip' data-tip={(row.values.hasAccess === true ? 'You have access to view this data product.' : 'You are currently restricted from this data product and must request access to view it.')}>
                                {(row.values.hasAccess === true || row.values.type==="Data Science Product") ?
                                    <HasAccessFlat />
                                    : <HasNoAccessFlat />
                                }
                                <p>{(row.values.hasAccess === true || row.values.type==="Data Science Product") ? 'Authorized' : 'Restricted'}</p>
                            </div>
                        </div>
                    </>
                )
            },
            {
                Header: "Data Origin",
                accessor: "reportTypeIcon",
                className: "origin-column data-column",
                Cell: ({ row }) => (
                    <div className='access-origin-separate'>
                        <p className='cell--title'>Data Origin:</p>
                        <ReportType reportType={row.values.reportType} includeColorClasses={true} />
                    </div>
                )
            },
            {
                Header: "Type",
                accessor: "assetTypeIcon",
                className: "type-column data-column",
                Cell: ({ row }) => (
                    <>
                        <ReactTooltip id='row-domain-tip' className='custom-color-no-arrow' delayHide={1000}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                        <p className='cell--title'>Type:</p>
                        <p className='domainData' data-for='row-domain-tip' data-tip={row.values.domain.join(', ')}>
                            {row.values.type}
                        </p>
                    </>
                )
            },
            {
                Header: "Primary Owner",
                accessor: "primaryOwner",
                className: "owner-column data-column",
                Cell: ({ row }) => (
                    <>
                        <p className='cell--title'>Owner:</p>
                        <ReactTooltip id='row-owner-tip' className='custom-color-no-arrow' delayHide={1000}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                        {row.values.primaryOwner.map((owner, id, owners) => {
                            return (
                                <p key={id} className='overflow' id={`owner_${id}`} data-for='row-owner-tip' data-tip={(owners.length > 0 ? `Displaying 1 of ${owners.length} Primary Owners` : null)}
                                >{owner.name}{(id === 0 && owners.length > 1 ? ' ...' : '')}</p>
                            );
                        })}
                    </>
                ),
                Filter: OwnershipFilter,
                filterLabel: "Ownership",
                filter: ApplyFilter
            },

            {
                Header: "Domain",
                accessor: "domainIcon",
                className: "domain-column data-column",
                Cell: ({ row }) => (
                    <>
                        <ReactTooltip id='row-domain-tip' className='custom-color-no-arrow' delayHide={1000}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                        <p className='cell--title'>Domain:</p>
                        <p className='domainData' data-for='row-domain-tip' data-tip={row.values.domain.join(', ')}>
                            {row.values.domain.join(', ')}
                        </p>
                    </>
                )
            },
            {
                Header: "Segment",
                accessor: "businessSegmentIcon",
                className: "segment-column data-column",
                Cell: ({ row }) => (
                    <>
                        <ReactTooltip id='row-segment-tip' className='custom-color-no-arrow' delayHide={1000}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                        <p className='cell--title'>Segment:</p>
                        <p className='segmentData' data-for='row-segment-tip' data-tip={row.values.businessSegment.join(', ')}>
                            {row.values.businessSegment.join(', ')}
                        </p>
                    </>
                )
            },
            {
                accessor: "accessOrigin",
                className: "access-origin-column data-column",
                Cell: ({ row }) => (
                    <span className='show-access-origin'>
                        <ProductAccessOrigin id='search-page-access-origin' access={row.values.hasAccess} reportType={row.values.reportType} />
                    </span>
                )
            },
            {
                id: "Edit",
                Header: "Actions",
                accessor: "actions",
                className: "actions-column",
                Cell: ({ row }) => (
                    <>
                        {row.values.hasAccess &&
                            <ShareIcon
                                data-for='action-tip'
                                data-tip={row.values.type === 'Data Science Product' ? 'Launch Presentation' : 'Launch Product'}
                                className={`typeIcon share-icon product-img${row.original.type === 'Data Science Product' && row.original.presentation === '' ? '--disabled' : ''}`}
                                onClick={(e) => handleShowProduct(e, row.original)}
                            />
                        }
                        <div className='search-favorite'>
                            <Favorite favoriteItem={row.original} />
                        </div>
                        <div className='actions-no-launch'>
                            <ActionsDropdown
                                options={buildActionOptions(row.values.hasAccess, row.values.type)}
                                type={row.values.type}
                                hasAccess={row.values.hasAccess}
                                onChange={(actionType) => handleAction(actionType, row.original)}
                            />
                        </div>
                        <div className='actions-launch'>
                            <ActionsDropdown
                                options={
                                    row.values.hasAccess
                                        ? [...buildActionOptions(row.values.hasAccess, row.values.type), row.values.type === 'Data Science Product'
                                            ? 'Launch Presentation' : 'Launch Product']
                                        : buildActionOptions(row.values.hasAccess, row.values.type)
                                }
                                type={row.values.type}
                                hasAccess={row.values.hasAccess}
                                onChange={(actionType) => handleAction(actionType, row.original)}
                                disabledOptions={row.original.type === 'Data Science Product' && row.original.presentation === '' ? ['Launch Presentation'] : []}
                            />
                        </div>
                    </>

                ),
            }
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    const data = useMemo(() => [...results], [results]);

    function errorResult() {
        return (
            <div data-testid='search-page-error' onClick={() => LoadData()} className='loading-error'>
                <span>{loadError}<br />
                    Click here to retry</span>
            </div>
        );
    }

    function loadingResult() {
        return (
            <div className="search-spinner">
                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                <span>Searching....</span>
            </div>
        );
    }

    function successfulResult() {
        return (
            <>
                <DetailDrawer
                    id='search-page-details'
                    itemId={modalId}
                    modalActive={showModal}
                    setModalState={(state) => setShowModal(state)}
                    data-testid={props.id}
                />
                <StatefulDatatable
                    pageName='search'
                    id='search-page-datatable'
                    columns={columns}
                    data={data}
                    hiddenColumns={['name', 'domain', 'type', 'legacyBrands', 'id', 'reportType', 'hasAccess', 'businessSegment']}
                    sortOptions={['Default', 'Alphabetical A-Z', 'Alphabetical Z-A']}
                    onChangeSort={handleSortChange}
                    defaultPageSize={10}
                    defaultSortBy={[]}
                    onRowClick={(row) => {
                        handleRowClick(row.original);
                        sendEvent('analyticsClickAction', 'search_openReport', null, null, row.original);
                    }}
                    setResultsCount={setResultsCount}
                    searchTerm={term}
                    rowClass={getTypeColor}
                    displayCheck={false} />
                <FormContainer
                    id={'favorite-page-form-modal'}
                    isOpen={showRequestAccessModal}
                    handleClose={() => setShowRequestAccessModal(false)}
                    header={'Request Access'}
                    typeForm="DAA"
                    handleClickConfirmation={handleClickRequestAccessConfirmation}
                    handleError={handleModalErrorRequestAccess}
                    asset={{
                        id: clickedItem?.id,
                        name: clickedItem?.name,
                        type: clickedItem?.type,
                        primaryOwners: clickedItem?.primaryOwner,
                        secondaryOwners: clickedItem?.secondaryOwner
                    }}
                />
                <ConfirmationModal
                    isOpen={showRequestAccessConfirmModal}
                    id={'favorite-page-confirmation-modal'}
                    handleClose={() => setShowRequestAccessConfirmModal(false)}
                    header='Thank you for submitting your request for access.'
                    message='You can expect confirmation email from Data Governance in 1 to 2 days​ and Data Governance will notify you of approval.'
                />
                <ErrorModal
                    isOpen={showModalError}
                    id={'favorite-page-error-modal'}
                    handleClose={() => setShowModalError(false)}
                    message={'Your request has failed. Please submit your request again.'}
                ></ErrorModal>
            </>
        );
    }

    return (
        <section data-testid='search-page' className='w-full search-page'>
            <div className='link-results'>
                <BackButton id='search-page' />
                {!isLoading && !isFavoritesLoading && loadError?.length === 0 ? <p>We found {resultsCount} results</p> : null}
            </div>
            {
                loadError && loadError.length > 0 ? errorResult() :
                    ((isLoading || isFavoritesLoading) ? loadingResult() :
                        successfulResult())
            }
        </section>
    );
}

SearchPage.propTypes = {
    id: PropTypes.string
};