/*jshint esversion: 6 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {CaretUp, CaretDown} from 'phosphor-react';
import './Tabs.scss';

export const Tab = ({ id, label, active, caret,  onClick }) => {
    return (
        <>
            <button
                id={id}
                data-testid={id}
                role="tab"
                // eslint-disable-next-line react/no-unknown-property
                active={active ? 1 : 0}
                className={active ? 'active' : null}
                onClick={onClick}>
                {label}
                { caret && <span className='default-caret' data-testid={`${id}-caret`}>
                {active ?
                    <CaretUp size={16} weight="bold" data-testid={`${id}-caret-up`} />
                    : <CaretDown weight="bold" size={16} data-testid={`${id}-caret-down`} />}
                </span>
                }
            </button>
        </>
    );
};

export const Tabs = ({ id, selectedTab, rowClicked, onChange, className, children }) => {
    const tabs = React.Children.map(children, child => {
        if (child) {
            const handleClick = (e) => {
                onChange(e, child.props.value, child.props.rowId);  
            };
            const checkRowClicked = (rowClicked) => {
                if(rowClicked===undefined || rowClicked === child.props.rowId){
                    return true;
                }
                return false;
            }
            return React.cloneElement(child, {
                active: child.props.value === selectedTab && checkRowClicked(rowClicked),
                onClick: handleClick
            })
        }
    });

    return (
        <div id={id} data-testid={id} className={className ? `${className}-tab--header-container` : 'tab--header-container'}>
            <div className='tab--holder'>
                {tabs}
            </div>
        </div>
    );
};

export const TabPanel = ({ id, children, value, selectedIndex }) => {
    const [animate, setAnimate] = useState(true);
    const hidden = value !== selectedIndex;

    useEffect(() => { if (value === selectedIndex) setAnimate(true); }, [value, selectedIndex, hidden]);
    return (
        <>
            <div id={id} data-testid={id} className={!hidden && animate ? 'tab--panel animate__animated animate__fadeIn' : 'tab--panel'}
                onAnimationEnd={() => setAnimate(false)}
                // eslint-disable-next-line react/no-unknown-property
                hidden={hidden} active={(hidden ? 0 : 1)}>
                {children}
            </div>
        </>
    );
};

Tab.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
    caret: PropTypes.bool,
    onClick: PropTypes.func,
};

Tabs.propTypes = {
    id: PropTypes.string,
    selectedTab: PropTypes.any,
    rowClicked: PropTypes.any,
    onChange: PropTypes.any,
    className: PropTypes.string,
    children: PropTypes.any
};

TabPanel.propTypes = {
    id: PropTypes.string,
    children: PropTypes.any,
    value: PropTypes.any,
    selectedIndex: PropTypes.any
};