import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import './ConfirmUnfavoriteModal.scss';
import Button from '../Button/Button';

const ConfirmUnfavoriteModal = ({ id, isOpen, handleClose, handleRemove, header, message }) => {
    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleClose} size="sm">
                    <div className='confirm-modal'>
                        <h2>{header}</h2>
                        <p>{message}</p>
                        <div className='confirm-buttons'>
                            <Button id={`${id}-cancel-btn`} className='btn--rounded btn--blue-white btn--close confirm-button'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button id={`${id}-remove-btn`} className='btn--rounded btn--blue-light btn--close confirm-button'
                                onClick={handleRemove}
                            >
                                Yes, remove it
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

ConfirmUnfavoriteModal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    header: PropTypes.string,
    message: PropTypes.string
}

export default ConfirmUnfavoriteModal;