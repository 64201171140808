import React from 'react';
import PropTypes from 'prop-types';
import ContentEditableInput from '../ContentEditableInput/ContentEditableInput';
import { ArrowLeft, ArrowRight } from "phosphor-react";

export default function Pagination(props) {
    function getPageSizeOptions(totalCount) {
        const options = [10, 20, 50].filter((o) => o <= totalCount);
        if (options.length < 3) options.push(totalCount);
        return options;
    }

    const handleNextPage = () => {
        props.nextPage();
        window.scrollTo(0,0);
    }

    return (
        <div id={props.id} data-testid={props.id} className={props.paginationClass === 'glossary' ? 'pagination pagination--glossary': 'pagination'}>
            <div className='pagination--left'>
                <button id={`${props.id}-prev-btn`} data-testid={`${props.id}-prev-btn`} onClick={props.previousPage} disabled={!props.canPreviousPage}>
                    <div className='circle circle--blue'> <ArrowLeft size={20} /></div>
                </button>
                <span className='pagination--numbers'>
                    <span className='pagination--numbers-first'>
                        <ContentEditableInput
                            id={`${props.id}-content-edit`}
                            // type="number"
                            value={props.pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                props.gotoPage(page);
                            }}
                        />
                    </span>
                    of &nbsp;{props.pageCount}
                </span>
                <button id={`${props.id}-next-btn`} data-testid={`${props.id}-next-btn`} onClick={() => {handleNextPage()}} disabled={!props.canNextPage}>
                    <div className='circle circle--blue' > <ArrowRight size={20} /></div>
                </button>
            </div>
            <div className='pagination--right'>
                <select
                    data-testid={`${props.id}-select`}
                    value={props.pageSize}
                    className='dropdown-page'
                    onChange={e => {
                        props.setPageSize(Number(e.target.value));
                    }}
                >
                    {getPageSizeOptions(props.totalCount).map((pageSize, index) => (
                        <option data-testid={`${props.id}-select-${index}`} key={`${pageSize}-${index}`} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <span>of {props.totalCount} results</span>
            </div>
        </div>
    );
}

Pagination.propTypes = {
    previousPage: PropTypes.func,
    canPreviousPage: PropTypes.bool,
    nextPage: PropTypes.func,
    canNextPage: PropTypes.bool,
    gotoPage: PropTypes.func,
    setPageSize: PropTypes.func,
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    totalCount: PropTypes.number,
    paginationClass: PropTypes.string,
    id: PropTypes.string
};