import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react';
import { FunnelSimple } from "phosphor-react";

import Button from '../Button/Button';
import DefinitionGlobalFilter from './DefinitionGlobalFilter';

export default function ViewAllFilters(props) {
    const [isActive, setActive] = useState(false);
    const toggleDropdown = () => {
        setActive(!isActive);
    };

    const buildAllOptions = (props) => {
        return props.allColumns?.map((column, i) => {
            return (
                <div key={i} {...getFilterTooltip(column)}>
                    {column.canFilter && column.Filter ? column.render("Filter") : null}
                </div>
            );
        })
    }

    const wrapperRef = useRef(null);
    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setActive(false);
            }
        }
        if (isActive) {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [wrapperRef, isActive]);
    return (
        <div ref={wrapperRef} id={props.id} data-testid={props.id} className='dropdown--filter'>
            <div className={isActive ? 'overlay' : ''} data-testid={`${props.id}-overlay`} onClick={toggleDropdown}></div>
            <div data-testid={`${props.id}-view-all-filters`} className='dropdown-btn filter-all' onClick={toggleDropdown}><span className='dropdown-btn__arrow'><FunnelSimple size={15} /></span> View All Filters</div>
            <div className={isActive ? 'sort-dropdown-content dropdown-show' : 'dropdown-content'}>
                {isActive && (
                    <>
                        {props.showSearch &&
                            <div className='viewAllFilter-search'>
                                <DefinitionGlobalFilter
                                    preGlobalFilteredRows={props.preGlobalFilteredRows}
                                    globalFilter={props.globalFilter}
                                    setGlobalFilter={props.setGlobalFilter}
                                    onSearch={toggleDropdown} />
                            </div>
                        }
                        <div className='header-filters'>
                            <p>Filters</p>
                            <div className='dropdown--filter'>
                                <Button id={`${props.id}-clear-filters-btn`} className='btn--no-outline red' onClick={props.setAllFilters}>Clear</Button>
                                <Button id={`${props.id}-done-filters-btn`} className='btn--no-outline' onClick={() => { setActive(false)}}>Done</Button>
                            </div>
                        </div>
                        <div className='filters--container'>
                            <div>
                                {buildAllOptions(props)}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div >
    )
}

export function getFilterTooltip(column) {
    if (column.tooltip === undefined || column.tooltip === null || column.tooltip.length === 0)
        return {};

    return {
        'data-for': 'filter-tooltip',
        'data-tip': column.tooltip
    }
}

ViewAllFilters.propTypes = {
    id: PropTypes.string,
    setAllFilters: PropTypes.func,
    allColumns: PropTypes.array.isRequired,
    onToggle: PropTypes.any,
    showSearch: PropTypes.bool,
    preGlobalFilteredRows: PropTypes.any,
    globalFilter: PropTypes.any,
    setGlobalFilter: PropTypes.any
}