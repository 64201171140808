/*jshint esversion: 6 */
/*jshint esversion: 11 */
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from "react-tooltip";

import warningIcon from '../../assets/icons/warning.svg';
import Button from '../Button/Button';
import ProductDescription from '../ProductElements/ProductDescription';

const DataTraceability = ({ id, data, openProductDetailHandler }) => {
    const chipInfo = (type) => {
        let chipInfo = {};
        switch (type) {
            case 'Power BI Report':
                chipInfo = {
                    chipText: 'DASHBOARD',
                    chipColor: 'orange'
                }
                break;
            case 'Dashboard':
                chipInfo = {
                    chipText: 'DASHBOARD',
                    chipColor: 'orange'
                }
                break;
            case 'Power BI Report Page':
                chipInfo = {
                    chipText: 'REPORT PAGE',
                    chipColor: 'orange'
                }
                break;
            case 'Power BI Table':
                chipInfo = {
                    chipText: 'BI TABLE',
                    chipColor: 'blue'
                }
                break;
            case 'Power BI Data Set':
                chipInfo = {
                    chipText: 'DATASET',
                    chipColor: 'blue'
                }
                break;
            case 'Table':
                chipInfo = {
                    chipText: 'TABLE',
                    chipColor: 'blue'
                }
                break;
            case 'Column':
                chipInfo = {
                    chipText: 'COLUMN',
                    chipColor: 'blue'
                }
                break;
            case 'Schema':
                chipInfo = {
                    chipText: 'SCHEMA',
                    chipColor: 'blue'
                }
                break;
            case 'Database':
                chipInfo = {
                    chipText: 'DATABASE',
                    chipColor: 'blue'
                }
                break;
            case 'Database View':
                chipInfo = {
                    chipText: 'DATABASE VIEW',
                    chipColor: 'blue'
                }
                break;
            case 'System':
                chipInfo = {
                    chipText: 'SYSTEM',
                    chipColor: 'blue'
                }
                break;
            case 'Business Term':
                chipInfo = {
                    chipText: 'BUSINESS TERM',
                    hipColor: 'orange'
                }
                break;
            case 'Acronym':
                chipInfo = {
                    chipText: 'ACRONYM',
                    chipColor: 'orange'
                }
                break;
            case 'KPI':
                chipInfo = {
                    chipText: 'KPI',
                    chipColor: 'orange'
                }
                break;
            case 'File':
                chipInfo = {
                    chipText: 'FILE',
                    chipColor: 'green'
                }
                break;
            case 'Data Process':
                chipInfo = {
                    chipText: 'DATA SCIENCE',
                    chipColor: 'purple'
                }
                break;
            case 'Data Science Product':
                chipInfo = {
                    chipText: 'DATA SCIENCE',
                    chipColor: 'purple'
                }
                break;
            default:
                chipInfo = {
                    chipText: 'TYPE',
                    chipColor: 'blue'
                }
        }
        return chipInfo;
    };

    const getTraceabilityData = (dataType) => {
        let traceabilityData = [];

        if (data.type ==="Data Science Product") {
            let dashboards = data.relationships.dashboards;
            let outputs = data.relationships.outputs;
            dashboards.forEach(d => d.uiType = 'dashboard');
            outputs.forEach(d => d.uiType = 'output');

            traceabilityData = dashboards.concat(outputs).concat(data.relationships.files);
        } else if (dataType === 'sources' && data && data.relationships) {
            traceabilityData = data.relationships.sources;
        } else if (dataType === 'targets' && data && data.relationships) {
            traceabilityData = data.relationships.targets.concat(data?.relationships?.dataProcesses);
        }else if(dataType==='tables' && data && ["Data Set","Data Entity","Schema"].includes(data.type) && data.relationships.table){
            traceabilityData = data.relationships.table;      
        }
        if (traceabilityData?.length > 0) {
            return traceabilityData.map((source, idx) => {
                if (source) {
                    return (
                        <div className='card' key={idx}>                            
                            <div className='card-header'>
                                <div className='card-text'>                  
                                    {source?.type === 'Table' || source?.type === 'Database View' || source?.type === 'Power BI Report' || source?.type === 'Dashboard' || source?.type === 'Power BI Dashboard'? 
                                    <p key={source?.id}>
                                    {(source?.status === 'Accepted' || ((source?.type==='Table' || source?.type==='Database View') && source?.status === 'Reviewed')) ? 
                                        <Button className='btn--plain-underline' id={`${id}-open-product`} onClick={() => openProductDetailHandler(source)}><span>{source?.name}</span></Button>
                                        : <span>{source?.name}</span>
                                    }
                                    </p>
                                    : 
                                    <p key={source?.id} >
                                        {(source?.type === 'Data Science Product' || source?.type === 'Data Process')  && source?.status === 'Accepted' ?
                                            <Button className='btn--plain-underline' id={`${id}-open-product`} onClick={() => openProductDetailHandler(source)}><span>{source?.name}</span></Button>
                                            : <span>{source?.name}</span>
                                        }
                                    </p>
                                    }
                                    {!(source?.status === 'Accepted' || ((source?.type==='Table' || source?.type==='Database View') && source?.status === 'Reviewed')) ?
                                        <img
                                            src={warningIcon}
                                            alt='data traceability warning'
                                            data-for='trace-warning-tip'
                                            data-tip="Asset is still in the process of being verified by Data Governance."
                                        />
                                        :
                                        null
                                    }
                                </div>                               
                                <span className={`chip chip--${chipInfo(source.type).chipColor}`}>{chipInfo(source.type).chipText}</span>                           
                            </div>
                            {source?.description && <div className='card-description'>
                            {source?.type === 'Table' ?
                            <ProductDescription description={source?.description} />
                            :
                             source?.type === 'Power BI Report Page'? 
                                <p key={source?.id} >
                                         <span>{source?.description}</span>
                                        
                                    </p>
                                    :<></>}
                            </div>}
                            {(source.uiType === 'dashboard' || source.uiType === 'output') && source.status === 'Accepted' && 
                                <p className='card-subtitle'>{source.type === 'Table' ? 'Select to view the dashboard input table.' : source.type === 'Database View' ? 'Select to view the dashboard input database.' : 'Select to view the dashboard detail page.'}</p>
                            }
                        </div>
                    );
                } else {
                    return null;
                }
            })
        } else {
            return null;
        }
    }

    return (
        <article id={id} data-testid={id} className='data-traceability'>
            <ReactTooltip id='chip-name-tip' className='custom-color-no-arrow' delayHide={1000}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' />
            <ReactTooltip id='trace-description-tip' className='custom-color-no-arrow data-traceability--tooltip' delayHide={1000}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' html={true} />
            <ReactTooltip id='trace-warning-tip' className='custom-color-no-arrow data-traceability--tooltip' delayHide={1000}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' />
            {
                !["Data Set","Data Entity","Schema"].includes(data.type) ? 
                <>
                <div className='data-traceability--source'>
                    <p className='subtitle'>Data Sources</p>
                    {getTraceabilityData('sources')}
                </div>
                <div className='data-traceability--targets'>
                    <p className='subtitle'>{`Targets`}</p>
                        {getTraceabilityData('targets')}
                </div>
                </>
                :
                <div className='data-traceability--tables'>
                    {getTraceabilityData('tables')}
                </div>
            }
        </article>
    );
};

DataTraceability.propTypes = {
    data: PropTypes.any,
    id: PropTypes.string,
    openProductDetailHandler: PropTypes.func
};

export default DataTraceability;