import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import FormTextArea from './FormTextArea';
import FormInput from './FormInput';

import './FormComponent.scss';
import './Form.scss';
import 'react-datepicker/dist/react-datepicker.css';


const FormRequestAccess = ({ id, onFieldChange, passClearStateFunction, passValidateFormFunction, assetName }) => {
    const productNameLabel = 'Data Product Name'
    const requestDateLabel = 'Date of Request'
    const summaryLabel = 'Summarize your data access needs and how it will be used.'
    const requestedForLabel = 'List the names of people who need access.'

    const _initialState = {
        productName: { prompt: productNameLabel, value: assetName },
        requestDate: { prompt: requestDateLabel, value: new Date() },
        summary: { prompt: summaryLabel, value: '' },
        requestedFor: { prompt: requestedForLabel, value: '' }
    }
    const [summarizeError, setSummarizeError] = useState(false);
    const [fieldLocalState, setFieldLocalState] = useState(_initialState);

    const clearState = () => {
        setSummarizeError(false);
        setFieldLocalState(_initialState);
    }
    passClearStateFunction(clearState)

    const validateForm = () => {
        let valid = true;
        if (fieldLocalState.summary.value === '') {
            setSummarizeError(true);
            valid = false;
        }
        return valid;
    }
    passValidateFormFunction(validateForm);

    const handleFieldChange = (key, value) => {
        if (key === 'summary') {
            value === '' ? setSummarizeError(true) : setSummarizeError(false);
        }

        fieldLocalState[key].value = value;
        setFieldLocalState(fieldLocalState);
        onFieldChange(fieldLocalState);
    }

    return (
        <>
            <FormInput
                id={`${id}-${assetName}`}
                type="text"
                name="productName"
                label={productNameLabel}
                value={fieldLocalState.productName.value}
                disabled={true} />
            <label htmlFor=''>{requestDateLabel}</label>
            <DatePicker
                disabled={true}
                selected={fieldLocalState.requestDate.value}
                onChange={(date) => handleFieldChange('requestDate', date)}
                value={fieldLocalState.requestDate.value}
                placeholderText='MM/DD/YYYY' />
            <FormTextArea
                id={`${id}-summarize`}
                type="textarea"
                errorMessage='This Field is required'
                required={true}
                rows="4"
                cols="50"
                name="summary"
                label={summaryLabel}
                placeholder="Describe your data needs in business terms. Please list any tables, reports, and/or data science products that will require access."
                value={fieldLocalState.summary.value}
                handleChange={(e) => handleFieldChange('summary', e.target.value)}
                showError={summarizeError}
            />
            <FormInput
                id={`${id}-usingData`}
                type="text"
                name="requestedFor"
                label="Who will need access?"
                placeholder={requestedForLabel}
                value={fieldLocalState.requestedFor.value}
                handleChange={(e) => handleFieldChange('requestedFor', e.target.value)}
            />
            <div className='form--header--secondary'>
                Risk, Restrictions and Conditions of Use
            </div>
            <label className='label-long question mb-6'>
                Data Privacy and Data Security will be engaged if there are PII and/or PCI data identified
            </label>
        </>
    )
};

FormRequestAccess.propTypes = {
    id: PropTypes.string,
    onFieldChange: PropTypes.func,
    passClearStateFunction: PropTypes.func,
    passValidateFormFunction: PropTypes.func,
    assetName: PropTypes.string
};

export default FormRequestAccess;