import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FavoritesGroupedCard from './FavoritesGroupedCard/FavoritesGroupedCard';

import business from '../../assets/icons/home/business-white.svg';
import corporate from '../../assets/icons/home/corporate-white.svg';
import GenAI from '../../assets/icons/home/genAI-white.svg';
import home from '../../assets/icons/home/home-white.svg';
import marketOps from '../../assets/icons/home/marketOps-white.svg';
import nrgEnterprise from '../../assets/icons/home/nrgEnterprise-white.svg';
import plantOps from '../../assets/icons/home/plantOps-white.svg';
import services from '../../assets/icons/home/services-white.svg';
import vivint from '../../assets/icons/home/vivint-white.svg';

import './FavoritesGroupedView.scss';

const FavoritesGroupedView = ({ id, favoritesResults, handleItemClick, handleLaunchProduct, handleRequestAccess }) => {
    const [groupedResults, setGroupedResults] = useState(null)
    const domains = ['Gen AI','Plant Operations', 'Market Operations', 'Customer Operations - Services', 'Customer Operations - Business', 'Customer Operations - Home', 'Corporate', 'Customer Operations - Smart Home'];
    const domainPriority = { "Gen AI":0,"Plant Operations": 1, "Market Operations": 2, "Customer Operations": 3, "Services": 4, "Business": 5, "Home": 6, "Corporate": 7 };

    const formatGroupDomain = (favoriteResults) => {
        let tempFavoriteResults = favoriteResults;
        tempFavoriteResults.forEach((item) => {
            item['groupDomain'] = item.domain.length>0 ? item.domain?.reduce(function (c, n) {
                return domainPriority[c] > domainPriority[n] ? c : n;
            }) : 'Gen AI';

            if (item.groupDomain === 'Customer Operations') {
                let temp = item.businessSegment.reduce(function (c, n) {
                    return domainPriority[c] > domainPriority[n] ? c : n;
                });
                item['groupDomain'] = item['groupDomain'] + ' - ' + temp;
            }
        });

        let results = favoritesResults.reduce(function (r, a) {
            r[a.groupDomain] = r[a.groupDomain] || [];
            r[a.groupDomain].push(a);
            return r;
        }, Object.create(null));

        domains.forEach((domain) => {
            if (!results[domain]) results[domain] = [];
        });

        const orderedResults = Object.keys(results).sort().reduce(
            (obj, key) => {
                obj[key] = results[key];
                return obj;
            },
            {}
        );

        setGroupedResults(orderedResults);
    }

    const getIcon = (title) => {
        switch (title) {
            case 'Plant Operations':
                return plantOps;
            case 'Market Operations':
                return marketOps;
            case 'Customer Operations - Services':
                return services;
            case 'Customer Operations - Business':
                return business;
            case 'Customer Operations - Home':
                return home;
            case 'Corporate':
                return corporate;
            case 'NRG Enterprise':
                return nrgEnterprise;
            case 'Customer Operations - Smart Home':
                return vivint;
            case 'Gen AI':
                return GenAI;
            default:
                return null;
        }
    }

    useEffect(() => {
        formatGroupDomain(favoritesResults);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favoritesResults]);

    return (
        <div className='grouped-container' data-testid={id}>
            {
                groupedResults && Object.entries(groupedResults).map(([k, v]) => {
                    return (
                        <FavoritesGroupedCard
                            key={k}
                            id={`${id}-${k}`}
                            icon={getIcon(k)}
                            title={k}
                            items={v}
                            handleItemClick={handleItemClick}
                            handleLaunchProduct={handleLaunchProduct}
                            handleRequestAccess={handleRequestAccess}
                        ></FavoritesGroupedCard>
                    )
                })
            }
        </div>
    )
}

FavoritesGroupedView.propTypes = {
    id: PropTypes.string,
    favoritesResults: PropTypes.array,
    handleItemClick: PropTypes.func,
    handleLaunchProduct: PropTypes.func,
    handleRequestAccess: PropTypes.func
}

export default FavoritesGroupedView;