import { Actions } from "./action";
import { initialState } from "./context";

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_PROFILE:
            return {
                ...state,
                isLoaded: true,
                persona: action.payload.persona,
                isAdmin: action.payload.isAdmin,
                favorites: action.payload.favorites,
                createdOn: action.payload.createdOn
            };
        case Actions.UPDATE_FAVORITES:
            return {
                ...state,
                favorites: action.payload
            }
        case Actions.UPDATE_MEDIA:
            return {
                ...state,
                media: action.payload
            }
        case Actions.UPDATE_PERSONA:
            return {
                ...state,
                persona: action.payload
            }
        case Actions.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            }
        case Actions.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: action.payload
            }
        case Actions.ADD_DASHBOARDS:
            return {
                ...state,
                dashboardPermissions: action.payload
            }
        case Actions.ADD_WHATS_NEW:
            return {
                ...state,
                whatsNew: action.payload
            }
        default:
            return state;
    }
}