/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import { ReactComponent as BoldIcon } from '../../assets/icons/editor-bold.svg';
import { ReactComponent as ItalicsIcon } from '../../assets/icons/editor-italics.svg';
import { ReactComponent as UnderlineIcon } from '../../assets/icons/editor-underline.svg';

import './TextEditor.scss';

const TextEditor = ({ saveHtmlFormat, currentHtmlContent }) => {
    const [editorState, setEditorState] = useState(currentHtmlContent ? EditorState.createWithContent(stateFromHTML(currentHtmlContent))
        :
        () => EditorState.createEmpty(),
    );
    const [contentLength, setContentLength] = useState(0);
    const maxLength = 100;

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return true;
        }
        return false;
    }

    const mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== editorState) {
                setEditorState(newEditorState);
            }
            return;
        } else if (e.keyCode === 13) {
            return false
        }
        return getDefaultKeyBinding(e);
    }

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    }

    const getLengthOfSelectedText = () => {
        const selection = editorState.getSelection();
        const isCollapsed = selection.isCollapsed();
    
        let length = 0;
    
        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const endKey = selection.getEndKey();

            const startBlock = currentContent.getBlockForKey(startKey);
            const startBlockTextLength = startBlock.getLength();

            const startSelectedTextLength = startBlockTextLength - selection.getStartOffset();
            const endSelectedTextLength = selection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            
            if (startKey === endKey) {
                length += selection.getEndOffset() - selection.getStartOffset();
            } else {
                let currentKey = startKey;
        
                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }
            
                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }
    
        return length;
    }
    
    const handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();
    
        if (currentContentLength - selectedTextLength > maxLength - 1) {
            // total can't be more than the maxLength
            return 'handled';
        }
    }
    
    const handlePastedText = (pastedText) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();
    
        if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
            // total can't be more than the maxLength
            return 'handled';
        }
    }


    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const currentContentLength = contentState.getPlainText('').length;
        setContentLength(currentContentLength);
        saveHtmlFormat(stateToHTML(contentState));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorState])

    return (
        <div className='text-editor'>
            <div className='text-editor-main'>
                <Editor
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={setEditorState}
                    spellCheck={true}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                />
            </div>
            <div className='toolbar'>
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
                <p>{contentLength}/{maxLength}</p>
            </div>
        </div>
    );
}

const StyleButton = (props) => {
    let className = useState('text-editor-styleButton');
    const onToggle = (e) => {
        e.preventDefault();
        props.onToggle(props.style);
    };

    if (props.active) {
        className += ' text-editor-activeButton';
    }

    return (
        <span className={className} onMouseDown={onToggle}>
            {props.icon}
        </span>
    );
}

var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD', icon: <BoldIcon /> },
    {label: 'Italic', style: 'ITALIC', icon: <ItalicsIcon /> },
    {label: 'Underline', style: 'UNDERLINE', icon: <UnderlineIcon /> }
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="text-editor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                    icon={type.icon}
                />
            )}
        </div>
);
};

TextEditor.propTypes = {
    id: PropTypes.string,
    saveHtmlFormat: PropTypes.func,
    currentHtmlContent: PropTypes.any
}

export default TextEditor;