import React from 'react';
import PropTypes from 'prop-types';

import StatefulDatatable from '../datatable/StatefulDatatable';
import { sendEvent, trackSort } from '../../helpers/analyticsHandler';

const FavoritesTableView = ({ id, columns, results, setResultsCount, handleRowClick }) => {
    function handleSortChange(option, setSortBy, sortBy) {
        switch (option) {
            case 'Alphabetical A-Z':
                setSortBy([{ id: "name", desc: false }]);
                break;
            case 'Alphabetical Z-A':
                setSortBy([{ id: "name", desc: true }]);
                break;
            case 'Date Favorited':
                setSortBy([{ id: "favoritedAt", desc: false }]);
                break;
            default:
                setSortBy([{ id: "lastAccessed", desc: true }]);
        }

        trackSort(option);
        sendEvent('analyticsClickAction', 'favorite_sort', null, option, null);
    }

    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    return (
        <StatefulDatatable
            pageName='favorites'
            id={id}
            columns={columns}
            data={results}
            hiddenColumns={['favoritedAt', 'lastAccessed']}
            sortOptions={['Last Used', 'Date Favorited', 'Alphabetical A-Z', 'Alphabetical Z-A']}
            defaultSortOption='Last Used'
            defaultSortBy={[{ id: "lastAccessed", desc: true }]}
            onChangeSort={handleSortChange}
            defaultPageSize={10}
            onRowClick={handleRowClick}
            setResultsCount={setResultsCount}
            rowClass={getTypeColor}
            displayCheck={false}
        />
    )
}

FavoritesTableView.propTypes = {
    id: PropTypes.string,
    results: PropTypes.array,
    columns: PropTypes.any,
    setResultsCount: PropTypes.func,
    handleRowClick: PropTypes.func
}

export default FavoritesTableView;