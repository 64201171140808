import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Favorite } from '../../assets/icons/favorite.svg';
import './FavoriteCard.scss'

const FavoriteCard = ({ title, children, id, type }) => {
    return (
        <>
            <div className={type ? `favorite-card` : 'favorite-card'} data-testid={id}>
                <div className='favorite-card--header-box'>
                    <Favorite />
                    <p className='favorite-card--title'> {title}</p>
                </div>
                <div className='favorite-card--children'>
                    {children}
                </div>
            </div>
        </>
    )
}

FavoriteCard.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    id: PropTypes.string,
    type: PropTypes.string
}

export default FavoriteCard;