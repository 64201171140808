import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-loader-spinner';

import { GetUpdates, AddPersonaFeatures, RemovePersonaFeature } from '../../../services/dmpApi';
import { Context } from '../../../contexts/context';
import { Actions } from '../../../contexts/action';

import { Tabs, Tab,TabPanel } from '../../Tabs/Tabs';
import RecommendedItemManage from '../../RecommendedItemManage/RecommendedItemManage';
import SortableComponent from '../../SortableComponent/SortableComponent';
import DataUnavailable from '../../DataUnavailable/DataUnavailable';
import BrowseCard from '../../browse/BrowseCard';
import Button from '../../Button/Button';
import FeatureModal from '../../Modal/FeatureModal';

import bellSlash from '../../../assets/icons/bell-slash.svg';
import draftIcon from '../../../assets/icons/drafts.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as UploadBlue } from '../../../assets/icons/upload-blue.svg';

import './ManageUpdates.scss';

const ManageUpdates = ({ id }) => {

    const {
        state: {
            persona
        },
        dispatch
    } = useContext(Context);

    const [checkedDrafts, setCheckedDrafts] = useState({});
    const [numSelectedDrafts, setNumSelectedDrafts] = useState(0);
    const [publishedItems, setPublishedItems] = useState({});
    const [draftItems, setDraftItems] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [enablePublish, setEnablePublish] = useState(false);
    const [isPublished, setIsPublished] = useState(true);
    const [currentUpdate, setCurrentUpdate] = useState(null);
    const [showFeatureModal, setShowFeatureModal] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);
    const [isWhatsNewLoaded, setIsWhatsNewLoaded] = useState(false);

    useEffect(() => {
        const LoadWhatsNew = async () => {
            const response = await GetUpdates()
                .catch((err) => console.log(err));

            if (response) {
                const data = response.data.results;
                handleWhatsNew(data);
                setIsWhatsNewLoaded(true);

                if (data) {
                    setCurrentUpdate(
                        convertUTCDateToLocalDate(new Date(Math.max.apply(null, data.map(function(e) {
                            return new Date(e.type === 'Feature' ? e.modifiedOn : e.statusAcceptedOn);
                          }))), true).toString()
                    );
                } else {
                    setCurrentUpdate(
                        convertUTCDateToLocalDate(new Date().toString()
                    ));
                }

            }
        }

        LoadWhatsNew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWhatsNew = (updates) => {
        const published = [];
        const drafts = [];
        const checkedDrafts = [];
        for (const index in updates) {
            if (updates[index].isPublished) {
                published.push(updates[index]);
            } else {
                drafts.push(updates[index]);
                checkedDrafts.push(false);
            }
        }
        setPublishedItems(published);
        setDraftItems(drafts);
        setCheckedDrafts(checkedDrafts);
        if (published[0]?.modifiedOn){
            let space = ' '
            setCurrentUpdate(space.concat(convertUTCDateToLocalDate(new Date(published[0].modifiedOn)).toString()));
        }
    }

    const handlePublishUpdate = useCallback((updatedPublishedItems) => {
        setPublishedItems(updatedPublishedItems);
    }, []);

    const handleChange = (e, value) => {
        setActiveTab(value);
    };
      
    function toggleCheckBox(event) {
        const isTargetChecked = event.target.checked;
        const otherCheckBoxes = document.querySelectorAll('input[type="checkbox"]:not([class="drafts-check-box"])');
        otherCheckBoxes.forEach(function (checkBox) {
            checkBox.checked = isTargetChecked;
        });

        const featureItems = document.querySelectorAll(`#drafts`);
        if (isTargetChecked) {
            featureItems.forEach(function (featureItem) {
                if (!featureItem.className.includes('checked')){
                    featureItem.className = featureItem.className + ' checked';
                }
            });
            const tempCheckedDrafts = { ...checkedDrafts };
            for (const index in tempCheckedDrafts) {
                tempCheckedDrafts[index] = true;
            } 
            setCheckedDrafts(tempCheckedDrafts);
        } 
        else {
            featureItems.forEach(function (featureItem) {
                if (featureItem.className.includes('checked')){
                    featureItem.className = featureItem.className.substring(0, featureItem.className.length - 8);
                }
            });
            const tempCheckedDrafts = { ...checkedDrafts };
            for (const index in tempCheckedDrafts) {
                tempCheckedDrafts[index] = false;
            } 
            setCheckedDrafts(tempCheckedDrafts);
        }
    }

    function checkDrafts() {
        const checkedItems = []
        const unCheckedItems = []
        for (const index in draftItems) {
            if (checkedDrafts[index]) {
                let tempItem = draftItems[index];
                tempItem.isPublished = true;
                tempItem.isVisible = true;
                checkedItems.push(tempItem);
            }
            else {
                unCheckedItems.push(draftItems[index])
            }
        }

        return {checkedItems: checkedItems,
            unCheckedItems: unCheckedItems
        };
    }

    async function publishDrafts() {
        let { checkedItems, unCheckedItems } = checkDrafts();
        await pushFeatures(false, {"features": checkedItems}).then(() => {
            let tempPublishedItems = publishedItems.concat(checkedItems);
            setPublishedItems(tempPublishedItems);
            setDraftItems(unCheckedItems);
        })
        .catch(e => {
            console.log(e)
        });
    }

    function checkPublished() {
        let tempItems = publishedItems;
        for (const index in tempItems) {
            tempItems[index].orderNum = index;
        }
        setPublishedItems(tempItems);
        return(tempItems);
    }

    function publish () {
        if (!isPublished) {
            pushFeatures(true, {"features": checkPublished()});   
        }
    }

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()-date.getTimezoneOffset()*60*1000);
        
        return newDate.toLocaleDateString('en-GB', {
                                month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
                            });   
    }

    async function pushFeatures(showToast, payload) {
        await AddPersonaFeatures(payload, persona.name).then(res => {
            if (showToast) {
                const toastProperties = {
                    id: "update-features",  
                    type: 'success',
                    title: 'All Changes Published',
                    message: `All changes made have been succesfully published to "What's New" Page`
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                let space = ' ';
                setCurrentUpdate(space.concat(((new Date()).toLocaleDateString('en-GB', {
                    month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
                })).toString()));
                setEnablePublish(false);
                setIsPublished(true);
        }
        }).catch(err => {
            console.error(err);
        });
    }

    async function removeFeature(id) {
        await RemovePersonaFeature(id).catch(err => {console.error(err);});
        let newItems = []
        const tempItems = { ...draftItems };
        for (const index in tempItems) {
            if(tempItems[index].id !== id) {
                newItems.push(tempItems[index])
            }
        }

        setDraftItems(newItems)
    }

    function handleEditFeatureClick(item) {
        setClickedItem(item);
        setShowFeatureModal(true);
    }

    const filterForLastAdded = (arr1, arr2) => {
        const results = arr1.filter(({ id: id1 }) => !arr2.some(({ id: id2 }) => id2 === id1));
        return results;
    }

    async function saveFeature(feature) {
        await AddPersonaFeatures({"features": [feature]}, persona.name).then(response => {
            
            // new item and isPublished
            if (!('id' in feature) && feature.isPublished) {
                let tempPublished = response.data.filter(item => item.isPublished && (parseInt(item.orderNum) !== -1))
                let tempLastAdded = filterForLastAdded(tempPublished, publishedItems);
                let tempPublishedItems = publishedItems.concat(tempLastAdded);
                setPublishedItems(tempPublishedItems);
            } else if (!('id' in feature) && !feature.isPublished) {
                // new item and not isPublished
                let tempDrafts = response.data.filter(item => !item.isPublished && (parseInt(item.orderNum) === -1));
                let tempLastAdded = filterForLastAdded(tempDrafts, draftItems);
                let tempDraftItems = draftItems.concat(tempLastAdded);
                setDraftItems(tempDraftItems);
            } else {
                // edit existing item
                let draftIndex = draftItems.findIndex(item => item.id === feature.id);
                // publishing draft item
                if (feature.isPublished && draftIndex > -1) {
                    draftItems.splice(draftIndex, 1);
                    let tempPublished = response.data.filter(item => item.isPublished && (parseInt(item.orderNum) !== -1))
                    let tempLastAdded = filterForLastAdded(tempPublished, publishedItems);
                    let tempPublishedItems = publishedItems.concat(tempLastAdded);
                    setPublishedItems(tempPublishedItems);
                    setDraftItems(draftItems);
                } else {
                    // all other edits
                    if (draftIndex > -1) {
                        draftItems[draftIndex].content = feature.content;
                        draftItems[draftIndex].type = feature.type;
                        draftItems[draftIndex].assetId = feature.assetId;
                        draftItems[draftIndex].assetName = feature.assetName;
                        draftItems[draftIndex].isPublished = feature.isPublished;
                        setDraftItems(draftItems);
                    } else {
                        let publishedIndex = publishedItems.findIndex(item => item.id === feature.id);
                        publishedItems[publishedIndex].content = feature.content;
                        publishedItems[publishedIndex].type = feature.type;
                        publishedItems[publishedIndex].assetId = feature.assetId;
                        publishedItems[publishedIndex].assetName = feature.assetName;
                        publishedItems[publishedIndex].isPublished = feature.isPublished;
                        setPublishedItems(publishedItems);
                    }
                }
            }
            setShowFeatureModal(false);
            setClickedItem(null);
        }).catch(err => {
            console.error(err);
        });
    }

    useEffect(()=> {
        if (enablePublish) {
            setIsPublished(false);
        }
    }, [enablePublish]);

    useEffect(() => {
        const recommendedItems = document.querySelectorAll(`#drafts`);
        recommendedItems.forEach(function (recommendedItem) {
            if (recommendedItem.className.includes('checked')){
                recommendedItem.className = recommendedItem.className.substring(0, recommendedItem.className.length - 8);
            }
        });
        const checkedDrafts = new Array(draftItems.length).fill(false)
        setCheckedDrafts(checkedDrafts);
    }, [draftItems]);

    useEffect(()=> {
        let numSelected = 0
        for (const index in checkedDrafts) {
            if (checkedDrafts[index]) {
                numSelected = numSelected + 1;
            }
        }
        
        setNumSelectedDrafts(numSelected);
    }, [checkedDrafts]);

    return (
        <div className='manage-updates-container'>
            <div className='header-container'>
                <div className="header">
                    <div className='header--title'>
                        <h1>Manage Updates</h1>
                        <h2 className='long'>View, add, and manage all updates on the What’s New</h2>
                    </div>
                </div>
                <div className = 'button-container'>
                    <Button className='btn--rounded btn--blue-light wide' id={"add-feature"} onClick={() => setShowFeatureModal(true)}>
                        Add Feature Update
                        <Plus className = 'cross' />
                    </Button>
                </div>
            </div>

            <div className = 'tab'>
                <div className='tab--container'>
                    <Tabs id='whats-new-page-tabs' selectedTab={activeTab} onChange={handleChange}>
                        <Tab id='tab-Whats-New-published' key={1} label="Published" value={1} />
                        <Tab id='tab-Whats-New-drafts' key={2} label="Drafts" value={2} />
                    </Tabs>
                </div>
            </div>
            
            <div className='manage-updates'>
                <div className='last-update-upload'>
                    <TabPanel value={activeTab} selectedIndex={1}>
                        {isPublished  ?  
                            <p className='updates-last-updated'>{'All updates published '}
                            {currentUpdate ?
                                currentUpdate
                                :
                                (new Date()).toLocaleDateString('en-GB', {
                                    month: 'short', day: 'numeric', year: 'numeric'
                                })
                            }
                            </p>
                            :
                            <p className='updates-last-updated red'>You have unpublished changes!</p>
                        }
                    </TabPanel>

                    <TabPanel value={activeTab} selectedIndex={2}>
                        <div className = 'checkbox-container'>
                            <input className = 'drafts-check-box' type="checkbox" onChange= {toggleCheckBox}></input>
                            <div className = 'drafts-text'>Select all drafts </div>
                        </div>
                    </TabPanel>
                    
                    <div className = 'button-container-upload'>
                        <TabPanel value={activeTab} selectedIndex={2}>
                            <Button className={numSelectedDrafts > 0 ? 'btn--publish blue': 'btn--publish'} id={"add-update"} onClick={publishDrafts}>
                                <div className= 'button-text-draft'> 
                                        Publish {'('}{numSelectedDrafts}{')'}
                                </div>
                                    { checkDrafts().checkedItems.length > 0 ? <UploadBlue className='upload' /> :<Upload className='upload' />}
                            </Button>
                        </TabPanel>
                        <TabPanel value={activeTab} selectedIndex={1}>
                            <Button className={enablePublish? 'btn--publish blue': 'btn--publish'} id={"publish-updates"} onClick={publish}>
                                <div className= 'button-text'> 
                                        Publish
                                </div>
                                { enablePublish ? <UploadBlue className = 'upload' />:<Upload className = 'upload' />}
                            </Button>
                        </TabPanel>
                    </div>
                </div>
                
                <BrowseCard id="manage-whats-new" type='recommendation' title='What’s New'>
                    {isWhatsNewLoaded ? 
                        <>
                        <TabPanel value={activeTab} selectedIndex={1}>
                            {publishedItems?.length > 0 ? 
                                <>
                                    <SortableComponent 
                                        publishedItems={publishedItems} 
                                        onPublishedUpdate={handlePublishUpdate} 
                                        handleChange={() => setEnablePublish(true)} 
                                        onEditItem={(item) => handleEditFeatureClick(item)}
                                        showVisible
                                    ></SortableComponent>
                                </>
                                : <DataUnavailable 
                                    id='whats-new-page-unavailbale' 
                                    icon={bellSlash}
                                    header={`Updates`}
                                    subtitle='There are currently no published updates!'
                                />
                            }
                        </TabPanel>
                        <TabPanel value={activeTab} selectedIndex={2}>
                            {isWhatsNewLoaded && draftItems.length > 0 ? 
                                <>
                                    {draftItems.map((value, index) => (
                                        value && 
                                            <RecommendedItemManage
                                                key ={value.id}
                                                id={`drafts`}
                                                hasCheckbox={true}
                                                checked={checkedDrafts[index] ? checkedDrafts[index] : false}
                                                onChange={() => {
                                                    const newCheckedItems={ ...checkedDrafts };
                                                    newCheckedItems[index]=!checkedDrafts[index];
                                                    setCheckedDrafts(newCheckedItems);
                                                }}
                                                removeItem={(item) => removeFeature(item.id)}
                                                item={value}
                                                editItem={() => handleEditFeatureClick(value)}
                                                showEdit
                                            ></RecommendedItemManage>
                                    ))}
                                </>
                                : <DataUnavailable 
                                    id='drafts-page-unavailbale' 
                                    icon={draftIcon}
                                    header={`All Drafts published`}
                                    subtitle='There are currently no drafts to review.'
                                />
                            }
                        </TabPanel>
                        </>
                    : <Grid color="#999999" height={100} width={110} ariaLabel='loading' wrapperClass="manage-updates-spinner" />}
                </BrowseCard>
            </div>
            <FeatureModal 
                isOpen={showFeatureModal} 
                handleClose={() => {setShowFeatureModal(false); setClickedItem(null)}}
                header={clickedItem ? 'Edit Feature Update' : 'New Feature Update'}
                saveFeature={(feature) => saveFeature(feature)}
                featureToEdit={clickedItem}
            />
        </div>
    );
};

ManageUpdates.propTypes = {
    id: PropTypes.string,
}

export default ManageUpdates;





    