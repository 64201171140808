/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Actions } from '../../../contexts/action';
import { Context } from '../../../contexts/context';
import { sendEvent, trackSort } from '../../../helpers/analyticsHandler';
import { GetPersonas, addPersonaUser } from '../../../services/dmpApi';
import BackButton from '../../Button/BackButton/BackButton';
import Button from '../../Button/Button';
import AddPersonaUserModal from '../../Modal/AddPersonaUserModal';
import ErrorModal from '../../Modal/ErrorModal';
import { Tab, TabPanel, Tabs } from '../../Tabs/Tabs';
import StatefulDatatable from '../../datatable/StatefulDatatable';

import actionsButton from '../../../assets/icons/actions-button.svg';
import DataWorkerIcon from '../../../assets/icons/data-worker-icon.svg';
import ExecutiveIcon from '../../../assets/icons/executive-icon.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import TeamManagerIcon from '../../../assets/icons/team-manager-icon.svg';
import userType from '../../../assets/icons/user-type.svg';
import userGroupIcon from '../../../assets/icons/userGroupIcon.svg';
import './ManagePersona.scss';

const ManagePersona = () => {
    
    const {
        dispatch
    } = useContext(Context);
    const { term } = useParams();
    const [activeTab, setActiveTab] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState('');
    const [results, setResults] = useState([]);
    const [activePersona, setActivePersona] = useState(0);
    const [personaName, setPersonaName] = useState('');
    const [resultsCount, setResultsCount] = useState(0);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [userName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userEmailError, setUserEmailError] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const emailRegex = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, "gm");

    const defaultSort = [
        {
            id: 'name',
            desc: false
        }
    ];

    useEffect(LoadManageUsergroup, [loadError.length, isLoading, isSubmitLoading, activeTab, term]);

    function LoadManageUsergroup() {
        GetPersonas(term)
            .then(response => {
                setResults(response.data);
                if (loadError.length > 0) setLoadError('');
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                setLoadError('Error loading Search results. ' + error);
                console.log(error);
            });
    }

    const data = useMemo(() => [...results], [results]);

    const handleChange = (e, value) => {
        setActiveTab(value);
    };

    const handlePersonaClick = (personaId) => {
        setActivePersona(personaId);
        setPersona(personaId);
        setActiveTab(1);
        if(data.filter(d => d.name===personaName).length!==0){
            setResultsCount(data.filter(d => d.name===personaName)[0]?.groups?.length)
        } 
    }

    const setPersona = (personaId) => {
        switch(personaId) {
            case 1 :
                return setPersonaName('Executive')
            case 2:
                return setPersonaName('Team Manager')
            case 3:
                return setPersonaName('Data Worker')
            default:
                return null;
        }
    }

    const handleBackButton = () => {
        setActivePersona(0);
    }

    function handleManagePersonaSortChange(option, setSortBy) {
        switch (option) {
            case 'Alphabetical A-Z':
                setSortBy([{ id: "name", desc: false }]);
                break;
            case 'Alphabetical Z-A':
                setSortBy([{ id: "name", desc: true }]);
                break;
            default:
                setSortBy([{ id: "name", desc: false }]);
        }

        trackSort(option);
        sendEvent('analyticsClickAction', 'product_sort', null, option, null);
    }

    const userColumns = useMemo(
        () => [
            {
                Header: "userName",
                accessor: "name",
                Cell: ({ row }) => (
                    <>
                    <img className='persona-user-icon' src={userType} alt="persona user icon"/>
                    <span className='persona-user-name'>{row.original.name}</span>
                    </>
                )
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ row }) => (
                    <>
                    <span className='persona-user-email'>{row.original.email}</span>
                    </>
                )
            }
        ],
        []
    );

    const userGroupColumns = useMemo(
        () => [
            {
                Header : 'Name',
                accessor: 'name',
                Cell: ({row}) => (
                    <>
                        <img className='user-group-icon' src= {userGroupIcon} alt="persona user group icon"/>
                        <span className='user-group-name' >{row.original.name}</span>
                    </>
                )
            }
        ],[]);

    const handleAddUserSubmitForm = async () => {
        const valid = userName.length>0 && userEmail.length>0;
        if (valid) {
            const payload = {
                email: userEmail,
                name: userName
            }

            setIsSubmitLoading(true);
            addPersonaUser(personaName, payload)
                .then(response => {
                    setIsSubmitLoading(false);
                    setShowAddUserModal(false);
                    const toastProperties = {
                        id: "add-persona-user",  
                        type: 'success',
                        title: 'User Added',
                        message: `${userName} has been successfully added.`
                    }
                    dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                }).catch(err => {
                    setIsSubmitLoading(false);
                    console.log(err);
                    setShowAddUserModal(false);
                    setShowModalError(true);
                });
        } else {
            setUserNameError(true);
            setUserEmailError(true);
        }
    }
    const handleUserNameFieldChange =(value) => {
        setUserName(value);
        setUserNameError(value.length <= 0);
    }
    const handleUserEmailFieldChange =(value) => {
        setUserEmail(value);
       setUserEmailError(!emailRegex.test(value));
    }

    return (
        <div className='manage-personas'>
            {activePersona === 0 ?
            <div className = 'manage-persona-container'>
                <div className = 'header-container'>
                    <div className="header">
                        <div className='header--title'>
                            <h1>Manage Personas</h1>
                            <h2 className='long'>Select a Persona to add or remove a user groups and/or users.</h2>
                        </div>
                    </div>
                </div>
                <div className = 'persona-icon-container'>
                    <div className = 'tab-header'>
                        <span className = 'title'>USER PERSONAS</span>
                    </div>
                    <div className = 'persona-icons-grid'>
                        <div className={data.filter(d => d.name==='Executive').length!==0 ? '--persona-column':'--persona-column--inactive'} onClick={() => {handlePersonaClick(1)}}>
                            <div className='action-button'>
                            <img src={actionsButton} alt="actions-button" />
                            <Button id='manage-persona-btn' className='btn--rounded btn--blue-light wide' onClick={() => {handlePersonaClick(1)}}>Manage</Button></div>
                            <div className = 'executive --persona-icon'>
                            <img src={ExecutiveIcon} alt="Executive Icon" /><h1>Executive</h1>
                            </div>
                        </div>
                        <div className={data.filter(d => d.name==='Team Manager').length!==0 ? '--persona-column' : '--persona-column--inactive'} onClick={() => {handlePersonaClick(2)}}>
                            <div className='action-button'>
                                <img src={actionsButton} alt="actions-button" />
                                <Button id='manage-persona-btn' className='btn--rounded btn--blue-light wide' onClick={() => {handlePersonaClick(2)}}>Manage</Button>
                            </div>
                            <div className = 'team-manager --persona-icon' >
                                <img src={TeamManagerIcon} alt="team manager Icon" /><h1>Team Manager</h1>
                            </div>
                        </div>
                        <div className= {data.filter(d => d.name==='Data Worker').length!==0 ? '--persona-column':'--persona-column--inactive'} onClick={() => {handlePersonaClick(3)}}>
                            <div className='action-button'>
                                <img src={actionsButton} alt="actions-button" />
                                <Button id='manage-persona-btn' className='btn--rounded btn--blue-light wide' onClick={() => {handlePersonaClick(3)}}>Manage</Button>
                            </div>
                            <div className = 'data-worker --persona-icon'>
                                <img src={DataWorkerIcon} alt="data worker Icon" /><h1>Data Worker</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className={'persona-management-container'}>
                <BackButton id='manage-personas' handleClick={() => handleBackButton()}/>
                <div className='persona-description-container'>
                    <div className='persona-description'>
                        <div className={activePersona===1 ? 'persona-title --executive':'persona-title--inactive'}>
                            <div className='persona-header'><img src={ExecutiveIcon} alt="Executive Icon" /><span>Executive</span></div>
                            <span className='persona-definition-text'>User is a member of the CEO&#39;s executive management team inclusive of the CEO. The Data & AI Marketplace for them is similar to a shop as they will enter to &#34;purchase&#34; data goods, mainly dashboards, to review and make decisions based on the information provided in these reports.</span>
                        </div> 
                        <div className={activePersona===2 ? 'persona-title --team-manager':'persona-title--inactive'}>
                            <div className='persona-header'><img src={TeamManagerIcon} alt="team manager Icon" /><span>Team Manager</span></div>
                            <span className='persona-definition-text'>User is an individual that oversees a team of employees. Team Managers will be our point of contact for determining product owners & data steward owners for spoke products. The Data & AI Marketplace for them is a window into the NRG Data Lake, SaraS as well as a place to review their teams products that have been uploaded into the Data & AI Marketplace.</span>
                        </div>
                        <div className={activePersona===3 ? 'persona-title --data-worker':'persona-title--inactive'}>
                            <div className='persona-header'><img src={DataWorkerIcon} alt="data worker Icon" /><span>Data Worker</span></div>
                            <span className='persona-definition-text'>User is technical and skilled with wrangling data and understanding data structures. The Data & AI Marketplace for them is a window into the NRG Data Lake, SaraS. They are looking for information on what is in the data lake, understanding different data tables, reviewing dashboards available, learning about different data science models and requesting access to information.</span>
                        </div> 
                    </div>
                    <div className={activeTab===2 ? 'button-container' : 'button-container--inactive'}>
                        <Button className='btn--rounded btn--blue-light wide' id={"add-user"} onClick={() => setShowAddUserModal(true)}>
                            Add User
                            <Plus className = 'cross' />
                        </Button>
                    </div>
                </div>
                <div className='tab--container'>
                    <Tabs id='product-detail-page-tabs' selectedTab={activeTab} onChange={handleChange}>
                        <Tab id='product-detail-page-tab-definitions' key={1} label="Manage user groups" value={1} />
                        <Tab id='product-detail-page-tab-data-traceability' key={2} label="Manage USers" value={2} />
                    </Tabs>
                </div>
                <div className={data.length === 0 ? 'tab--panel-container inactive' : 'tab--panel-container'}>
                    <TabPanel value={activeTab} selectedIndex={1}>
                        <StatefulDatatable givenClassName='user-group-datatable'
                            pageName='persona-user-detail'
                            id='persona-manage-user-page-datatable'
                            columns={userGroupColumns}
                            data={data.filter(d => d.name===personaName)[0]?.groups===undefined ? 
                                [] : data.filter(d => d.name===personaName)[0].groups}
                            defaultPageSize={10}
                            setResultsCount={setResultsCount}
                            hasGlobalFilter={false}
                            sortOptions={['Alphabetical A-Z', 'Alphabetical Z-A']}
                            onChangeSort={handleManagePersonaSortChange}
                            defaultSortBy={defaultSort}
                            resultsCount = {resultsCount}
                            noResultsMessage = {'No results found'}
                            displayCheck = {false}
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} selectedIndex={2}>
                        <StatefulDatatable givenClassName='persona-user-datatable'
                            pageName='persona-user-detail'
                            id='persona-manage-user-page-datatable'
                            columns={userColumns}
                            data={data.filter(d => d.name===personaName)[0]?.personaUsers===undefined ? 
                                [] : data.filter(d => d.name===personaName)[0].personaUsers}
                            defaultPageSize={10}
                            setResultsCount={setResultsCount}
                            hasGlobalFilter={false}
                            sortOptions={['Alphabetical A-Z', 'Alphabetical Z-A']}
                            onChangeSort={handleManagePersonaSortChange}
                            defaultSortBy={defaultSort}
                            resultsCount = {resultsCount}
                            noResultsMessage = {'No results found'}
                            displayCheck = {false}
                        />
                    </TabPanel>
                </div>
            </div>
        }
        <AddPersonaUserModal
            id={"manage-persona-admin"}
            isOpen={showAddUserModal}
            handleClose={() => {setShowAddUserModal(false);}}
            personaName={personaName}
            handleSubmitForm={handleAddUserSubmitForm}
            isSubmitLoading={isSubmitLoading}
            userName={userName}
            userNameError={userNameError}
            userEmail={userEmail}
            userEmailError={userEmailError}
            handleUserEmailFieldChange={handleUserEmailFieldChange}
            handleUserNameFieldChange={handleUserNameFieldChange}
        ></AddPersonaUserModal>
        
        <ErrorModal
            id={`faq-page-error-modal`}
            isOpen={showModalError}
            handleClose={() => setShowModalError(false)}
            message='Your request has failed. Please submit your request again.'
            >
        </ErrorModal>
        </div>
    )
}

export default ManagePersona;