import { ArrowRight } from "phosphor-react";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import { Link, useNavigate } from "react-router-dom";

import { Context } from '../../contexts/context';
import { sendEvent } from '../../helpers/analyticsHandler';
import { TrackAssetUsage } from '../../services/dmpApi';

import Button from '../../components/Button/Button';
import FavoritesUnavailable from '../../components/FavoriteElements/FavoritesUnavailable/FavoritesUnavailable';
import ProductTile from '../../components/ProductTile/ProductTile';
import ScrollToBottom from '../../components/ScrollTo/ScrollToBottom';
import BrowseCard from '../../components/browse/BrowseCard';
import BrowseItem from '../../components/browse/BrowseItem';
import FavoriteCard from '../../components/browse/FavoriteCard';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';

import genAI from '../../assets/icons/genAI.svg';
import business from '../../assets/icons/home/business-blue.svg';
import corporate from '../../assets/icons/home/corporate-blue.svg';
import dashboard from '../../assets/icons/home/dashboard-blue.svg';
import dataScience from '../../assets/icons/home/dataScience-blue.svg';
import home from '../../assets/icons/home/home-blue.svg';
import marketOps from '../../assets/icons/home/marketOps-blue.svg';
import plantOps from '../../assets/icons/home/plantOps-blue.svg';
import services from '../../assets/icons/home/services-blue.svg';
import table from '../../assets/icons/home/table-blue.svg';
import vivint from '../../assets/icons/home/vivint-blue.svg';
import './Browse.scss';

const Browse = ({ id }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const {
        state: {
            isLoaded,
            favorites
        }
    } = useContext(Context);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [numFiltersSelected, setNumFiltersSelected] = useState(0)
    const [scrollDown, setScrollDown] = useState(false);

    let browseItems = [
        {
            type: 'product',
            title: 'Product Type',
            button: 'Data Products',
            items: [
                {
                    icon: dashboard,
                    name: 'Dashboard',
                    filter: {
                        param: 'type', value: 'Dashboard'
                    }
                },
                {
                    icon: table,
                    name: 'Data Products',
                    filter: {
                        param: 'type', value: 'Table'
                    }
                },
                {
                    icon: dataScience,
                    name: 'Data Science Product',
                    filter: {
                        param: 'type', value: 'Data Science Product'
                    }
                },
                {
                    icon: genAI,
                    name: 'Gen AI',
                    filter: {
                        param: 'type', value: 'Gen AI Use Case'
                    }
                }
            ],
        },
        {
            type: 'domain',
            title: 'Domain',
            button: 'Domains',
            items: [
                {
                    icon: home,
                    name: 'Home',
                    subtitle: 'Customer Ops',
                    filter: {
                        param: 'businessSegment', value: 'Home'
                    }
                },
                {
                    icon: services,
                    name: 'Services',
                    subtitle: 'Customer Ops',
                    filter: {
                        param: 'businessSegment', value: 'Services'
                    }
                },
                {
                    icon: business,
                    name: 'Business',
                    subtitle: 'Customer Ops',
                    filter: {
                        param: 'businessSegment', value: 'Business'
                    }
                },
                {
                    icon: vivint,
                    name: 'Smart Home',
                    subtitle: 'Customer Ops',
                    filter: {
                        param: 'businessSegment', value: 'Smart Home'
                    }
                },
                {
                    icon: marketOps,
                    name: 'Market Ops',
                    subtitle: '',
                    filter: {
                        param: 'domain', value: 'Market Operations'
                    }
                },
                {
                    icon: plantOps,
                    name: 'Plant Ops',
                    subtitle: '',
                    filter: {
                        param: 'domain', value: 'Plant Operations'
                    }
                },
                {
                    icon: corporate,
                    name: 'Corporate',
                    subtitle: '',
                    filter: {
                        param: 'domain', value: 'Corporate'
                    }
                }
            ]
        },
        {
            type: 'favorites',
            title: 'My Favorites',
            button: 'Favorites',
            items: favorites
        },
    ];


    function handleFavoriteItemClick(hasAccess, product) {
        if (hasAccess === true || product.type === 'Data Science Product') {
            navigate(`/product/${product.id}`, {
                state: {
                    id: product.id,
                    name: product.name,
                    type: product.type
                }
            });
        } else {
            setModalId(product.id);
            setShowModal(!showModal);
        }

        sendEvent('analyticsClickAction', 'browse_favoritesOpen', null, null, product);
    }

    useEffect(() => {
        if (isLoaded) {
            setIsLoading(false);
        }
    }, [isLoaded]);

    const handleShowProduct = (e, row) => {
        e.stopPropagation();
        openProductHandler(row);
    }

    function openProductHandler(row) {
        TrackAssetUsage(row.id)
            .catch(error => {
                console.log(error);
            });

        sendEvent('analyticsClickAction', 'browse_favoriteLaunch', null, null, row);

        const newWindow = window.open(getProductUrl(row), '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    function getProductUrl(data) {
        if (data.type === 'Data Science Product') {
            return data.presentation;
        } else if (data.access === 'app' && data.appUrl) {
            return data.appUrl;
        }

        let url = data.url.replace(/&amp;/g , "&");
        return url;
    }

    function addFilterSelect(filterParam, filterValue) {
        let index = selectedFilters.findIndex(filter => filter.param === filterParam);
        let tempSelectedFilters = [...selectedFilters];
        let eventTile = 'Tile Selected';

        if (index < 0) {
            setNumFiltersSelected(numFiltersSelected + 1)
            tempSelectedFilters.push({
                param: filterParam,
                value: new Array(filterValue)
            });
        } else {
            let filterIndex = tempSelectedFilters[index].value.findIndex(item => item === filterValue);
            if (filterIndex < 0) {
                setNumFiltersSelected(numFiltersSelected + 1);
                tempSelectedFilters[index].value.push(filterValue);
            } else {
                setNumFiltersSelected(numFiltersSelected - 1);
                tempSelectedFilters[index].value.splice(filterIndex, 1);
                eventTile = 'Tile Unselected';
            }
        }
        //Auto Select Data Set','Data Entity' 'Schema' in search page, when product type 'Table' is selected
        let tempAssetTypes = ['Data Set','Data Entity','Schema'];
        let typeIndex = tempSelectedFilters.findIndex(filter => filter.param === 'type');
        if(typeIndex>=0){
            let tableIndex = tempSelectedFilters[typeIndex].value.findIndex(item => item === 'Table');
            tempAssetTypes.forEach((assetTypeName)=>{
                let assetIndex = tempSelectedFilters[typeIndex].value.findIndex(item => item === assetTypeName);
                if(tableIndex>=0 && assetIndex<0){
                    tempSelectedFilters[typeIndex].value.push(assetTypeName);
                }else if(tableIndex<0 && assetIndex>=0){
                    tempSelectedFilters[typeIndex].value.splice(assetIndex, 1)
                }
            })
        }  
        //Auto select Customer Operations when a Business segment filter is selected
        //Auto Select NRG enterprise when a non-customer-operations domain is selected && segment filters are selected
        let segmentIndex = tempSelectedFilters.findIndex(filter => filter.param === 'businessSegment');
        let domainIdx = tempSelectedFilters.findIndex(filter => filter.param === 'domain');

        if(segmentIndex>=0 && tempSelectedFilters[segmentIndex].value.length>0){
            let nrgIdx = tempSelectedFilters[segmentIndex].value.findIndex(item => item === 'NRG Enterprise')
            let nonNrgIdx = tempSelectedFilters[segmentIndex].value.findIndex(item => item !== 'NRG Enterprise')
            
            if(domainIdx>=0 && tempSelectedFilters[domainIdx].value.length>0){
                let nonCustOpIdx = tempSelectedFilters[domainIdx].value.findIndex(item => item !== 'Customer Operations')
                let custOpIdx = tempSelectedFilters[domainIdx].value.findIndex(item => item === 'Customer Operations')
                
                if(nonNrgIdx>=0){
                    if(custOpIdx<0){ tempSelectedFilters[domainIdx].value.push('Customer Operations') }
                    if(nonCustOpIdx>=0 && nrgIdx<0){ tempSelectedFilters[segmentIndex].value.push('NRG Enterprise') }
                    if(nonCustOpIdx<0 && nrgIdx>=0){ tempSelectedFilters[segmentIndex].value.splice(nrgIdx, 1) }
                }else if(nonNrgIdx<0){ 
                    if(custOpIdx>=0){ tempSelectedFilters[domainIdx].value.splice(custOpIdx, 1) }
                    if(nrgIdx>=0){ tempSelectedFilters[segmentIndex].value.splice(nrgIdx, 1) }
                }

            }else if(nonNrgIdx>=0){
                tempSelectedFilters.push({
                    param: 'domain',
                    value: new Array('Customer Operations')
                })
                if(nrgIdx>=0){ tempSelectedFilters[segmentIndex].value.splice(nrgIdx, 1) }
            }
        }else if(domainIdx>=0){
           let custOpIdx = tempSelectedFilters[domainIdx].value.findIndex(item => item === 'Customer Operations')
           if(custOpIdx>=0){tempSelectedFilters[domainIdx].value.splice(custOpIdx, 1)}
        }

        sendEvent('analyticsClickAction', 'browse_optionSelect', eventTile, filterParam + ':' + filterValue);
        setSelectedFilters(tempSelectedFilters);
    }

    useEffect(() => {
        if (selectedFilters.length === 1) {
            setScrollDown(true);
        }
        let tempSelectedFilters = selectedFilters.filter(filter => filter.value.length > 0);
        if (tempSelectedFilters.length !== selectedFilters.length) {
            setSelectedFilters(tempSelectedFilters);
        }
    }, [selectedFilters]);

    return (
        <section className='content--container browse-content' data-testid={id}>
            <div className='header'>
                <div className='header--title'>
                    <h1 className='short'>Welcome</h1>
                    <h1 className='long'>Welcome to the Data & AI Marketplace</h1>
                    <h2>Narrow your search by different categories or use the search bar</h2>
                </div>
                <div className='button-browse'>
                    <Link to="/search" state={{ defaultFilter: [], defaultPageIndex: 0 }} className='link'>
                        <Button 
                            className='btn btn--block-rounded btn--blue-light' 
                            onClick={() => sendEvent('analyticsClickAction', 'browse_browseAll')}
                        >
                            Browse All
                            <div className='circle'>
                                <ArrowRight size={16} weight="bold" />
                            </div>
                        </Button>
                    </Link>
                </div>
            </div>
            <div className='narrow'>
                <BrowseCard id={`${id}-${browseItems[0].type}`} type='wide' key={browseItems[0].type} title='Choose Browse Options'>
                    <div className='browse-card--links'>
                        <div className='browse-card--option product-type'>
                            <div className='browse-card--option-title'>{browseItems[0].title}</div>
                            <div className= 'browse-card--option tall'>
                            {browseItems[0].items.map(({ icon, name, subtitle, filter }) => {
                                return (
                                    <BrowseItem key={name} icon={icon} name={name} subtitle = {subtitle} handleItemClick={() => addFilterSelect(filter.param, filter.value)} />
                                );
                            })}
                            </div>
                        </div>
                        <div className='browse-card--option domain'>
                            <div className='browse-card--option-title tall'>{browseItems[1].title}</div>
                            <div className= 'browse-card--option tall'>
                            {browseItems[1].items.map(({ icon, name, subtitle, filter }) => {
                                return (
                                    <BrowseItem key={name} icon={icon} name={name} subtitle = {subtitle} handleItemClick={() => addFilterSelect(filter.param, filter.value)} />
                                );
                            })}
                            </div>
                        </div>
                       
                    </div>
                    <hr className='card-inner-hr'></hr>
                    <div className='browse-card--browse-all'>
                        <Link to="/search" state={{ defaultFilter: selectedFilters, defaultPageIndex: 0 }} className='link'>
                            <Button 
                                disabled={numFiltersSelected === 0} 
                                className='btn btn--block-rounded btn--blue-light browse-button'
                                onClick={() => sendEvent('analyticsClickAction', 'browse_selected')}
                            >
                                <div><span className='browse-all--title'>Browse Selected ({numFiltersSelected})&nbsp;</span><span className='browse-all--name'></span></div>
                                <div className='circle'>
                                    <ArrowRight size={16} weight="bold" />
                                </div>
                            </Button>
                        </Link>
                    </div>
                </BrowseCard>
                <FavoriteCard id={`${id}-${browseItems[2].type}`} type='long' key={browseItems[2].type} title={browseItems[2].title}>
                    {isLoading ?
                        <div className="favorites-spinner" id="grid-loading">
                            <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                            <span>Loading....</span>
                        </div> :
                        browseItems[2].items?.length > 0 ?
                            <>
                                <div className='favorite-card--links'>
                                    {browseItems[2].items.map((item) => {
                                        return (
                                            <span key={item.id}>
                                                <ProductTile
                                                    id={`${id}-favorite-item-${item.id}`}
                                                    name={item.name}
                                                    access={item.hasAccess || item.type==="Data Science Product"}
                                                    launchButtonAccess = {true}
                                                    type={item.type}
                                                    handleClick={() => handleFavoriteItemClick((item.hasAccess || item.type==="Data Science Product"), {
                                                        id: item.id,
                                                        name: item.name,
                                                        type: item.type
                                                    })}
                                                    favoriteItem={item}
                                                    handleOpenProductClick={(e) => handleShowProduct(e, item)}
                                                />
                                            </span>
                                        );
                                    })}
                                </div>
                                <div className='favorite-card--favorite-all'>
                                    <Link to="/favorite" state={{ defaultFilter: [] }} className='link'>
                                        <Button className='btn btn--block-rounded btn--blue-light favorite-button' onClick={() => sendEvent('analyticsClickAction', 'browse_favorites')}>
                                            <div><span className='browse-all--title'>Browse All </span><span className='browse-all--name'>{browseItems[2].button}</span></div>
                                            <div className='circle'>
                                                <ArrowRight size={16} weight="bold" />
                                            </div>
                                        </Button>
                                    </Link>
                                </div>
                            </>
                            : <FavoritesUnavailable />
                    }
                </FavoriteCard>
            </div>
            <DetailDrawer
                id={`browse-page-details-${modalId}`}
                itemId={modalId}
                modalActive={showModal}
                setModalState={(state) => setShowModal(state)}
                data-testid='browse-page-details'
            />
            <ScrollToBottom scroll={scrollDown} />
        </section>
    );
};

Browse.propTypes = {
    id: PropTypes.string
}

export default Browse;