import React from 'react';
import PropTypes from 'prop-types';
import './Switch.scss';

const Switch = ({ id, isSwitched, handleChange }) => {
    return (
        <div className="switch-container">
            <label id={id} className="toggle-switch">
                <input type="checkbox" checked={isSwitched} onChange={handleChange} data-testid={id} />
                <span className="switch" />
            </label>    
        </div>
    );
};

Switch.propTypes = {
    id: PropTypes.string,
    isSwitched: PropTypes.bool,
    handleChange: PropTypes.func
}

export default Switch;
