import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function ContentEditableInput(props) {
    const [editable, setEditable] = useState(false);

    return (
        <>
            {editable ?
                <input
                    id={`${props.id}-input`}
                    data-testid={`${props.id}-input`}
                    autoFocus
                    defaultValue={props.value}
                    onChange={props.onChange}
                    onBlur={() => setEditable(false)} />
                :
                <input
                    id={`${props.id}`}
                    data-testid={`${props.id}`}
                    readOnly={true}
                    value={props.value}
                    onClick={() => setEditable(true)} />
            }

        </>
    );
}
ContentEditableInput.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    id: PropTypes.string
};