import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Actions } from '../../../contexts/action';
import { Context } from '../../../contexts/context';
import { AddPersonaRecommendations, GetAssetPermissions, ProductSearch, RemovePersonaRecommendation } from '../../../services/dmpApi';

import Button from '../../Button/Button';
import DataUnavailable from '../../DataUnavailable/DataUnavailable';
import RecommendationModal from '../../Modal/RecommendationModal';
import RecommendedItemManage from '../../RecommendedItemManage/RecommendedItemManage';
import SortableComponent from '../../SortableComponent/SortableComponent';
import { Tab, TabPanel, Tabs } from '../../Tabs/Tabs';
import BrowseCard from '../../browse/BrowseCard';

import bellSlash from '../../../assets/icons/bell-slash.svg';
import draftIcon from '../../../assets/icons/drafts.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as UploadBlue } from '../../../assets/icons/upload-blue.svg';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';

import './ManageRecs.scss';

const ManageRecs = ({ id }) => {

    const {
        state: {
            persona
        },
        dispatch
    } = useContext(Context);

    const [permissions, setPermissions] = useState(null);
    const [checkedDrafts, setCheckedDrafts] = useState({});
    const [numSelectedDrafts, setNumSelectedDrafts] = useState(0);
    const [publishedItems, setPublishedItems] = useState({});
    const [draftItems, setDraftItems] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [enablePublish, setEnablePublish] = useState(false);
    const [isPublished, setIsPublished] = useState(true);
    const [currentUpdate, setCurrentUpdate] = useState(null);
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);
    const [recommendationProducts, setRecommendationProducts] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [clickedItem, setClickedItem] = useState(null);

    const handlePublishUpdate = useCallback((updatedPublishedItems) => {
        setPublishedItems(updatedPublishedItems);
    }, []);

    const handleChange = (e, value) => {
        setActiveTab(value);
    };
      
    function toggleCheckBox(event) {
        const isTargetChecked = event.target.checked;
        const otherCheckBoxes = document.querySelectorAll('input[type="checkbox"]:not([class="drafts-check-box"])');
        otherCheckBoxes.forEach(function (checkBox) {
            checkBox.checked = isTargetChecked;
        });

        const recommendedItems = document.querySelectorAll(`#drafts`);
        if (isTargetChecked) {
            recommendedItems.forEach(function (recommendedItem) {
                if (!recommendedItem.className.includes('checked')){
                    recommendedItem.className = recommendedItem.className + ' checked';
                }
            });
            const tempCheckedDrafts = { ...checkedDrafts };
            for (const index in tempCheckedDrafts) {
                tempCheckedDrafts[index] = true;
            } 
            setCheckedDrafts(tempCheckedDrafts);
        } 
        else {
            recommendedItems.forEach(function (recommendedItem) {
                if (recommendedItem.className.includes('checked')){
                    recommendedItem.className = recommendedItem.className.substring(0, recommendedItem.className.length - 8);
                }
            });
            const tempCheckedDrafts = { ...checkedDrafts };
            for (const index in tempCheckedDrafts) {
                tempCheckedDrafts[index] = false;
            } 
            setCheckedDrafts(tempCheckedDrafts);
        }
    }

    function checkDrafts() {
        const checkedItems = []
        const unCheckedItems = []
        for (const index in draftItems) {
            if (checkedDrafts[index]) {
                let tempItem = draftItems[index];
                tempItem.isPublished = true;
                checkedItems.push(tempItem);
            }
            else {
                unCheckedItems.push(draftItems[index])
            }
        }
        const newItems = publishedItems.concat(checkedItems).concat(unCheckedItems);

        updatePersona(newItems);

        return(checkedItems);
    }

    function publishDrafts() {
        pushRecommendations(false, {"recommendations": checkDrafts()});
        
        let newItems = []
        const tempItems = { ...persona?.recommendations?.recommendationsList };
        for (const index in tempItems) {
            if(tempItems[index].id !== id) {
                newItems.push(tempItems[index])
            }
        }
        updatePersona(newItems);
    }

    function updatePersona(newItems) {
        const tempPersona = { ...persona }
        for (const index in newItems) {
            if (permissions[newItems[index].assetId] != null && !("hasAccess" in newItems[index])) {
                newItems[index].hasAccess = permissions[newItems[index].assetId]
            }
        }
        tempPersona.recommendations.recommendationsList = newItems;
        dispatch({ type: Actions.UPDATE_PERSONA, payload: tempPersona });
    }

    function checkPublished() {
        let tempItems = publishedItems;
        for (const index in tempItems) {
            tempItems[index].orderNum = index
        }
        const newItems = tempItems.sort((a, b) => a.orderNum - b.orderNum).concat(draftItems);
        updatePersona(newItems);
        return(tempItems);
    }

    function publish () {
        if (!isPublished) {
            pushRecommendations(true, {"recommendations": checkPublished()});   
        }
    }

    useEffect(() => {
        const LoadPermissions = async () => {
            await GetAssetPermissions(['Dashboard', 'Data Science Product', 'Table']).then(response => {
                if (response) {
                    const data = response.data;
                    let assetPermissions = data.results.map(item => {
                        return {
                            id: item.id,
                            access: item.access,
                            appUrl: item.appUrl,
                            hasAccess: item.hasAccess
                        }
                    });
                    let permission_dict = {};
                    for (var i = 0; i < assetPermissions.length; i++) {
                        permission_dict[assetPermissions[i].id] = assetPermissions[i].hasAccess;
                    }
                    setPermissions(permission_dict);
                }
            }).catch((err) => console.log(err));
        }
        if (permissions === null) {
            LoadPermissions();
        }
        
    }, [dispatch, permissions]);

       

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()-date.getTimezoneOffset()*60*1000);

        return newDate.toLocaleDateString('en-GB', {
            month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
        });   
    }

    async function pushRecommendations(showToast, payload) {
        await AddPersonaRecommendations(payload).then(res => {
            if (showToast) {
                const toastProperties = {
                    id: "update-recommendations",  
                    type: 'success',
                    title: 'All Changes Published',
                    message: `All changes made have been succesfully published to "What's New" Page`
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                let space = ' ';
                setCurrentUpdate(space.concat(((new Date()).toLocaleDateString('en-GB', {
                    month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
                })).toString()));
                setEnablePublish(false);
                setIsPublished(true);
        }
        }).catch(err => {
            console.error(err);
        });
    }

    async function removeRec(id) {
        await RemovePersonaRecommendation(id).catch(err => {console.error(err);});
        let newItems = []
        const tempItems = { ...persona?.recommendations?.recommendationsList };
        for (const index in tempItems) {
            if(tempItems[index].id !== id) {
                newItems.push(tempItems[index])
            }
        }
        const tempPersona = { ...persona }
        tempPersona.recommendations.recommendationsList = newItems;
        dispatch({ type: Actions.UPDATE_PERSONA, payload: tempPersona });
    }

    function handleEditRecClick(item) {
        setClickedItem(item);
        setShowRecommendationModal(true);
        if (item.type !== 'Search Term' && !recommendationProducts) {
            refreshRecommendationProducts(item.type);
        }
    }

    const filterForLastAdded = (arr1, arr2) => {
        const results = arr1.filter(({ id: id1 }) => !arr2.some(({ id: id2 }) => id2 === id1));
        return results;
    }

    async function saveRec(recommendation) {
        await AddPersonaRecommendations({"recommendations": [recommendation]}).then(response => {

            // new item and isPublished
            if (!('id' in recommendation) && recommendation.isPublished) {
                let tempPublished = response.data.filter(rec => rec.isPublished && (parseInt(rec.orderNum) !== -1))
                let tempLastAdded = filterForLastAdded(tempPublished, publishedItems);
                let tempPublishedItems = publishedItems.concat(tempLastAdded);
                let newItems = tempPublishedItems.concat(draftItems);
                
                updatePersona(newItems);
                setPublishedItems(tempPublishedItems);
            } else if (!('id' in recommendation) && !recommendation.isPublished) {
                // new item and not isPublished
                let tempDrafts = response.data.filter(rec => !rec.isPublished && (parseInt(rec.orderNum) === -1));
                let tempLastAdded = filterForLastAdded(tempDrafts, draftItems);
                let tempDraftItems = draftItems.concat(tempLastAdded);
                let newItems = publishedItems.concat(tempDraftItems);

                updatePersona(newItems);
                setDraftItems(tempDraftItems);
            } else {
                // edit existing item
                let draftIndex = draftItems.findIndex(rec => rec.id === recommendation.id);
                // publishing draft item
                if (recommendation.isPublished && draftIndex > -1) {
                    draftItems.splice(draftIndex, 1);
                    let tempPublished = response.data.filter(rec => rec.isPublished && (parseInt(rec.orderNum) !== -1))
                    let tempLastAdded = filterForLastAdded(tempPublished, publishedItems);
                    let tempPublishedItems = publishedItems.concat(tempLastAdded);
                    let newItems = tempPublishedItems.concat(draftItems);
                    updatePersona(newItems);
                    setDraftItems(draftItems);
                    setPublishedItems(tempPublishedItems);
                } else {
                    // all other edits
                    if (draftIndex > -1) {
                        draftItems[draftIndex].content = recommendation.content;
                        draftItems[draftIndex].type = recommendation.type;
                        draftItems[draftIndex].assetId = recommendation.assetId;
                        draftItems[draftIndex].assetName = recommendation.assetName;
                        draftItems[draftIndex].isPublished = recommendation.isPublished;
                        updatePersona(publishedItems.concat(draftItems));
                        setDraftItems(draftItems);
                    } else {
                        let publishedIndex = publishedItems.findIndex(rec => rec.id === recommendation.id);
                        publishedItems[publishedIndex].content = recommendation.content;
                        publishedItems[publishedIndex].type = recommendation.type;
                        publishedItems[publishedIndex].assetId = recommendation.assetId;
                        publishedItems[publishedIndex].assetName = recommendation.assetName;
                        publishedItems[publishedIndex].isPublished = recommendation.isPublished;
                        updatePersona(publishedItems.concat(draftItems));
                        setPublishedItems(publishedItems);
                    }
                }
            }
            setShowRecommendationModal(false);
            setClickedItem(null);
        }).catch(err => {
            console.error(err);
        });
    }


    const refreshRecommendationProducts = async (type) => {
        if (recommendationProducts) {
            let filteredProducts = [];
            filteredProducts = recommendationProducts.filter(asset => asset.type === type).map(item => {
                return {
                    'name': item.name,
                    'id': item.id
                }
            });
            setFilteredProducts(filteredProducts);
            return filteredProducts;
        } else {
            let filteredProducts = [];
            await ProductSearch()
                .then(response => {
                    let data = response.data.results;
                    setRecommendationProducts(data);
                    filteredProducts = data.filter(asset => asset.type === type).map(item => {
                        return {
                            'name': item.name,
                            'id': item.id
                        }
                    });
                    setFilteredProducts(filteredProducts);
                    return filteredProducts;
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    useEffect(()=> {
        if (enablePublish) {
            setIsPublished(false);
        }
    }, [enablePublish])

    useEffect(()=> {
        let numSelected = 0
        for (const index in checkedDrafts) {
            if (checkedDrafts[index]) {
                numSelected = numSelected +1;
            }
        }
        setNumSelectedDrafts(numSelected);
    }, [checkedDrafts]);

    useEffect (()=> {
        const tempPersonas = { ...persona?.recommendations?.recommendationsList }
        const published = [];
        const drafts = [];
        const checkedDrafts = [];
        for (const index in tempPersonas) {
            if (tempPersonas[index].isPublished) {
                published.push(tempPersonas[index]);
            }
            else {
                drafts.push(tempPersonas[index]);
                checkedDrafts.push(false);
            }
        }
        setPublishedItems(published);
        setDraftItems(drafts);
        setCheckedDrafts(checkedDrafts);
        if (published[0]?.modifiedOn){
            let space = ' '
            setCurrentUpdate(space.concat(convertUTCDateToLocalDate(new Date(published[0].modifiedOn)).toString()));
        }
    }, [persona]);

    useEffect(() => {
        const recommendedItems = document.querySelectorAll(`#drafts`);
        recommendedItems.forEach(function (recommendedItem) {
            if (recommendedItem.className.includes('checked')){
                recommendedItem.className = recommendedItem.className.substring(0, recommendedItem.className.length - 8);
            }
        });
        const checkedDrafts = new Array(draftItems.length).fill(false)
        setCheckedDrafts(checkedDrafts);
    }, [draftItems]);

    return (
        <div className = 'manage-rec-container'>
            <div className = 'header-container'>
                <div className="header">
                    <div className='header--title'>
                        <h1>Manage Recommendations</h1>
                        <h2 className='long'>View, add, and manage all Data & AI Marketplace recommendations</h2>
                    </div>
                </div>
                <div className = 'button-container'>
                    <Button className='btn--rounded btn--blue-light wide' id={"add-rec"} onClick={() => setShowRecommendationModal(true)}>
                        Add Recommendation
                        <Plus className = 'cross' />
                    </Button>
                </div>
            </div>

            <div className = 'tab'>
                <div className='tab--container'>
                    <Tabs id='recommendations-page-tabs' selectedTab={activeTab} onChange={handleChange}>
                        <Tab id='tab-Recommended' key={1} label="Published" value={1} />
                        <Tab id='tab-Whats-New' key={2} label="Drafts" value={2} />
                    </Tabs>
                </div>
            </div>
            
            <div className = 'manage-recs'>
                <div className = 'last-update-upload'>
                    <TabPanel value={activeTab} selectedIndex={1}>
                        {isPublished  ?  
                            <p className='recommended-last-updated'>{'All updates published '} 
                            {currentUpdate ?
                                currentUpdate
                                :
                                (new Date()).toLocaleDateString('en-GB', {
                                    month: 'short', day: 'numeric', year: 'numeric'
                                })
                            }
                            </p>
                            :
                            <p className='recommended-last-updated red'>You have unpublished changes!</p>
                        }
                    </TabPanel>

                    <TabPanel value={activeTab} selectedIndex={2}>
                        <div className='checkbox-container'>
                            <input className='drafts-check-box' type="checkbox" onChange={toggleCheckBox}></input>
                            <div className='drafts-text'>Select all drafts </div>
                        </div>
                    </TabPanel>
                    
                    <div className = 'button-container-upload'>
                        <TabPanel value={activeTab} selectedIndex={2}>
                            <Button className={numSelectedDrafts > 0 ? 'btn--publish blue': 'btn--publish'} id={"add-rec"} onClick={publishDrafts}>
                                <div className= 'button-text-draft'> 
                                        Publish {'('}{numSelectedDrafts}{')'}
                                </div>
                                    { checkDrafts.length > 0 ? <UploadBlue className = 'upload' /> :<Upload className = 'upload' />}
                            </Button>
                        </TabPanel>
                        <TabPanel value={activeTab} selectedIndex={1}>
                            <Button className={enablePublish? 'btn--publish blue': 'btn--publish'} id={"add-rec"} onClick={publish}>
                                <div className= 'button-text'> 
                                        Publish
                                </div>
                                { enablePublish ? <UploadBlue className = 'upload' />:<Upload className = 'upload' />}
                            </Button>
                        </TabPanel>
                    </div>
                </div>
                
                <BrowseCard id="manage-rec" type='recommendation' title='Recommendations'>
                    <TabPanel value={activeTab} selectedIndex={1}>
                        {publishedItems?.length > 0 ? 
                            <>
                                <SortableComponent publishedItems = {publishedItems} onPublishedUpdate={handlePublishUpdate} onRemoveItem = {(item) => removeRec(item.id)} handleChange = {() =>setEnablePublish(true)} onEditItem={(item) => handleEditRecClick(item)} showEdit></SortableComponent>
                            </>
                            : <DataUnavailable 
                                id='recommendations-page-unavailbale' 
                                icon={bellSlash}
                                header={`Recommendations`}
                                subtitle='There are currently no published recommendations!'
                            />
                        }
                    </TabPanel>
                    <TabPanel value={activeTab} selectedIndex={2}>
                        {draftItems.length > 0 ? 
                            <>
                                {draftItems.map((value, index) => (
                                    value && 
                                        <RecommendedItemManage
                                        key ={value.id}
                                        id={`drafts`}
                                        hasCheckbox = {true}
                                        checked={checkedDrafts[index] ? checkedDrafts[index] : false}
                                        onChange={() => {
                                            const newCheckedItems = { ...checkedDrafts };
                                            newCheckedItems[index] = !checkedDrafts[index];
                                            setCheckedDrafts(newCheckedItems);
                                        }}
                                        removeItem = {(item) => removeRec(item.id)}
                                        item = {value}
                                        editItem={() => handleEditRecClick(value)}
                                        showEdit
                                        ></RecommendedItemManage>
                                ))}
                            </>
                            : <DataUnavailable 
                                id='drafts-page-unavailbale' 
                                icon={draftIcon}
                                header={`All Drafts published`}
                                subtitle='There are currently no drafts to review.'
                            />
                        }
                    </TabPanel>
                </BrowseCard>
            </div>
            <RecommendationModal 
                isOpen={showRecommendationModal} 
                handleClose={() => {setShowRecommendationModal(false); setClickedItem(null)}}
                header={clickedItem ? 'Edit Recommendation' : 'New Recommendation'}
                refreshRecommendationProducts={(type) => refreshRecommendationProducts(type)}
                products={filteredProducts}
                saveRecommendation={(recommendation) => saveRec(recommendation)}
                recommendationToEdit={clickedItem}
            />
        </div>
    );
};

ManageRecs.propTypes = {
    id: PropTypes.string,
}

export default ManageRecs;





    