import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Button from '../Button/Button';

import { ReactComponent as LaunchProduct } from '../../assets/icons/share-grey.svg';
import { ReactComponent as RequestAccess } from '../../assets/icons/unlock.svg';
import { ReactComponent as ViewDetails } from '../../assets/icons/view.svg';

import './ActionsDropdown.scss';

export default function ActionsDropdown(props) {
    const [isActive, setActive] = useState(false);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setActive(!isActive);
    };
    const handleChange = (e, option) => {
        e.stopPropagation();
        setActive(false);
        props.onChange(option);
    }

    const getActionIcon = (option) => {
        switch(option) {
            case 'View Details':
            case 'Overview':
                return <ViewDetails />
            case 'Request Access':
                return <RequestAccess />
            case 'Launch Product':
            case 'Launch Presentation':
                return <LaunchProduct />
            default:
                return null;
        }
    }

    const buildOptions = () => {
        return props.options.map((option, idx) => {
            return (
                <div data-testid={`${props.id}-option-${idx}`} key={idx} id={`sort_${idx}`} className='dropdown-item' onClick={(e) => { handleChange(e, option) }}>
                    <div className={`input-container ${props.disabledOptions && props.disabledOptions.includes(option) ? 'disabled' : ''}`}>
                        {getActionIcon(option)}
                        <div>{option}</div>
                    </div>
                </div>
            );
        });
    }
    const wrapperRef = useRef(null);
    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setActive(false);
            }
        }
        if (isActive) {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [wrapperRef, isActive]);

    return (
        <div ref={wrapperRef} id={props.id} data-testid={props.id} className='actions-dropdown'>
            <ReactTooltip id='action-tip' className='custom-color-no-arrow' delayHide={200}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
            <Button 
                id={`${props.id}-more-details`}
                className='action-btn btn-row--action'
                data-for='action-tip'
                data-tip='More Details'
                onClick={(e) => toggleDropdown(e)} >...</Button>
            <div className={isActive ? 'actions-dropdown-content dropdown-show' : 'actions-dropdown-content'}>
                {isActive && (
                    <div>
                        <div>
                            {buildOptions()}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

ActionsDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    disabledOptions: PropTypes.array
};
