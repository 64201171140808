import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

import Favorite from '../FavoriteElements/FavoriteIcon/Favorite';
import ReportType from '../ProductElements/ReportType';
import ProductIcon from '../ProductElements/ProductIcon';
import Accordion from '../Accordion/Accordion';
import AccordionItem from '../Accordion/AccordionItem/AccordionItem';
import ProductDescription from '../ProductElements/ProductDescription';

import information from '../../assets/icons/information.svg';

const DetailsView = ({ result, handleClick, id  }) => {

    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    return (
        <>
            <ReactTooltip id='request-access-tip' className='custom-color-no-arrow' delayHide={200}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
            <div data-testid={id}>
                <div className='drawer-content--block'>
                    <ProductIcon productType={result?.type} />
                    <p className='drawer-content--title'>{result?.name}</p>
                    <span className="favorite">
                        <Favorite favoriteItem={result && result} />
                    </span>
                </div>
                <div data-testid={`${id}-request-access`} className={`drawer-content--card ${getTypeColor(result.type)}`} onClick={handleClick}>
                    <span className='access-request'>
                        <p className='text-underline'>Request access</p> to view this data product.
                        <img src={information} alt='Information Icon'
                            className='information-icon'
                            data-for='request-access-tip'
                            data-tip="You don't have access to this product, to gain access please submit an access request form detailing your needs.">
                        </img>
                    </span>
                </div>
                <ProductDescription description={result?.description} />
                <div className='details-drawer-accordion'>
                    <Accordion id={`${id}-accordion`} defaultOpen={0} multiple={false}>
                        <AccordionItem
                            id={`${id}-product-category`}
                            name='Product Category'
                            value={0}
                            type='accordion-lg'
                            accordionItemClass={getTypeColor(result.type)}
                        >
                            <>
                                <hr />
                                <section className='category-grid'>
                                    {result?.domain && <div className='container-element'>
                                        <p>{result?.domain && ('Domain')}</p>
                                        <ul>
                                            {result?.domain && result?.domain?.map((domain, idx) => {
                                                return <li key={idx}>{domain}</li>;
                                            })}
                                        </ul>
                                    </div>}
                                    {result?.businessSegment && <div className='container-element'>
                                        <p>{result.businessSegment?.length > 0 && ('Segment')}</p>
                                        <ul>
                                            {result && result?.businessSegment?.map((segment, idx) => {
                                                return <li key={idx}>{segment}</li>;
                                            })}
                                        </ul>
                                    </div>}
                                    <div className='container-element'>
                                        <p>Data Origin</p>
                                        <ReportType reportType={result.reportType} includeColorClasses={false} />
                                    </div>
                                    {result?.relationships?.sourceSystem && <div className='container-element'>
                                        <p>{result?.relationships?.sourceSystem && ('Source System')}</p>
                                        <ul>
                                            {result.relationships?.sourceSystem && result.relationships.sourceSystem.map(source => {
                                                return <li key={source.id}>{source.name}</li>;
                                            })}
                                        </ul>
                                    </div>}
                                    {result?.regions?.length > 0 && <div className='container-element'>
                                        <p>{result?.regions?.length > 0 && ('Region')}</p>
                                        <ul>
                                            {result?.regions && result.regions.map((region, idx) => {
                                                return <li key={idx}>{region}</li>;
                                            })}
                                        </ul>
                                    </div>}
                                    {result?.brands?.length > 0 && <div className='container-element'>
                                        <p>{result?.brands?.length > 0 && ('Brand')}</p>
                                        <ul>
                                            {result?.brands && result?.brands?.map((brand, idx) => {
                                                return <li key={idx}>{brand}</li>;
                                            })}
                                        </ul>
                                    </div>}
                                    {result.applications?.length > 0 && <div className='container-element'>
                                        <p>{result.applications?.length > 0 && ('Application')}</p>
                                        <ul>
                                            {result && result?.applications?.map((application, idx) => {
                                                return <li key={idx}>{application}</li>;
                                            })}
                                        </ul>
                                    </div>}
                                </section>
                            </>
                        </AccordionItem>
                        <AccordionItem
                            id={`${id}-product-owners`}
                            name='Product Owners'
                            value={1}
                            type='accordion-lg'
                            accordionItemClass={getTypeColor(result.type)}
                        >
                            <>
                                <hr></hr>
                                <section className='owners-grid'>
                                    <div className='container-element'>
                                        <p>{result?.primaryOwner && ('Primary Owner')}</p>
                                        <ul className={result?.primaryOwner?.length > 3 ? 'ul-column-set' : ''}>
                                            {result?.primaryOwner && result?.primaryOwner?.map((owner, index) => {
                                                return <li key={index}>{owner.name} <span className='role-name'>({owner.roleName})</span></li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className='container-element'>
                                        <p>{result?.secondaryOwner && ('Secondary Owner')}</p>
                                        <ul className={result?.secondaryOwner?.length > 3 ? 'ul-column-set' : ''}>
                                            {result?.secondaryOwner && result?.secondaryOwner?.map((owner) => {
                                                return <li key={owner.username}>{owner.name} <span className='role-name'>({owner.roleName})</span></li>;
                                            })}
                                        </ul>
                                    </div>
                                </section>
                            </>
                        </AccordionItem>
                    </Accordion>
                </div>

            </div>
        </>
    );
};

DetailsView.propTypes = {
    result: PropTypes.any,
    handleClick: PropTypes.func,
    id: PropTypes.string
}

export default DetailsView;