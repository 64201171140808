/*jshint esversion: 6 */
import PropTypes from 'prop-types';
import React from 'react';

import ButtonAddMarketPlace from '../Button/ButtonAddMarketPlace/ButtonAddMarketPlace';
import Login from '../Login/Login';
import SearchBar from '../searchBar/SearchBar';

import './Navbar.scss';
export default function Navbar({ id }) {
    return (
        <>
            <div id={id} data-testid={id} className="navbar">
                <SearchBar />
                <div className="navbar-buttons">
                    <ButtonAddMarketPlace id="navbar" classBtn='btn--rounded-outline add-mktpl-btn' />
                </div>
                <Login />
            </div>
        </>
    );
}

Navbar.propTypes = {
    id: PropTypes.string
}