/*jshint esversion: 6 */
import { MagnifyingGlass } from 'phosphor-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import './Dropdown.scss';

export default function Dropdown(props){
    const [isActive, setActive] = useState(false);
    const [selectOption, setSelectOption] = useState(props.placeHolder ? props.placeHolder : props.label);
    const [options, setOptions] = useState(props.options);

    const handleChange = (option) => {
        setSelectOption(option);
        setActive(false);
        props.onChange(option);
    }

    const wrapperRef = useRef(null);
    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setActive(false);
            }
        }
        if (isActive) {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [wrapperRef, isActive]);

    const getStyles = () => {
        let className = 'dropbtn';
        if (props.options.indexOf(selectOption) > -1) {
            className = className + ' selected';
        }
        if (isActive && !props.disabled) {
            className = className + ' open';
        }
        return className;
    };

    const onKeyPressHandler = (e) => {
        let charCode = e.which || e.charCode || e.keyCode || 0;
        if (charCode === 13) props.handleSearch();
    }

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        setSelectOption(props.preSelectedOption ? props.preSelectedOption : props.placeHolder ? props.placeHolder : props.label);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.preSelectedOption]);

    return (
        <>
            <div className="dropdownmenu">
                <div  id={props.id} data-testid={props.id} onClick={() => setActive(!isActive)} className={getStyles()}>
                    <div> <span>{props.isOptionsMap ? selectOption.name : selectOption}</span></div>
                    {props.sideIconDisplay !== undefined && <div className='circle'><props.sideIconDisplay size={16} weight="bold"/></div>}
                    </div>
                <div ref={wrapperRef} className={(isActive && !props.disabled) ? 'dropdown-content-show' : 'dropdown-content'} >
                    {props.searchSelect &&
                        <div className='search-bar'>
                            <input className='search-bar-input' 
                                onChange={e => {
                                    props.setSearchTerm(e.target.value);
                                }}
                                onKeyPress={onKeyPressHandler}
                                value={props.searchTerm}
                            />
                            <button data-testid={`${props.id}-search-btn`} onClick={(event) => {event.preventDefault();props.handleSearch()}}><MagnifyingGlass size={20} className='icon' /></button>
                        </div>
                    }
                    {options.map((option, idx) => {
                        return (
                            <div id={`${props.id}-${idx}`} data-testid={`${props.id}-${idx}`} key={idx} onClick={() => {handleChange(option)}}>{props.isOptionsMap ? option.name : option}</div>
                        )
                    })}
                </div>
                {props.showError && <span className='error-message'>{props.errorMessage}</span>}
            </div>
        </>
    )
}

Dropdown.propTypes = {
    id: PropTypes.string,
    label: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string,
    searchSelect: PropTypes.bool,
    setSearchTerm: PropTypes.func,
    handleSearch: PropTypes.func,
    isOptionsMap: PropTypes.bool,
    preSelectedOption: PropTypes.any,
    searchTerm: PropTypes.string,
    sideIconDisplay: PropTypes.any,
    placeHolder: PropTypes.string
}