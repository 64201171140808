import { useEffect } from 'react'
import PropTypes from 'prop-types';

const ScrollToBottom = ({ scroll }) => {

    useEffect(() => {
        if (scroll) {
            window.scrollTo({  top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [scroll]);

    return null;
}

ScrollToBottom.propTypes = {
    scroll: PropTypes.bool
}

export default ScrollToBottom;