/*jshint esversion: 6 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

import { ReactComponent as Spoke } from '../../assets/icons/spoke.svg';
import { ReactComponent as Hub} from '../../assets/icons/hub.svg';

export default function ReportType({ id, reportType, includeColorClasses }) {
    const element = useMemo(() => {
        const isHub = () => {
            if (reportType.includes('Department')){
                return(false);
            }
            else {
                return(true);
            }
        };

        const isRestricted = () => {
            if (reportType.includes('Restricted')) {
                return(true);
            }
            else {
                return(false);
            }
        };
        
        const getDataTip = () => {
            let datatip = isHub() ?
            'Product was fully developed and governed by the ED&A organization.'
            : 'Product was built and developed outside of the ED&A organization and has yet to go through the full ED&A governance process.';

            datatip += isRestricted() ?
            '<br/>Restricted items are only visible to those with access.'
            : '';

            return(datatip);
        };

        try {
            return (
                <>
                    <ReactTooltip id='report-type-tip' className='custom-color-no-arrow' delayHide={200}
                        textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
                    <div data-for='report-type-tip' data-tip={getDataTip()} id={id} data-testid={id} className={'report-type '+ (isHub() ? 'hub' : 'spoke')} >
                        {isHub() ? <Hub /> : <Spoke />}
                        <p className={includeColorClasses ? (isHub() ? 'green' : 'dark-blue') : undefined}>{reportType}</p>
                    </div>
                </>
            );
        }
        catch (error) {
            console.log(error);
            return(
                <></>
            )
        }

    }, [id, reportType, includeColorClasses]);

    return element;
}

ReportType.propTypes = {
    id: PropTypes.string,
    reportType: PropTypes.string,
    includeColorClasses: PropTypes.bool
}