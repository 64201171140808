import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CaretDown, CaretUp } from "phosphor-react";

export default function SortDropdown({ id, defaultOption, onChange, options }) {
    const [isActive, setActive] = useState(false);
    const [sortOption, setSortOption] = useState(defaultOption ? defaultOption : 'Default');

    const toggleDropdown = () => {
        setActive(!isActive);
    };
    
    const handleChange = (option) => {
        setSortOption(option);
        onChange(option);
    }

    const buildOptions = () => {
        return options.map((option, idx) => {
            return (
                <div data-testid={`${id}-option-${idx}`} key={idx} id={`sort_${idx}`} className='dropdown-item' onClick={() => { handleChange(option) }}>
                    <div className="input-container">
                        <div className="sort-option ml-1.5 font-medium text-gray-700">{option}</div>
                    </div>
                </div>
            );
        });
    }
    const wrapperRef = useRef(null);
    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setActive(false);
            }
        }
        if (isActive) {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [wrapperRef, isActive]);

    return (
        <div ref={wrapperRef} id={id} data-testid={id} className='dropdown--filter'>
            <div data-testid={`${id}-active-btn`} className='dropdown-btn' onClick={toggleDropdown}>
                {sortOption}
                <span className='dropdown-btn__arrow'>
                    {isActive ? <CaretUp size={12} /> : <CaretDown size={12} />}
                </span>
            </div>
            <div className={isActive ? 'sort-dropdown-content dropdown-show' : 'sort-dropdown-content'}>
                {isActive && (
                    <div>
                        <div>
                            {buildOptions()}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

SortDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    defaultOption: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string
};
