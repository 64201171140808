import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CaretDown, CaretUp, MagnifyingGlass } from "phosphor-react";
import { useLocation } from 'react-router-dom';

import { sendEvent } from '../../helpers/analyticsHandler';

import Button from '../Button/Button';

export default function FilterSelect(props) {
    const [isActive, setActive] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionFilter, setOptionFilter] = useState('');
    const location = useLocation();

    let selectedCount = 0;
    if (props.multiSelect) {
        selectedCount = props.filterValue.size;
    } else {
        if (props.filterValue !== undefined) selectedCount = 1;
    }

    const toggleDropdown = () => {
        setActive(!isActive);
        if (!isActive) {
            setOptionFilter('');
        }
    };

    const buildCheckboxOptions = (props) => {
        // add any filters that aren't included in the data as a filter if it is being sent.
        if (props.filterValue && props.filterValue.size > 0) {
            const optionSet = new Set(props.options);
            const unsetFilters = [...props.filterValue].filter(e => !optionSet.has(e));
            unsetFilters.forEach(f => props.options.push(f));
        }

        let tempOptions = props.id === 'primaryOwner' ? options : props.options;
        return tempOptions.map((option, idx) => {
            return (
                <div className="filter-input-container" key={idx}>
                    <div className="input-container">
                        <input
                            data-testid={`${props.id}-${option.toString()}-checkbox`}
                            key={idx}
                            type="checkbox" // TODO: create a if / else para el input
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            id={`${option.toString()}_${idx}`}
                            name={option.toString()}
                            value={option.toString()}
                            checked={props.isChecked(option)}
                            onChange={(e) => handleOnChange(e, option)}

                        ></input>
                        <label
                            htmlFor={`${option.toString()}_${idx}`}
                            className="ml-1.5 font-medium text-gray-700"
                        >
                            {option}
                        </label>
                    </div>
                </div>
            );
        });
    };

    const buildRadioOptions = (props) => {
        return props.options.map((option, idx) => {
            return (
                <div className="filter-input-container" key={idx} id={props.inputName + `_${idx}`}>
                    <div className="input-container">
                        <input
                            data-testid={`${props.id}-${option.toString()}-radio`}
                            key={idx}
                            type="radio"
                            className=""
                            id={option.replace(/ /g, '').toString()}
                            name={props.inputName}
                            value={option.toString()}
                            checked={props.isChecked(option)}
                            onChange={(e) => handleOnChange(e, option)}
                        />
                        <label
                            htmlFor={option.replace(/ /g, '').toString()}
                            className="ml-1.5 font-medium text-gray-700"
                        >
                            {option}
                        </label>
                    </div>
                </div>
            );
        });
    };

    const handleOnChange = (e, option) => {
        sendEvent('analyticsClickAction', location.pathname + '_filters', props.isChecked(option) === 'checked' || props.isChecked(option) === true ? 'Filter Removed' : 'Filter Selected', props.labelDropdown + ':' + option);
        props.onChange(e);
    }

    const wrapperRef = useRef(null);

    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setActive(false);
            }
        }
        if (isActive) {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [wrapperRef, isActive]);

    useEffect(() => {
        if (props.id === 'primaryOwner') {
            setOptions(props.options);
        }
    }, [props.options, props.id]);


    useEffect(() => {
        setOptions(props.options.filter(val => {
            return val.toLowerCase().includes(optionFilter.toLowerCase());
        }));
    }, [props.options, optionFilter]);

    return (
        <div ref={wrapperRef} className='dropdown--filter' id={props.id.substr(0, 11)} data-testid={props.id}>
            <div className={'dropdown-btn' + (selectedCount > 0 ? ' dropdown-active' : '')} onClick={toggleDropdown} data-testid={`${props.id}-dropdown-btn`}>
                <div>
                    {props.labelDropdown}
                    <span className='dropdown-btn__counter'>
                        {selectedCount > 0 ? `(${selectedCount})` : null}
                    </span>
                </div>
                <span className='dropdown-btn__arrow'>
                    {isActive ? <CaretUp size={12} /> : <CaretDown size={12} />}
                </span>
            </div>
            <div className={isActive ? 'dropdown-content dropdown-show' : 'dropdown-content'}>
                {isActive && (
                    <div className='dropdown-item'>
                        <div id={props.id.substr(0, 12)}>
                            <div className='dropdown-item-header'>
                                <p>{props.labelDropdown} Filter</p>
                                <span className='header-buttons'>
                                    <Button id={`${props.id}-clear-filters-btn`} className='btn--no-outline red' onClick={() => { props.setFilter(undefined); }}>Clear</Button>
                                    <Button id={`${props.id}-done-filters-btn`} className='btn--no-outline' onClick={() => { setActive(false)}}>Done</Button>
                                </span>
                            </div>
                            {
                                props.hasOptionFilter &&
                                <div className='search-option'>
                                    <input
                                        type="text"
                                        data-testid={`${props.id}-search-option`}
                                        placeholder='Search owners'
                                        value={optionFilter}
                                        onChange={(e) => setOptionFilter(e.target.value)} />
                                    <MagnifyingGlass size={16} />
                                </div>
                            }
                            {props.multiSelect ? buildCheckboxOptions(props) : buildRadioOptions(props)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
FilterSelect.propTypes = {
    multiSelect: PropTypes.bool.isRequired,
    filterValue: PropTypes.any,
    id: PropTypes.string.isRequired,
    labelDropdown: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
    inputName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isChecked: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    hasOptionFilter: PropTypes.bool
};


