import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';
import { X } from "phosphor-react";

const Modal = ({ id, isOpen, handleClose, size, children }) => {
    const modalClassname = isOpen ? 'modal-container show' : 'modal-container hide';

    return (
        <div id={id} data-testid={id} className={modalClassname}>
            <div className={`modal-main ${size}`}> 
                <span className='modal-close'>
                    <button id={`${id}-close-modal-btn`} data-testid={`${id}-close-modal-btn`} onClick={handleClose} className="close-icon">
                        <X size={25} />
                    </button>
                </span>
                <div>{children}</div>
            </div>

        </div>
    )

}

Modal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    children: PropTypes.any
}

export default Modal;