import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Favorite from '../FavoriteElements/FavoriteIcon/Favorite';
import ProductIcon from '../ProductElements/ProductIcon';
import ProductAccessOrigin from '../ProductElements/ProductAccessOrigin';
import shareIcon from '../../assets/icons/share-grey.svg';

import './ProductTile.scss';

const ProductTile = ({ id, name, type, access, launchButtonAccess, handleClick, favoriteItem, handleOpenProductClick }) => {
    
    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            default:
                return '';
        }
    }

    return (
        <>
            <div className={`product-tile ${getTypeColor(type)}`} data-testid={id} onClick={handleClick}>
                <div key={name}>
                    <div className='product-name'>
                        <ProductIcon productType={favoriteItem.type} />
                        <div className='product-name--name'>{name}</div>
                    </div>
                    <span className='product-access'>
                        <ProductAccessOrigin id={`${id}-access-origin`} access={access} reportType={favoriteItem.reportType} />
                    </span>
                </div>
                <div className='product-actions'>
                    <Favorite favoriteItem={favoriteItem} />
                    { access && launchButtonAccess &&
                        <>
                            <ReactTooltip id='launch-product-tip' className='custom-color-no-arrow' delayHide={200}
                                textColor='#FFF' backgroundColor='#231F20' effect='solid' multiline={true} />
                            <img
                                src={shareIcon}
                                alt='Share Icon'
                                className='share-icon'
                                data-for='launch-product-tip'
                                data-tip='Launch Product'
                                onClick={handleOpenProductClick}
                            />
                        </>
                    }
                </div>
            </div>

        </>
    )
}

ProductTile.propTypes = {
    id: PropTypes.string,
    name: PropTypes.any,
    type: PropTypes.string,
    access: PropTypes.bool,
    launchButtonAccess: PropTypes.bool,
    handleClick: PropTypes.func,
    favoriteItem: PropTypes.any,
    handleOpenProductClick: PropTypes.func
}

export default ProductTile;