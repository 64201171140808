import React from 'react'
import PropTypes from 'prop-types'

import './DetailCard.scss';

const ProductDetailCard = ({ title, paragraph, cssClass, id, children }) => {

    return (
        <div className={`detail-card ${cssClass && cssClass}`} id={id}>
            <p className='detail-card--title'>{title}</p>
            {paragraph && <p className='detail-card--subtitle'>{paragraph}</p>}
            <>
                {children}
            </>
        </div>
    )
}

ProductDetailCard.propTypes = {
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string,
    cssClass: PropTypes.any,
    children: PropTypes.any.isRequired,
    id: PropTypes.string
};

export default ProductDetailCard;