import React from 'react';
import PropTypes from 'prop-types';

import './FAQSectionCard.scss';

const FAQSectionCard = ({ id, icon, title, children }) => {
    return (
        <>
            <div className='faq-section-card' data-testid={id} id={id}>
                <div className='faq-section-card--header'>
                    <span className='faq-section-card--title'>
                        {icon && <img src={icon} alt={`${id}-title-icon`}/>}
                        <p>{title}</p>
                    </span>
                </div>
                <hr />
                <div className='faq-section-card--items'>
                    {children}
                </div>
            </div>
        </>
    )
}

FAQSectionCard.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.any,
    title: PropTypes.string.isRequired,
    children: PropTypes.any
}

export default FAQSectionCard;