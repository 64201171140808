import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FilterSelect from './FilterSelect';

// deprecated - create custom apply filter functions
export const ApplyFilter = (rows, accessor, filterValue) => {
    const arr = [];

    if (accessor.toString() === 'legacyBrands' || accessor.toString() === 'domain' || accessor.toString() === 'businessSegment') {
        return ApplyArrayFilter(rows, accessor, filterValue)
    }

    rows.forEach((val) => {
        if (accessor.toString() === 'primaryOwner') {
            val.original[accessor].forEach((owner) => {
                if (filterValue.has(owner.name) && !arr.includes(val)) {
                    arr.push(val);
                }
            });
        } else if (accessor.toString() === 'hasAccess') {
            if (filterValue === val.original[accessor]) {
                arr.push(val);
            }
        } else {
            if (filterValue.has(val.original[accessor])) {
                arr.push(val);
            }
        }
    });
    return arr;
};

export const ApplyArrayFilter = (rows, accessor, filterValue) => {
    const arr = [];

    rows.forEach((val) => { 
        val.original[accessor].forEach((brand) => {
            if (filterValue.has(brand) && !arr.includes(val)) {
                arr.push(val);
            }
        });
    });

    return arr;
};


export const ApplyStringFilter = (rows, accessor, filterValue) => {
    const arr = [];
    
    rows.forEach((val) => { 
        if (filterValue.has(val.original[accessor])) {
            arr.push(val);
        }
    });

    return arr;
};

export const OwnershipFilter = ({
    column: { filterValue = new Set(), setFilter, preFilteredRows, id, filterLabel }
}) => {
    const options = useMemo(() => {
        const rowoptions = new Set();
        preFilteredRows.forEach((row) => {
            row.values[id].forEach((item) => rowoptions.add(item.name));
        });
        return [...rowoptions.values()].sort();
    }, [id, preFilteredRows]);

    return <FilterSelect
        labelDropdown={filterLabel}
        multiSelect={true}
        hasOptionFilter={true}
        id={id}
        filterValue={filterValue}
        setFilter={setFilter}
        inputName={id}
        options={options}
        isChecked={(o) => (filterValue.has(o)) ? 'checked' : ''}
        onChange={(e) => {
            if (e.target.checked) {
                filterValue.add(e.target.value);
            } else {
                filterValue.delete(e.target.value);
                if (filterValue.size === 0) filterValue = undefined;
            }
            setFilter(filterValue);
        }} />;
};

export const ArrayMultiSelectFilter = ({
    column: { filterValue = new Set(), setFilter, preFilteredRows, id, filterLabel }
}) => {
    const options = useMemo(() => {
        const rowoptions = new Set();
        preFilteredRows.forEach((row, idx) => {
            row.values[id].forEach((item) => rowoptions.add(item));
        });
        return [...rowoptions.values()].sort();
    }, [id, preFilteredRows]);

    return <FilterSelect
        labelDropdown={filterLabel}
        multiSelect={true}
        id={id}
        filterValue={filterValue}
        setFilter={setFilter}
        inputName={id}
        options={options}
        isChecked={(o) => (filterValue.has(o)) ? 'checked' : ''}
        onChange={(e) => {
            if (e.target.checked) {
                filterValue.add(e.target.value);
            } else {
                filterValue.delete(e.target.value);
                if (filterValue.size === 0) filterValue = undefined;
            }
            setFilter(filterValue);
        }} />;
};

export const AccessFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id, filterLabel }
}) => {
    const options = ['Available', 'Restricted'];

    return <FilterSelect
        labelDropdown={filterLabel}
        multiSelect={false}
        id={id}
        filterValue={filterValue}
        setFilter={setFilter}
        inputName={id}
        options={options}
        isChecked={(o) => filterValue === (o === 'Available')}
        onChange={(e) => {
            setFilter((e.target.value === 'Available'));
        }} />;
};

export const LegacyFilter = ({
    column: { filterValue = new Set(), setFilter, preFilteredRows, id, filterLabel }
}) => {
    const options = useMemo(() => {
        const rowoptions = new Set();
        preFilteredRows.forEach((row) => {
            if (!row || !row.values || row.values[id] === undefined)
                return console.log(row);
            row.values[id].forEach((item) => rowoptions.add(item));
        });
        return [...rowoptions.values()];
    }, [id, preFilteredRows]);

    return <FilterSelect
        labelDropdown={filterLabel}
        multiSelect={true}
        id={id}
        filterValue={filterValue}
        setFilter={setFilter}
        inputName={id}
        options={options}
        isChecked={(o) => (filterValue.has(o)) ? 'checked' : ''}
        onChange={(e) => {
            if (e.target.checked) {
                filterValue.add(e.target.value);
            } else {
                filterValue.delete(e.target.value);
                if (filterValue.size === 0) filterValue = undefined;
            }
            setFilter(filterValue);
        }} />;
};

export const MultiSelectFilter = ({
    column: { filterValue = new Set(), setFilter, preFilteredRows, id, filterLabel }
}) => {
    const options = useMemo(() => {
        const rowoptions = new Set();
        preFilteredRows.forEach((row) => {
            rowoptions.add(row.values[id]);
        });
        return [...rowoptions.values()];
    }, [id, preFilteredRows]);

    return <FilterSelect
        labelDropdown={filterLabel}
        multiSelect={true}
        id={id}
        filterValue={filterValue}
        setFilter={setFilter}
        inputName={id}
        options={options}
        isChecked={(o) => (filterValue.has(o)) ? 'checked' : ''}
        onChange={(e) => {
            if (e.target.checked) {
                filterValue.add(e.target.value);
            } else {
                filterValue.delete(e.target.value);
                if (filterValue.size === 0) filterValue = undefined;
            }
            setFilter(filterValue);
        }} />;
};

OwnershipFilter.propTypes = {
    column: PropTypes.object
};

LegacyFilter.propTypes = {
    column: PropTypes.object
};

AccessFilter.propTypes = {
    column: PropTypes.object
};

MultiSelectFilter.propTypes = {
    column: PropTypes.object
};

ArrayMultiSelectFilter.propTypes = {
    column: PropTypes.object
};