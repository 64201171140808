const storeDataLayer = (dataLayerInternal) => {
    window.dataLayerInternal = dataLayerInternal;
}

export const getDataLayer = () => {
    return window.dataLayerInternal;
}

export const updateDataLayer = (dataLayerInternal, pathName, pageState) => {
    dataLayerInternal.pageProperties.pageName = getPageName(pathName);

    if (pathName.includes('/product/')) {
        if (pageState) {
            dataLayerInternal.reportProperties = {
                reportId: pageState.id,
                reportName: pageState.name,
                reportType: pageState.type
            };
        }
    } else if (pathName.includes('/search')) {
        let searchArray = pathName.split('/search/');
        if (searchArray.length > 1) {
            dataLayerInternal.searchProperties.searchTerm = decodeURI(searchArray[searchArray.length - 1]);
        }

        let tableState = null;
        if (window.sessionStorage.getItem('tableState')) {
            tableState = JSON.parse(window.sessionStorage.getItem('tableState'));
            if (tableState && tableState.filters) {
                dataLayerInternal.searchProperties.filters = formatFiltersToString(dataLayerInternal, tableState.filters);
            }

            if (tableState && tableState.sortBy) {
                dataLayerInternal.searchProperties.sortBy = getSortBy(tableState.sortBy);
            }
        }
    }

    storeDataLayer(dataLayerInternal);
    return dataLayerInternal;
};

export const trackSession = (pageName, pageState) => {
    let dataLayerInternal = {
        pageProperties: {
            pageName: ''
        },
        reportProperties: {
            reportId: '',
            reportName: '',
            reportType: '',
            searchTerm: '',
            filters: '',
            sortBy: ''
        },
        searchProperties: {
            searchTerm: '',
            filters: '',
            sortBy: ''
        },
        glossaryProperties: {
            searchTerm: '',
            filters: '',
            sortBy: ''
        },
        favoriteProperties: {
            sortBy: ''
        },
        adminProperties: {
            sortBy: ''
        }
    };

    dataLayerInternal = updateDataLayer(dataLayerInternal, pageName, pageState);
    return dataLayerInternal;
};

export const trackFilters = (filters) => {
    let dataLayerInternal = getDataLayer();
    let propertiesName = getPropertiesName(dataLayerInternal.pageProperties.pageName);

    dataLayerInternal[propertiesName].filters = formatFiltersToString(dataLayerInternal, filters);
    storeDataLayer(dataLayerInternal);
};

export const trackSort = (sortBy) => {
    let dataLayerInternal = getDataLayer();
    let propertiesName = getPropertiesName(dataLayerInternal.pageProperties.pageName);

    dataLayerInternal[propertiesName].sortBy = sortBy;
    storeDataLayer(dataLayerInternal);
};

export const trackSearch = (searchTerm) => {
    let dataLayerInternal = getDataLayer();
    let propertiesName = getPropertiesName(dataLayerInternal.pageProperties.pageName);

    dataLayerInternal[propertiesName].searchTerm = searchTerm;
    storeDataLayer(dataLayerInternal);
};

export const sendEvent = (type, clickEvent, clickType, cta, report) => {
    let body = document.querySelector('body');
    let loadEvent;

    if (type === 'pageView') {
        loadEvent = new CustomEvent('analyticsPageView', {
            detail: getDataLayer()
        });

        // Add timeout for analyticsPageView events to allow 
        // dataLayerInternal to completely load before event is triggered
        setTimeout(() => {
            body.dispatchEvent(loadEvent);
        }, 1500);
    } else {
        loadEvent = new CustomEvent('analyticsClickAction', {
            detail: constructClickEventObj(clickEvent, clickType, cta, report)
        });
        body.dispatchEvent(loadEvent);
    }
};

export const constructClickEventObj = (clickEvent, clickType, cta, report) => {
    let tempClickEvent = clickEvent.replace('/', '');
    if (tempClickEvent.startsWith('search')) {
        tempClickEvent = 'search' + tempClickEvent.substr(tempClickEvent.lastIndexOf('_'));
    } else if (tempClickEvent.startsWith('product')) {
        tempClickEvent = 'product' + tempClickEvent.substr(tempClickEvent.lastIndexOf('_'));
    }

    let eventObj = {
        analyticsClickLocation: '',
        analyticsClickType: clickType ? clickType : '',
        analyticsClickCTA: cta ? cta : '',
        analyticsReportID: report ? report.id : 'None',
        analyticsReportType: report ? report.type : 'None',
        analyticsReportTitle: report ? report.name : 'None'
    };
    switch (tempClickEvent) {
        // browse page clicks
        case 'browse_browseAll':
            eventObj.analyticsClickLocation = 'Header';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Browse All';
            break;
        case 'browse_favorites':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Browse All';
            break;
        case 'browse_favoritesOpen':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Report';
            break;
        case 'browse_favorite':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Icon';
            eventObj.analyticsClickCTA = 'Remove From Favorites';
            break;
        case 'browse_favoriteLaunch':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Icon';
            eventObj.analyticsClickCTA = 'Launch Product';
            break;
        case 'browse_optionSelect':
            eventObj.analyticsClickLocation = 'Browse Options';
            eventObj.analyticsClickCTA = getFormattedTileCTA(cta);
            break;
        case 'browse_selected':
            eventObj.analyticsClickLocation = 'Browse Options';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Browse Selected';
            break;

        // search page clicks
        case 'search_filters':
            eventObj.analyticsClickLocation = 'Search Filters';
            break;
        case 'search_filtersClear':
            eventObj.analyticsClickLocation = 'Search Filters';
            eventObj.analyticsClickType = 'Filters Cleared';
            eventObj.analyticsClickCTA = 'Clear All';
            break;
        case 'search_sort':
            eventObj.analyticsClickLocation = 'Search Filters';
            eventObj.analyticsClickType = 'Sort By';
            break;
        case 'search_openReport':
            eventObj.analyticsClickLocation = 'Search Results';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Report';
            break;
        case 'search_favorite':
            eventObj.analyticsClickLocation = 'Search Results';
            eventObj.analyticsClickType = 'Icon';
            break;
        case 'search_launch':
            eventObj.analyticsClickLocation = 'Search Results';
            eventObj.analyticsClickType = 'Icon';
            eventObj.analyticsClickCTA = 'Launch Product';
            break;
        case 'search_details':
            eventObj.analyticsClickLocation = 'Search Results';
            eventObj.analyticsClickType = 'Button';
            break;

        // glossary page clicks
        case 'glossary_filters':
            eventObj.analyticsClickLocation = 'Glossary Filters';
            break;
        case 'glossary_filtersClear':
            eventObj.analyticsClickLocation = 'Glossary Filters';
            eventObj.analyticsClickType = 'Filters Cleared';
            eventObj.analyticsClickCTA = 'Clear All';
            break;
        case 'glossary_sort':
            eventObj.analyticsClickLocation = 'Glossary Filters';
            eventObj.analyticsClickType = 'Sort By';
            break;
        case 'glossary_search':
            eventObj.analyticsClickLocation = 'Header';
            eventObj.analyticsClickType = 'Search Bar';
            eventObj.analyticsClickCTA = 'Glossary Search';
            break;

        // product detail page clicks
        case 'product_launch':
            eventObj.analyticsClickLocation = 'Report Detail';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Launch Product';
            break;
        case 'product_favorite':
            eventObj.analyticsClickLocation = 'Report Detail';
            eventObj.analyticsClickType = 'Icon';
            break;
        case 'product_filters':
            eventObj.analyticsClickLocation = 'Report Defintions Filters';
            break;
        case 'product_filtersClear':
            eventObj.analyticsClickLocation = 'Report Defintions Filters';
            eventObj.analyticsClickType = 'Filters Cleared';
            eventObj.analyticsClickCTA = 'Clear All';
            break;
        case 'product_sort':
            eventObj.analyticsClickLocation = 'Report Defintions Filters';
            eventObj.analyticsClickType = 'Sort By';
            break;
        case 'product_search':
            eventObj.analyticsClickLocation = 'Report Definitions Filters';
            eventObj.analyticsClickType = 'Search Bar';
            eventObj.analyticsClickCTA = 'Report Definitions Search';
            break;
        case 'product_target':
            eventObj.analyticsClickLocation = 'Report Targets';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Target';
            break;

        // recommended page clicks
        case 'recommended_openRecommendation':
            eventObj.analyticsClickLocation = 'Recommended RecommendationItem';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Report';
            break;
        case 'recommended_openTutorial':
            eventObj.analyticsClickLocation = 'Recommended Tutorial';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Tutorial';
            break;
        case 'recommended_openNewUpdate':
            eventObj.analyticsClickLocation = 'Recommended WhatsNewItem';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Report';
            break;
        case 'recommended_RecommendationTab':
            eventObj.analyticsClickLocation = 'Recommended Tab Recommendation';
            break;
        case 'recommended_WhatsNewTab':
            eventObj.analyticsClickLocation = 'Recommended Tab WhatsNew';
            break;

        // favorites page clicks
        case 'favorite_openReport':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Open Report';
            break;
        case 'favorite_sort':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Sort By';
            break;
        case 'favorite_launch':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Launch Product';
            break;
        case 'favorite_favorite':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Icon';
            eventObj.analyticsClickCTA = 'Remove From Favorites';
            break;
        case 'favorite_request':
            eventObj.analyticsClickLocation = 'My Favorites';
            eventObj.analyticsClickType = 'Button';
            break;

        // admin page clicks
        case 'admin_permissions_sort':
            eventObj.analyticsClickLocation = 'Admin';
            eventObj.analyticsClickType = 'Sort By';
            break;

        // site wide clicks
        case 'navigation':
            eventObj.analyticsClickLocation = 'Nav Bar';
            eventObj.analyticsClickType = 'Icon';
            break;
        case 'addToMarketplace':
            eventObj.analyticsClickLocation = 'Header';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Add To Marketplace';
            break;
        case 'internalSearch':
            eventObj.analyticsClickLocation = 'Header';
            eventObj.analyticsClickType = 'Search Bar';
            eventObj.analyticsClickCTA = 'Internal Search';
            break;
        case 'back':
            eventObj.analyticsClickLocation = 'Header';
            eventObj.analyticsClickType = 'Icon';
            eventObj.analyticsClickCTA = 'Back';
            break;
        case 'modalSubmit':
            eventObj.analyticsClickLocation = clickType === 'DIA'
                ? 'Add to Marketplace Modal'
                : clickType === 'DAA'
                    ? 'Get Access Modal'
                    : 'Data Extract Modal';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Submit';
            eventObj.analyticsReportType = clickType === 'DIA' && 'New Report Request';
            break;
        case 'drawerRequestAccess':
            eventObj.analyticsClickLocation = 'Report Drawer';
            eventObj.analyticsClickType = 'Button';
            eventObj.analyticsClickCTA = 'Request Access';
            break;
        default:
            break;
    }
    return eventObj;
};

// helper functions
const getPageName = (path) => {
    if (path === '/' || path === '/browse') {
        return 'Browse';
    } else if (path.includes('/search')) {
        return 'Search';
    } else if (path.includes('/product')) {
        return 'Product Detail';
    } else if (path === '/glossary') {
        return 'Glossary';
    } else if (path === '/favorite') {
        return 'Favorite';
    } else if (path === '/recommendations') {
        return 'Recommended';   
    } else if (path === '/admin-console') {
        return 'Admin';
    } else {
        return 'Not Found';
    }
};

const mapFilterIdToName = (page, filterId) => {
    let filterName = filterId?.charAt(0).toUpperCase() + filterId?.slice(1);
    switch (filterId) {
        case 'type':
            if (page === 'search') {
                filterName = 'Product Type';
            }
            break;
        case 'businessSegment':
            filterName = 'Segment';
            break;
        case 'legacyBrands':
            filterName = 'Legacy Acquisition';
            break;
        case 'primaryOwner':
            filterName = 'Ownership';
            break;
        case 'hasAccess':
            filterName = 'Access';
            break;
        default:
            break;
    }

    return filterName;
};

const getPropertiesName = (pageName) => {
    let propertiesName = '';

    if (pageName === 'Search') {
        propertiesName = 'searchProperties';
    } else if (pageName === 'Glossary') {
        propertiesName = 'glossaryProperties';
    } else if (pageName === 'Favorite') {
        propertiesName = 'favoriteProperties';
    } else if (pageName === 'Admin') {
        propertiesName = 'adminProperties';
    } else {
        propertiesName = 'reportProperties';
    }
    return propertiesName;
};

const formatFiltersToString = (dataLayerInternal, filters) => {
    let formattedFilters = '';
    filters.forEach((filter) => {
        let convertedFilterName = mapFilterIdToName(dataLayerInternal.pageProperties.pageName, filter.id);
        if (filter.id !== 'hasAccess') {
            filter.value.forEach((val) => {
                formattedFilters += convertedFilterName + ':' + val + '|';
            });
        } else {
            formattedFilters += convertedFilterName + ':' + (filter.value ? 'Available' : 'Restricted') + '|';
        }
    });

    return formattedFilters.slice(0, -1);
}

const getSortBy = (sortBy) => {
    const { id, desc } = sortBy[0];

    switch (id) {
        case 'id':
            return 'Default';
        case 'name':
            if (desc) return 'Alphabetical Z-A';
            return 'Alphabetical A-Z';
        default:
            return 'unknown';
    }
}

const getFormattedTileCTA = (tileCTA) => {
    let tileArray = tileCTA.split(':');
    let tileCTAFormatted = '';

    if (tileArray[0] === 'businessSegment') {
        tileCTAFormatted = 'Segment';
    } else if (tileArray[0] === 'domain') {
        tileCTAFormatted = 'Domain';
    } else if (tileArray[0] === 'type') {
        tileCTAFormatted = 'Type';
    }

    return tileCTAFormatted + ':' + tileArray[1];
};