import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { arrayMoveImmutable } from 'array-move';

import RecommendedItemManage from '../../components/RecommendedItemManage/RecommendedItemManage';

import './SortableComponent.scss'

const SortableComponent = ({ publishedItems, onPublishedUpdate, onRemoveItem, handleChange, onEditItem, showVisible, showEdit }) => {
    const [tempItems, setTempItems] = useState(publishedItems);

    useEffect(() => {
        setTempItems(publishedItems);
    }, [publishedItems]);

    const onDragEnd = useCallback((result) => {
        if (!result.destination) {
        return;
        }

        const updatedItems = arrayMoveImmutable(tempItems, result.source.index, result.destination.index);
        setTempItems(updatedItems);
        onPublishedUpdate(updatedItems);
        handleChange();

    }, [tempItems, onPublishedUpdate,handleChange]);

    const onVisibleChange = (id, newVisibilty) => {
        let updatedItems = tempItems;
        let temp = updatedItems.findIndex((i) => i.id === id);
        updatedItems[temp].isVisible = newVisibilty;
        setTempItems([...updatedItems]);

        onPublishedUpdate(updatedItems);
        handleChange();
    } 

    return (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="publishedItems">
        {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
            {tempItems.map((value, index) => (
                <Draggable key={value.id} draggableId={value.id} index={index}>
                {(provided) => (
                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    <RecommendedItemManage
                        id={`published`}
                        content={`<p>${value.content}</p>`}
                        type={value.type}
                        hasCheckbox={false}
                        removeItem={onRemoveItem}
                        item={value}
                        editItem={() => onEditItem(value)}
                        showVisible={showVisible}
                        showEdit={showEdit || value.type === 'Feature'}
                        handleVisibleChange={(id, newVisibilty) => onVisibleChange(id, newVisibilty)}
                    />
                    </li>
                )}
                </Draggable>
            ))}
            {provided.placeholder}
            </ul>
        )}
        </Droppable>
    </DragDropContext>
    );
};

SortableComponent.propTypes = {
  publishedItems: PropTypes.any.isRequired,
  onPublishedUpdate: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  showVisible: PropTypes.bool,
  showEdit: PropTypes.bool
};

export default SortableComponent;