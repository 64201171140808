import React, { useState, useEffect } from 'react';
import { Grid } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import Modal from './Modal';
import TextEditor from '../TextEditor/TextEditor';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';

import './FeatureModal.scss';

const FeatureModal = ({ id, isOpen, handleClose, header, message, saveFeature, featureToEdit }) => {
    const [duration, setDuration] = useState('');
    const [featureHtml, setFeatureHtml] = useState('');
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [contentError, setContentError] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);

    const handleCloseModal = () => {
        handleClose();
        setDuration('');
        setContentError(false);
        setDropdownError(false);
    }

    const handleSave = async (publish) => {
        if (validateForm()) {
            setContentError(false);
            setDropdownError(false);
            setIsSaveLoading(true);
            let itemToSave = {
                "content": featureHtml,
                "type": 'Feature',
                "isPublished": publish,
                "isVisible": true
            }

            if (featureToEdit) {
                itemToSave.id = featureToEdit.id
            } else {
                itemToSave.displayDuration = duration.split(' ')[0];
            }

            await saveFeature(itemToSave).then((response) => {
                setIsSaveLoading(false);
                handleCloseModal();
            }).catch((err) => {
                setIsSaveLoading(false);
                console.log(err);
            });
        }
    }

    const validateForm = () => {
        let valid = true;
        if (featureHtml === '' || featureHtml === '<p><br></p>') {
            setContentError(true);
            valid = false
        }
        
        if (duration === '') {
            setDropdownError(true);
            valid = false
        }
        return valid;
    }

    useEffect(() => {
        setFeatureHtml(featureToEdit ? featureToEdit.content : '');
        setDuration(featureToEdit ? featureToEdit.duration : '')
    }, [featureToEdit]);
    
    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleCloseModal} size={featureToEdit ? "sm" : "md"}>
                    <div className='feature-modal'>
                        <h2>{header}</h2>
                        <p>{message}</p>
                        <div className='feature-content'>
                            <label>Please enter the feature update to be listed</label>
                            <TextEditor 
                                saveHtmlFormat={setFeatureHtml}
                                currentHtmlContent={featureToEdit ? featureToEdit.content : null}
                            />
                            {contentError && <p className='content-error'>This Field is required</p>}
                            {!featureToEdit &&
                                <div className='feature-duration'>
                                    <label>How long should this update be displayed?</label>
                                    <Dropdown
                                        id="dropdown"
                                        label='Please choose a duration'
                                        onChange={(option) => setDuration(option)}
                                        errorMessage='This Field is required'
                                        showError={dropdownError}
                                        options={['1 day', '2 days', '3 days', '4 days', '5 days', '6 days', '7 days',
                                            '8 days', '9 days', '10 days', '11 days', '12 days', '13 days', '14 days']}
                                    />
                                </div>
                            }
                        </div>
                        <div className='feature-buttons'>
                            {((featureToEdit && !featureToEdit.isPublished) || !featureToEdit) &&
                                <Button 
                                    id={`${id}-login-btn`} 
                                    className='btn--rounded btn--blue-white btn--close'
                                    onClick={() => handleSave(false)}
                                    disabled={isSaveLoading}
                                >Save as draft</Button>
                            }           
                            <Button 
                                id={`${id}-close-btn`} 
                                className='btn--rounded btn--blue-light btn--close'
                                onClick={() => handleSave(true)}
                                disabled={isSaveLoading}
                            >Publish now</Button>
                            {isSaveLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="modal-spinner" /></span>}
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

FeatureModal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    header: PropTypes.string,
    message: PropTypes.string,
    saveFeature: PropTypes.func,
    featureToEdit: PropTypes.any
}

export default FeatureModal;