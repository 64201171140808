/*jshint esversion: 6 */
import React, { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import { sendEvent, trackSession } from '../helpers/analyticsHandler';
import { Context } from '../contexts/context';

import Browse from '../pages/Browse/Browse';
import ProductDetailPage from '../pages/ProductDetail/productDetailPage';
import SearchPage from '../pages/Search/SearchPage';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import GlossaryPage from '../pages/Glossary/GlossaryPage';
import FavoritePage from '../pages/Favorite/FavoritePage';
import Admin from '../pages/Admin/Admin';
import FAQPage from '../pages/FAQ/FAQPage';
import RecommendedPage from '../pages/Recommended/RecommendedPage';
import TermDetailPage from '../pages/TermDetail/TermDetailPage';

export const AppRouter = () => {
    const location = useLocation();

    const {
        state: {
            isAdmin
        },
    } = useContext(Context);

    useEffect(() => {
        trackSession(location.pathname, location.state);
        sendEvent('pageView');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <Routes>
            <Route path="/" element={<Browse />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/search/:term" element={<SearchPage />} />
            <Route path="product/:id" element={<ProductDetailPage />} />
            <Route path="/glossary" element={<GlossaryPage />} />
            <Route path="/term/:id" element={<TermDetailPage />} />
            <Route path="/favorite" element={<FavoritePage />} />
            <Route path="/browse" element={<Browse />} />
            {isAdmin && <Route path="/admin-console" element={<Admin />} /> }
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/recommendations" element={<RecommendedPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};