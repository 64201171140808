import React, { useState } from 'react';

import PropTypes from 'prop-types';
import './FileUpload.scss';
import Paperclip from '../../assets/icons/paperclip.svg';
import Check from '../../assets/icons/check.svg';

const FileUpload = ({handleUpload, uriPattern, label, parameterName, disabled, progress, required}) => {
    const [selectedFile, setSelectedFile] = useState(null);
	const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    if(event.target.files[0]){
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
      handleUpload(event.target.files[0],parameterName);
    }
  }

  const allowedFileTypes = () => {
    var fileTypes = uriPattern.split("(").at(-1).split(/[^A-Za-z/.]/);
    fileTypes = fileTypes.filter(e => e!=="")
    return fileTypes.toString()
  }

  return (
    <>
      <div className='upload-file-container'>
        <div className='upload-filename'>
            <p>{isFilePicked ? selectedFile.name : 'Choose a File'}</p>
         </div>
          
        <input type="file" accept={allowedFileTypes()} id="file-7" className="inputfile" onChange={changeHandler}/>
        <label htmlFor={disabled ? "" : "file-7"}><img src={Paperclip} alt='upload-file'width="15" height="15"/>Upload</label>
       </div>
       <div className='upload-progress-bar' >
        {progress !== 0 && <div className='check-image'>
          <img src={Check} alt='file-uploaded' width="16" height="16"/></div>}
       <div className='progress-bar-file' style= {{width : `${progress}%`}}></div>
       </div>
     </>)
}
FileUpload.propTypes = {
    handleUpload: PropTypes.func,
    uriPattern: PropTypes.string,
    parameterName: PropTypes.string,
    label: PropTypes.string,
    required : PropTypes.bool,
    disabled: PropTypes.bool,
    progress: PropTypes.number
}
export default FileUpload;