import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import './ConfirmUnfavoriteModal.scss';
import Button from '../Button/Button';

const ConfirmRecordDeleteModal = ({ id, isOpen, handleClose, handleDelete, header, message }) => {
    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleClose} size="sm">
                    <div className='confirm-modal'>
                        <h2>{header}</h2>
                        <p>{message}</p>
                        <div className='confirm-buttons'>
                            <Button id={`${id}-cancel-btn`} className='btn--rounded btn--blue-white btn--close confirm-button'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button id={`${id}-yes-cancel-btn`} className='btn--rounded btn--blue-light btn--close confirm-button'
                                onClick={handleDelete}
                            >
                                Yes, Delete It
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

ConfirmRecordDeleteModal.propTypes = {
    handleDelete: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    header: PropTypes.string,
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string
}

export default ConfirmRecordDeleteModal;