import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

import questionLabel from '../../assets/icons/question-label.svg'

import './FormTextArea.scss';

const FormTextArea = (props) => {
    return (
        <div className={props.addClass ? "formInput mb-3" : "formInput"}>
            <label
                htmlFor={props.name}
                className={props.long && 'label-long question-3'} >
                {props.label}
                {
                    (props.label && props.showtooltip) &&
                    <img src={questionLabel} alt='help question' data-for='row-name-tip' data-tip={props.tooltip} />
                }
            </label>
            <ReactTooltip id='row-name-tip' className='custom-color-no-arrow' delayHide={1000}
                textColor='#231F20' backgroundColor='#FFFFFF' effect='solid' />
            <textarea
                type={props.type}
                id={props.name}
                data-testid={props.id}
                name={props.name}
                // eslint-disable-next-line react/no-unknown-property
                showtooltip={props.showtooltip}
                placeholder={props.placeholder}
                required={props.required}
                onChange={props.handleChange} />
            {props.showError && <span className='error-message'>{props.errorMessage}</span>}
        </div>
    )
}

FormTextArea.propTypes = {
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    long: PropTypes.bool,
    errorMessage: PropTypes.string,
    required: PropTypes.bool,
    showtooltip: PropTypes.bool,
    tooltip: PropTypes.string,
    addClass: PropTypes.any,
    handleChange: PropTypes.func,
    showError: PropTypes.bool,
    id: PropTypes.string
}

export default FormTextArea;