import React from 'react';
import PropTypes from 'prop-types';

import ProductIcon from '../ProductElements/ProductIcon';

import './RecommendedItem.scss';

const RecommendedItem = ({ id, icon, content, type, date, handleItemClick }) => {
    
    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            case 'Search Term':
                return 'green';
            case 'Feature':
                return 'green'
            default:
                return '';
        }
    }

    return (
        <>
            <div className={`recommended-item ${getTypeColor(type)}`} data-testid={id} onClick={handleItemClick}>
                <div className='recommended-content'>
                    <ProductIcon productType={type} />
                    <div className='recommended-title' dangerouslySetInnerHTML={{ __html: content }}></div>
                    <img src={icon} alt='arrow-right' className='arrow-pointer'/>
                </div>
                <p className='recommended-date'>{date}</p>
            </div>

        </>
    )
}

RecommendedItem.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string,
    content: PropTypes.any,
    type: PropTypes.string,
    date: PropTypes.string,
    handleItemClick: PropTypes.func
}

export default RecommendedItem;