import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button/Button';
import './ErrorModal.scss';
import Modal from './Modal';

const CheckDownloadDSExecutionModal = ({ id, isOpen, handleClose, handleContinue, handleDownload, header, message }) => {
    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleClose} size="x-sm">
                    <div className='confirm-modal'>
                        <h2>{header}</h2>
                        <p>{message}</p>
                        <div className = 'button-container'>
                            <Button id={`${id}-close-btn`} className='btn--rounded btn--blue-white btn--close'
                                onClick={() => handleContinue()}
                            >
                                Continue with Run
                            </Button>
                            <Button id={`${id}-delete-btn`} className='btn--rounded btn--blue-light btn--delete'
                                onClick={() => handleDownload()}
                            >
                                Download Previous Output
                            </Button>
                        </div>
                        
                    </div>
                </Modal>
            }
        </>
    )
}

CheckDownloadDSExecutionModal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleContinue: PropTypes.func.isRequired,
    handleDownload: PropTypes.func.isRequired,
    header: PropTypes.string,
    message: PropTypes.string
}

export default CheckDownloadDSExecutionModal;