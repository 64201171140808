/*jshint esversion: 6 */
/*jshint esversion: 11 */
import React from 'react';
import PropTypes from 'prop-types';

import './DataUnavailable.scss';

const DataUnavailable = ({ id, header, subtitle, icon }) => {

    return (
        <article id={id} data-testid={id} className='no-data'>
            <div className='no-data--card'>
                <img src={icon} alt="data-unavailable-icon" />
                {header && <p className='no-data-header'>{header}</p>}
                <p>{subtitle}</p>
            </div>
        </article>
    );
};

DataUnavailable.propTypes = {
    id: PropTypes.string,
    header: PropTypes.string,
    subtitle: PropTypes.string.isRequired,
    icon: PropTypes.any
};

export default DataUnavailable;