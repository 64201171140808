import { X } from "phosphor-react";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import { ReactComponent as NextSlide } from '../../assets/icons/arrow-next-slide.svg';
import { ReactComponent as NextArrow } from '../../assets/icons/arrow-next.svg';
import { ReactComponent as PrevSlide } from '../../assets/icons/arrow-prev-slide.svg';
import { ReactComponent as CarouselDotCircle } from '../../assets/icons/carousel-dot-circle.svg';
import { ReactComponent as CarouselDotRectangle } from '../../assets/icons/carousel-dot-rectangle.svg';
import { Actions } from "../../contexts/action";
import { Context } from '../../contexts/context';
import { GetMedia } from '../../services/dmpApi';
import Button from '../Button/Button';
import './TutorialModal.scss';

const TutorialModal = ({id, isOpen, handleClose}) => {
    const {  
        state: {
            media
        },
        dispatch
    } = useContext(Context);

    const modalClassname = isOpen ? 'tutorial-modal-container show' : 'tutorial-modal-container hide';
    const [slide, setSlide] = useState(1);
    const headers = ['How do I view the Data & AI Marketplace products?', 'How can I search for products?', 'How do I filter my search results?']
    const subtitles = ['Narrow your results from the browse page by selecting the filter options. Or selet the "Browse All" button to view all the Data & AI Marketplace products.',
    'From any page in the Data & AI Marketplace, you can enter your keywords into the search bar, press enter, and start exploring products.', 
    'Maybe you want to only see dashboards you have access to. Select your criteria in the filter dropdowns, and see your results filtered automatically.']
    const [videos, setVideos] = useState( media ? [media.browse_desktop, media.search_desktop, media.filters_desktop, media.browse_tablet, media.search_tablet, media.filters_tablet] : null)
    const [expirationTime, setExpirationTime] = useState( media ? calcExpirationTime(media.browse_desktop) : null);
    const [isVideosLoaded, setIsVideosLoaded] = useState(media ? true : false);

    const nextSlide = () => {
        if (isVideosLoaded === true) {
            setSlide(slide + 1);
        }
    };
    
    const prevSlide = () => {
        if (isVideosLoaded === true) {
            setSlide(slide - 1);
        }
    };

    const LoadMedia = async () => {
        const response = await GetMedia()
            .catch((err) => console.log(err));
        if (response) {
            let data = response.data;
            dispatch({
                type: Actions.UPDATE_MEDIA,
                payload: data
            });
        }
        else {
            console.log('no response from media')
        }
    }

    function calcExpirationTime(presignedUrl) {
        const regex = /Expires=(\d+)/;
        const match = regex.exec(presignedUrl);
        if (!match) {
            console.log('Invalid presigned URL: missing expiration time');
            return(0);
        }
        const expirationTime = parseInt(match[1], 10);
        return(expirationTime);
    }

    useEffect (() => {
        const now = Math.floor(Date.now() / 1000);
        if (now > expirationTime) {
            setIsVideosLoaded(false);
            LoadMedia();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slide]);

    useEffect(() => {
        const videos = [media?.browse_desktop, media?.search_desktop, media?.filters_desktop, media?.browse_tablet, media?.search_tablet, media?.filters_tablet]
        setVideos(videos);
        if (media != null) {
            setExpirationTime(calcExpirationTime(videos[slide-1]));
            setIsVideosLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [media])

    useEffect(() => { 
        if (isOpen === false) {
            setSlide(1);
        }
    }, [isOpen]);

    return (
        <>
            {isOpen &&
                <div className={modalClassname} id = {id} data-testid={id}>
                    <div className='tutorial-modal-main'>
                        {slide ===  1 || slide === 2 ? <NextSlide data-testid={'next'} className = 'next-slide' onClick = {nextSlide}/> : null}
                        {slide === 2 || slide === 3 ? <PrevSlide data-testid={'prev'} className = 'prev-slide' onClick = {prevSlide}/> : null}
                        <div className='tutorial-modal'>
                            <div className = 'tutorial--description'>
                                <div className='tutorial-close-button'>
                                    <button onClick={() => handleClose()} className='close' data-testid={`${id}-close-tutorial-btn`}>
                                        <X size={25} />
                                    </button>
                                </div>
                                <div className = 'description--detail'> 
                                    <p className = 'title'>{headers[slide-1]}</p>
                                    <p className = 'subtitle'>{subtitles[slide-1]}</p>
                                </div>
                                <div className = 'next-button--carousel'>
                                    <div className = 'carousel'>
                                        {slide === 1 ?
                                        <>
                                        <CarouselDotRectangle className = 'rectangle'/>
                                        <CarouselDotCircle className = 'circle'/>
                                        <CarouselDotCircle className = 'circle'/>
                                        </>
                                        : null }
                                        {slide === 2 ?
                                        <>
                                        <CarouselDotCircle className = 'circle'/>
                                        <CarouselDotRectangle className = 'rectangle'/>
                                        <CarouselDotCircle className = 'circle'/>
                                        </>
                                        : null}
                                        {slide === 3 ?
                                        <>
                                        <CarouselDotCircle className = 'circle'/>
                                        <CarouselDotCircle className = 'circle'/>
                                        <CarouselDotRectangle className = 'rectangle'/>
                                        </>
                                        : null}
                                    </div>
                                    <div className = 'next-button'>
                                        {slide === 1 || slide === 2? 
                                            <Button className='btn--rounded btn--blue-light'
                                                id={"next-button"} onClick={() => nextSlide()}
                                            >
                                            Next
                                            <NextArrow className = 'next-arrow' />
                                            </Button>
                                            : null}
                                        {slide ===3 ? 
                                            <Button id={'modal-close'} className='btn--rounded btn--blue-light'
                                            onClick={() => handleClose()}
                                            >
                                            Close
                                            </Button>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className = 'video-container'>
                                {isVideosLoaded ?
                                <>
                                {slide === 1? 
                                    <>
                                        <video preload="auto" className = 'tablet' autoPlay loop>
                                        <source src={videos[3]} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>

                                        <video preload="auto" className = 'desktop' autoPlay loop>
                                            <source src={videos[0]} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> 
                                    </>
                                    : 
                                    null
                                }
                                {slide === 2 ? 
                                    <>
                                        <video preload="auto" className = 'tablet' autoPlay loop>
                                            <source src={videos[4]} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        
                                        <video preload="auto" className = 'desktop' autoPlay loop>
                                            <source src={videos[1]} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> 
                                    </>
                                    : 
                                    null
                                }
                                {slide === 3 ? 
                                    <>
                                        <video preload="auto" className = 'tablet' autoPlay loop>
                                            <source src={videos[5]} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        
                                        <video preload="auto" className = 'desktop' autoPlay loop>
                                            <source src={videos[2]} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> 
                                    </>
                                    : 
                                    null
                                }
                                </>
                                : 
                                <div className="loading-spinner" id="grid-loading">
                                        <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

TutorialModal.propTypes = {
    id : PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default TutorialModal;