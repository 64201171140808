import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Drawer.scss';
import { X } from "phosphor-react";

const Drawer = ({ id, children, isOpen, handleClose }) => {
    useEffect(() => {
        if(isOpen){
            document.body.style.overflow = 'hidden'
        }
        else{
            document.body.style.overflow = 'unset'
        }
    },[isOpen])
    return (
        <>
            {isOpen &&
                <div className="drawer-container">
                    <div className='drawer' id={id} data-testid={id}>
                        <div>
                            <button onClick={() => handleClose(false)} className='close' data-testid={`${id}-close-drawer-btn`}>
                                <X size={25} />
                            </button>
                        </div>
                        <div className='drawer-content'>
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

Drawer.propTypes = {
    id: PropTypes.string,
    children: PropTypes.any,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func
}

export default Drawer;