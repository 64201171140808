import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../contexts/context';
import { sendEvent } from '../../helpers/analyticsHandler';
import { GetUpdates } from '../../services/dmpApi';

import BackButton from '../../components/Button/BackButton/BackButton';
import DataUnavailable from '../../components/DataUnavailable/DataUnavailable';
import FormContainer from '../../components/Form/FormContainer';
import RecommendedItem from '../../components/RecommendedItem/RecommendedItem';
import { Tab, TabPanel, Tabs } from '../../components/Tabs/Tabs';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';

import arrowRight from '../../assets/icons/arrow-right.svg';
import bellSlash from '../../assets/icons/bell-slash.svg';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import ErrorModal from '../../components/Modal/ErrorModal';
import TutorialModal from '../../components/Modal/TutorialModal';
import TutorialItem from '../../components/TutorialItem/TutorialItem';
import './RecommendedPage.scss';

const RecommendedPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const {
        state: {
            persona
        },
        dispatch
    } = useContext(Context);
    const [isWhatsNewLoading, setIsWhatsNewLoading] = useState(true);
    const [whatsNewLastUpdated, setWhatsNewLastUpdated] = useState('');
    const [recommendationsLastUpdated, setRecommendationsLastUpdated] = useState('');
    const [whatsNew, setWhatsNew] = useState([]);

    const navigate = useNavigate();
    const [modalId, setModalId] = useState('');
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);
    const [showRequestAccessConfirmModal, setShowRequestAccessConfirmModal] = useState(false);
    const [showModalError, setShowModalError] = useState(false);

    function handleItemClick(item) {
        if(item.type!=="Search Term" && item.type !=='Feature' && item.assetId !== ''){
            if (item.hasAccess === true || item.type==='Data Science Product') {
                navigate(`/product/${item.assetId}`, {
                    state: {
                        id: item.assetId,
                        name: item.name,
                        type: item.type
                    }
                });
            } else{
                setModalId(item.assetId);
                setShowDetailModal(!showDetailModal);
            }
        }
    }

    const handleClickRequestAccessConfirmation = () => {
        setShowRequestAccessConfirmModal(!showRequestAccessConfirmModal);
        setShowRequestAccessModal(!showRequestAccessModal);
    };
    const handleModalErrorRequestAccess = () => {
        setShowModalError(!showModalError)
        setShowRequestAccessModal(!showRequestAccessModal)
    }   

    useEffect(() => {
        if (persona?.recommendations) {
            setIsLoading(false);
            sendEvent('analyticsClickAction', 'recommended_RecommendationTab', null, null, null)
            
            let date = new Date();
                setRecommendationsLastUpdated(
                    date.toLocaleDateString('en-GB', {
                        month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
                    })
                )
        }
    }, [persona])

    useEffect(() => {
        const LoadWhatsNew = async () => {
            const response = await GetUpdates()
                .catch((err) => console.log(err));

            if (response) {
                const data = response.data.results;
                setWhatsNew(data.filter(record => record.isPublished && record.isVisible));
                setIsWhatsNewLoading(false);
                
                let date = new Date()
                    setWhatsNewLastUpdated(
                        date.toLocaleDateString('en-GB', {
                            month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
                        })
                    )
            }
        }

        LoadWhatsNew();
    }, [dispatch]);

    const handleChange = (e, value) => {
        setActiveTab(value);
        if(value===1){
            sendEvent('analyticsClickAction', 'recommended_RecommendationTab', null, null, null)
        }
        else if(value===2){
            sendEvent('analyticsClickAction', 'recommended_WhatsNewTab', null, null, null)
        }
    };

    const toggleTutorialModal = () => {
        setShowModal(!showModal);
    }

    function convertUTCDateToLocalDate(date, long) {
        var newDate = new Date(date.getTime()-date.getTimezoneOffset()*60*1000);
        if (long) {  
            return newDate.toLocaleDateString('en-GB', {
                month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: 'true'
                                });   
        }
        else {
            return newDate.toLocaleDateString('en-GB', {
                day: 'numeric', month: 'short', year: 'numeric'
                                });   
        }
        
    }

    function detailView() {
        return (
            <>
                <DetailDrawer
                    id='recommended-page-details'
                    itemId={modalId}
                    modalActive={showDetailModal}
                    setModalState={(state) => setShowDetailModal(state)}
                    data-testid={props.id}
                />

                <section className='tab'>
                    <div className='tab--container'>
                        <Tabs id='recommendations-page-tabs' selectedTab={activeTab} onChange={handleChange}>
                            <Tab id='tab-Recommended' key={1} label="Recommended" value={1} />
                            <Tab id='tab-Whats-New' key={2} label="What's New" value={2} />
                        </Tabs>
                    </div>
                    <div className={(activeTab === 1 && (persona?.recommendations?.recommendationsList?.length === 0 || !persona?.recommendations?.recommendationsList))
                        || (activeTab === 2 && (whatsNew?.length === 0 || !whatsNew))
                        ? 'tab--panel-container inactive' : 'tab--panel-container'}
                    >
                        <TabPanel value={activeTab} selectedIndex={1}>
                            {persona?.recommendations?.recommendationsList?.length > 0 ? 
                                <>
                                    <p className='recommended-last-updated'>Last updated {recommendationsLastUpdated}</p>
                                    {persona.recommendations.recommendationsList.map((recItem, index) => (
                                        recItem.isPublished && 
                                            <RecommendedItem 
                                                key={index}
                                                id={`${index}`}
                                                icon={recItem.type !== 'Search Term' && recItem.assetId !== '' ? arrowRight : ''}
                                                handleItemClick={() => {
                                                    handleItemClick(recItem)
                                                    sendEvent('analyticsClickAction', 'recommended_openRecommendation', null, null, recItem)}}
                                                content={recItem.content}
                                                type={recItem.type}
                                                date={convertUTCDateToLocalDate(new Date(recItem.modifiedOn), false).toString()}
                                            ></RecommendedItem>
                                            ))}
                                </>
                                : <DataUnavailable 
                                    id='recommendations-page-unavailbale' 
                                    icon={bellSlash}
                                    header={`Last Updated ${recommendationsLastUpdated}`}
                                    subtitle='There are currently no updates to share. Please check back later!'
                                />
                            }
                            <div className ='tutorial-contianer'> 
                                <TutorialItem 
                                id ='tutorial-item'
                                title= 'Need Help?' 
                                subtitle = 'View our tutorial on how to get started searching in the Data & AI Marketplace' 
                                onClick = {() => {
                                    toggleTutorialModal()
                                    sendEvent('analyticsClickAction', 'recommended_openTutorial', null, null, null)}}/>
                            </div>                        
                        </TabPanel>
                        <TabPanel value={activeTab} selectedIndex={2}>
                            {whatsNew?.length > 0 ? 
                                <>
                                    <p className='recommended-last-updated'>Last updated {whatsNewLastUpdated.toLocaleString()}</p>
                                    {whatsNew.map((newItem, index) => (
                                        newItem.isVisible && 
                                            <RecommendedItem 
                                                key={index}
                                                id={`${index}`}
                                                icon={newItem.type !== 'Feature' && newItem.assetId !== '' ? arrowRight : ''}
                                                handleItemClick={() => {
                                                    handleItemClick(newItem)
                                                    sendEvent('analyticsClickAction', 'recommended_openNewUpdate', null, null, newItem)}}
                                                content={newItem.type !== 'Feature' ?
                                                    `<p><strong>${newItem.content}</strong> has been added to the Data & AI Marketplace</p>`
                                                    : newItem.content
                                                }
                                                type={newItem.type}
                                                date={convertUTCDateToLocalDate(new Date(newItem.modifiedOn), false).toString()}
                                            ></RecommendedItem>
                                            ))}
                                </>
                                : <DataUnavailable 
                                    id='recommendations-page-unavailbale' 
                                    icon={bellSlash}
                                    header={`Last Updated ${whatsNewLastUpdated}`}
                                    subtitle='There are currently no updates to share. Please check back later!'
                                />
                            }
                        </TabPanel>
                    </div> 
                    <TutorialModal 
                        id = "tutorial-modal"
                        isOpen = {showModal} 
                        handleClose= {toggleTutorialModal}
                    />   
                </section>

                <FormContainer
                    id={'favorite-page-form-modal'}
                    isOpen={showRequestAccessModal}
                    handleClose={() => setShowRequestAccessModal(false)}
                    header={'Request Access'}
                    typeForm="DAA"
                    handleClickConfirmation={handleClickRequestAccessConfirmation}
                    handleError={handleModalErrorRequestAccess}
                />
                <ConfirmationModal
                    isOpen={showRequestAccessConfirmModal}
                    id={'favorite-page-confirmation-modal'}
                    handleClose={() => setShowRequestAccessConfirmModal(false)}
                    header='Thank you for submitting your request for access.'
                    message='You can expect confirmation email from Data Governance in 1 to 2 days​ and Data Governance will notify you of approval.'
                />
                 <ErrorModal
                    isOpen={showModalError}
                    id={'favorite-page-error-modal'}
                    handleClose={() => setShowModalError(false)}
                    message={'Your request has failed. Please submit your request again.'}
                ></ErrorModal>
            </>
            
        );

        
    }

    return (
        <section data-testid='recommended-page'>
            <div className='content--container recommended--container'>
                <div className="header">
                    <BackButton id='recommended-page' />
                    <div className='header--title'>
                            <h1>Recommendations & What’s New</h1>
                            <h2>View Data & AI Marketplace recommendations or newly added products or features</h2>
                        </div>
                </div>
                {(isLoading || isWhatsNewLoading) &&
                    <div data-testid={'recommended-page-loading'} className="loading-spinner">
                        <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                        <span>Loading....</span>
                    </div>
                }
                {!isLoading && !isWhatsNewLoading ?
                    <div data-testid='recommended-page-items' className='recommended-page-container'>{detailView()}</div>
                    : null
                }
            </div>
        </section>

    );

};

RecommendedPage.propTypes = {
    id: PropTypes.string
}

export default RecommendedPage;