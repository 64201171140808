import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Datatable from './Datatable';

export default function StatefulDatatable({
    pageName, columns, data, hiddenColumns, onRowClick, setResultsCount, onChangeSort, sortOptions,
    id, defaultSortBy, defaultPageSize, searchTerm, hasGlobalFilter, rowClass, dropDownRow, paginationDisplay, noResultsMessage, givenClassName,
    fetchedResultsCount, totalResultsCount, displayCheck }) {

    const [stateLoaded, setStateLoaded] = useState(false);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [filters, setFilters] = useState(null);
    const [stateFilters, setStateFilters] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        loadState();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    function loadState() {
        setStateLoaded(true);
        let savedState = getSavedState(pageName);
        if (isStateSetInUrl()) {
            setStateFromUrl();
            saveTableState(pageName, pageSize, filters, pageIndex, sortBy);

            // if the filters were set via url update the location history so that going back 
            // doesn't reset the filters according to the url
            navigate(location.pathname, { replace: true });
        } else if (savedState) {
            setStateFromSession(savedState);

            if (savedState.term !== searchTerm) {
                setPageIndex(0);
                saveTableState(pageName, pageSize, filters, 0, sortBy, searchTerm);
            }
        } else {
            setDefaultState();
        }
    }

    // check to see if the state had been set via url
    function isStateSetInUrl() {
        return (location && location.state);
    }

    function setStateFromUrl() {
        setFilters(getUrlStateFilters());
        setPageIndex(getUrlStatePageIndex());
    }

    function setStateFromSession(sessionState) {
        setFilters(sessionState.filters);
        setPageIndex(sessionState.pageIndex);
        setPageSize(sessionState.pageSize);
        setSortBy(sessionState.sortBy);
    }

    function setDefaultState() {
        setFilters([]);
        setPageIndex(0);
        setPageSize(defaultPageSize);
    }

    // if we are setting the fitlers via url parameters parse the url to build an array of filter values
    function getUrlStateFilters() {
        if (!isStateSetInUrl() || !location.state.defaultFilter) {
            return [];
        }

        const filters = [];
        const { defaultFilter } = location.state;
        if (Array.isArray(defaultFilter)) {
            defaultFilter.forEach((f) => {
                filters.push({
                    id: f.param,
                    value: (Array.isArray(f.value) ? new Set(f.value) : f.value)
                });
            });
        } else if (defaultFilter !== null) {
            filters.push({
                id: defaultFilter.param,
                value: (Array.isArray(defaultFilter.value) ? new Set([defaultFilter.value]) : defaultFilter.value)
            });
        }
        return filters;
    }

    function getUrlStatePageIndex() {
        if (location && location.state) {
            const { defaultPageIndex } = location.state;

            return defaultPageIndex;
        }

        return 0;
    }

    // Callback passed to the Datatable - called each time a filter or page is changed
    // Store the filter and page data in session storage and the state
    function onTableStateChange(tablePageSize, tableFilters, tablePageIndex, tableSortBy) {
        let updated = false;
        // changing the state object won't happen before we save the session state so if we reset page in this function
        // we need to use this to propagate to the session storage
        let updatedPageIndex = tablePageIndex;
        // If page size has changed set the page size 
        if (tablePageSize !== pageSize && !isMaxPageSize(tablePageSize)) {
            setPageSize(tablePageSize);
            setPageIndex(0);
            updatedPageIndex = 0;
            updated = true;
        }

        // If the state filters haven't been set, initialize them. Otherwise check for a change.
        if (stringifyFilters(tableFilters) !== getStateFilters()) {
            setFilters(tableFilters);
            setPageIndex(0);
            updatedPageIndex = 0;
            updated = true;
        } else if (tablePageIndex !== pageIndex) {
            setPageIndex(tablePageIndex);
            updated = true;
        } else if (tableSortBy !== sortBy) {
            setSortBy(tableSortBy);
            updated = true;
        }
        // If there has been a change to the page size or filters update the session storage accordingly
        if (updated) {
            saveTableState(pageName, tablePageSize, tableFilters, updatedPageIndex, tableSortBy, searchTerm);
        }
    }

    function getStateFilters() {
        if (stateFilters !== null) {
            return stateFilters;
        }

        let savedState = getSavedState(pageName);

        if (!savedState) {
            return '';
        }

        let strFilters = stringifyFilters(savedState.filters);
        setStateFilters(strFilters);
        return strFilters;
    }

    function stringifyFilters(filterobj) {
        return JSON.stringify(filterobj, (_key, value) => (value instanceof Set ? [...value] : value));
    }

    function getSavedState(pagename) {
        let tableState = null;
        if (window.sessionStorage.getItem(pagename + '-tableState')) {
            tableState = JSON.parse(window.sessionStorage.getItem(pagename + '-tableState'));
            if (!tableState || tableState === null || tableState.filters === null) {
                return null;
            }
            tableState.filters.forEach(f => {
                if (f.value && Array.isArray(f.value)) f.value = new Set(f.value);
            });
        }
        return tableState;
    }

    function saveTableState(pagename, pageSize, filterobj, pageIndex, sortBy, term) {
        let filterString = stringifyFilters(filterobj);

        setStateFilters(filterString);

        window.sessionStorage.setItem(pagename + '-tableState', JSON.stringify({
            pageSize: pageSize,
            filters: filterobj,
            pageIndex: pageIndex,
            sortBy: sortBy,
            term: term
        }, (_key, value) => (value instanceof Set ? [...value] : value)));
    }

    if (stateLoaded) {
        return (
            <Datatable
                id={id}
                columns={columns}
                data={data}
                hiddenColumns={hiddenColumns}
                sortOptions={sortOptions}
                onChangeSort={onChangeSort}
                defaultFilters={filters}
                defaultPageSize={pageSize}
                defaultPageIndex={pageIndex}
                defaultSortBy={sortBy}
                onTableStateChange={onTableStateChange}
                onRowClick={onRowClick}
                setResultsCount={setResultsCount}
                hasGlobalFilter={hasGlobalFilter}
                rowClass={rowClass}
                dropDownRow={dropDownRow}
                paginationDisplay={paginationDisplay}
                noResultsMessage={noResultsMessage}
                givenClassName={givenClassName}
                fetchedResultsCount={fetchedResultsCount}
                totalResultsCount={totalResultsCount}
                displayCheck= {displayCheck} />);
    }
    return <></>;
}

StatefulDatatable.propTypes = {
    pageName: PropTypes.string.isRequired,
    columns: PropTypes.array,
    data: PropTypes.array,
    hiddenColumns: PropTypes.array,
    defaultPageSize: PropTypes.number,
    onRowClick: PropTypes.func,
    setResultsCount: PropTypes.func.isRequired,
    sortOptions: PropTypes.array,
    onChangeSort: PropTypes.func,
    id: PropTypes.string,
    defaultSortBy: PropTypes.array,
    searchTerm: PropTypes.string,
    hasGlobalFilter: PropTypes.bool,
    rowClass: PropTypes.func,
    dropDownRow: PropTypes.bool,
    paginationDisplay: PropTypes.bool,
    noResultsMessage: PropTypes.string,
    givenClassName: PropTypes.string,
    fetchedResultsCount: PropTypes.number,
    totalResultsCount: PropTypes.number,
    displayCheck: PropTypes.bool
};

function isMaxPageSize(pageSize) {
    return pageSize % 10 !== 0;
}
