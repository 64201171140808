import React, { useCallback, useContext, useEffect } from 'react';
import { X } from "phosphor-react";

import error from '../../assets/icons/error.svg';
import success from '../../assets/icons/success.svg';

import { Context } from '../../contexts/context';
import { Actions } from '../../contexts/action';

import './Toast.scss'

const Toast = () => {
    const {
        state: {
            notifications
        },
        dispatch
    } = useContext(Context)

    const deleteToast = useCallback(id => {
        const toastListItem = notifications.filter(e => e.id !== id);
        dispatch({ type: Actions.REMOVE_NOTIFICATION, payload: toastListItem });
    }, [notifications, dispatch]);

    useEffect(() => {
        
            const interval = setInterval(() => {
                if (notifications.length) {
                    if(!notifications[0].display){
                        deleteToast(notifications[0].id);
                    }
                }
            }, 10000);
    
            return () => {
                clearInterval(interval);
            }
        
        
    }, [notifications, deleteToast]);

    const getToastIcon = (type) => {
        switch (type) {
            case 'error':
                return error;
            case 'success':
                return success;
            default:
                return {}
        }
    }

    return (
        <div data-testid='toast' className='toast-container'>
            {
                notifications.map((toast, i) => (
                    <div key={i} className={`toast ${toast.type}`}>
                        <div className='toast-content'>
                            <img
                                className='icon'
                                src={getToastIcon(toast.type)}
                                alt={toast.type}
                            />
                            <div className='text'>
                                <p className='heading'>{toast.title}</p>
                                <p className='subtitle'>{toast.message}</p>
                                {toast.actionFunc && <button id={'action-button'} onClick={(state) => {
                                    toast.actionFunc(toast.data, state)
                                    deleteToast(toast.id)
                                    }}
                                    >View Download</button>}
                            </div>
                        </div>
                        <button id={'toast-close-btn'} data-testid={'toast-close-btn'} onClick={() => deleteToast(toast.id)}>
                            <X size={25} />
                        </button>
                    </div>
                ))
            }
        </div>
    )
}

export default Toast;